import { createClient } from "./createClient";

export const getRootClient = async ({ ctx }: { ctx: any }) => {
  return await getClient({
    ctx,
    projectId: process.env.ROOT_PROJECT_ID || "69983cb0-621b-4cf0-8886-367b6f4a4d47",
  });
};
// kuechengilde.de
// kuechenwerkstatt.de
// dk-das-kuechenhaus.de

export const getClient = async ({
  ctx,
  projectId,
  host,
}: {
  ctx: any;
  projectId: string;
  host?: string;
}) => {
  let preview = false;

  if (
    !projectId &&
    process.env.BACKEND_API_ORG &&
    process.env.BACKEND_API_ORG != ""
  ) {
    const URL = `https://cloud.caisy.io/api/${
      process.env.API_VERSION && process.env.API_VERSION != ""
        ? process.env.API_VERSION
        : "v2"
    }/e/${process.env.BACKEND_API_ORG}`;
    console.log(` URL`, URL);
    return createClient({ URL });
  } else {
    if (!projectId) {
      throw new Error(
        "dealer not found please set host header or provide BACKEND_API_ORG"
      );
    }

    let endpoint = undefined;
    if (`${process.env.ENDPOINT}`.length <= 9) {
      endpoint = "https://cloud.caisy.io";
    } else {
      endpoint = `${process.env.ENDPOINT}`.replace(/\/caisy\/graphql/g, "");
    }

    if (host?.includes("gedk.caisy.site")) {
      preview = !host.includes("dev.gedk.caisy.site");
      if (`${ctx.req?.headers?.["gedk-preview"]}` == "true") {
        preview = true;
      }
      if (`${ctx.req?.headers?.["gedk-preview"]}` == "false") {
        preview = false;
      }
    }

    const URL = `${endpoint}/api/${
      process.env.API_VERSION && process.env.API_VERSION != ""
        ? process.env.API_VERSION
        : "v2"
    }/e/${projectId}/graphql`;

    console.log(` -- HOST : ${host}`, ` -- preview : ${preview}`, `URL ${URL}`);
    if (URL.includes("undefined")) {
      throw `${URL}`;
    }
    return createClient({ URL, preview });
  }
};
