

import gql from "graphql-tag";
import Banner from "./fragments/Banner.gql";

export default gql`
${Banner}
query allBanner {
    allBanner {
    edges {
      node {
        _meta {
            publishedAt
        }
        ...Banner
      }
    }
  }
}
`;
