import gql from "graphql-tag";
import Asset from "./Asset.gql";

const PhotoGallery = gql`
    ${Asset}
    fragment PhotoGallery on PhotoGallery {
        maxCountPerRow
        visualization
        modal
        elements {
            ...Asset
        }
    }
`;
export default PhotoGallery;
