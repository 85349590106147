import gql from "graphql-tag";
import Asset from "./Asset.gql";
import Icon from "./Icon.gql";
import MetaInformation from "./MetaInformation.gql";
import SidebarText from "./SidebarText.gql";
import TextElement from "./TextElement.gql";

export default gql`
${TextElement}
${Asset}
${MetaInformation}
${SidebarText}
${Icon}
fragment Course on Course {
    _meta {
      publishedAt
      updatedAt
      id
    }
    extras {
      __typename
        ...MetaInformation
        ...SidebarText
    }
    elements {
        __typename
        ...Asset
        ...TextElement
        ...Icon
    }
    id
    startDate
    endDate
    pricePerParticipantInEuro
    kursNr
    nameInNavigation
    briefDescription
    maxParticipants
    registrationDeadline
    organizer
  }
`;
