import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";

import { css } from "styled-components";

export const wrapper: any = css`
  background-color: rgba(0, 0, 0, 0.81);
  position: fixed;
  z-index: 9999999999999;
  top: 0;
  left: 0;
  opacity: 0;
  height: 100%;
  width: 100%;
  transition: opacity 0.6s ease, height 0s linear 0.6s, width 0s linear 0.6s;
  pointer-events: none;
  padding: 90px 0 180px 0;
`;

export const wrapperActive: any = css`
  opacity: 1;
  pointer-events: all;
  transition: opacity 0.6s ease, height 0s linear 0s, width 0s linear 0s;
  /* height: 100vh;
    width: 100vw !important;
    img{
        width: 100% !important;
    } */
`;

export const fullScreenWrapper: any = css`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  > div {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    > div:nth-child(2) {
      width: calc(100vw - 40px);
      @media (${BREAKPOINTS_DM.gold_1024}) {
        width: unset;
      }
    }
  }
  padding: 0 20px;
  @media (${BREAKPOINTS_DM.gold_1024}) {
    padding: 0;
  }
  /* position: fixed;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: auto;
    max-width: 800px;
    width: calc(100% - 30px); */
  .fullscreen-caption {
    position: relative;
    svg {
      position: absolute;
      top: 10px;
      right: 0;
    }
    @media (${BREAKPOINTS_DM.gold_1024}) {
      padding: 0 20px;
      svg {
        right: 20px;
      }
    }
  }
  p {
    padding-right: 40px;
    height: 30px;
    padding-top: 10px;
    padding-left: 1px;
    color: rgba(255, 255, 255, 0.8);
  }
  svg {
    cursor: pointer;
    color: white;
    path {
      fill: white;
    }
  }
`;

export const imgFullScreen: any = css`
  width: calc(100vw - 40px);
  border: 4px solid white;

  @media (${BREAKPOINTS_DM.gold_1024}) {
    height: 100%;
    width: auto;
    object-fit: contain;
  }
`;

export const svg: any = css`
  /* fill: rgba(255, 255, 255, 0.8);
    position: absolute;
    bottom: 0;
    right: 0;
    cursor: pointer;
    &:hover {
        fill: rgba(255, 255, 255, 1);
    } */
`;

export const img: any = css`
  :not(:first-child) {
    margin-top: 20px;
    span {
      margin-top: 20px;
    }
  }
  height: auto;
  cursor: pointer;
  span {
    display: inline-block;
  }
  > div,
  img {
  }
`;
