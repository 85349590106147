import gql from "graphql-tag";

const Icon = gql`
  fragment Icon on Icon {
    subtitle
    __typename
    file {
      ... Asset
    }
  }
`;
export default Icon;