import gql from "graphql-tag";
import Asset from "./fragments/Asset.gql";
import { ProductService } from "./fragments/ProductService.gql";

export const ProductFragmentGQL = `
fragment Asset on Asset {
    id
    author
    title
    originType
    copyright
    descCaisy: description
    dominantColor
    src
    keywords
    originalName
    description: subtitle
    __typename
}

fragment ProductService on ProductService {
    title
    icon {
        ...Asset
    }
    __typename
}
`;
export const ProductBaseGQLBody = `
_meta {
    publishedAt
    updatedAt
}
__typename
id
showInMarketplace
availableQuantityMarketplace
transportNote
title
articelNumber
images {
    ...Asset
}
text 
services {
    ...ProductService
}
normalPrice
ourPrice
mehrwertsteuer
productDescription 
productTechnicalDetails 
productDownloads {
    ...Asset
}
startDate
endDate
acceptAGB
`;

export const ProductGQLBodies = {
    ProductSinkAndTap: `${ProductBaseGQLBody}
    Martial
    color
    Type
    `,
    ProductWorktop: `${ProductBaseGQLBody}
    EAN
    `,
    ProductCoffeeMachine: `${ProductBaseGQLBody}
    EAN
    hight
    width
    depth
    weight
    EnergyEfficiencyClass
    EnergyConsumption
    BuiltInDevice
    FullyAutomaticCoffeeMachine
    Technology
    `,
    ProductAccessoriesAndSupplies: `${ProductBaseGQLBody}
    EAN
    hight
    width
    depth
    weight`,
    ProductWashingMachine: `${ProductBaseGQLBody}
    EAN
    hight
    width
    depth
    weight
    EnergyEfficiencyClass
    EnergyConsumption
    WaterConsumption
    BuiltInDevice
    Capacity
    SpinningRate
    ProtectiveDrum
    Connectivity
    SpinEfficiencyClass
    programDuration
    AirborneNoiseEmissionClass
    AirborneNoiseEmissions
    `,
    ProductWaschDryer: `${ProductBaseGQLBody}
    AirborneNoiseEmissionClass
    AirborneNoiseEmissions
    BuiltInDevice
    CapacityOperatingCycle
    CapacityWashingCycle
    EAN
    EnergyConsumptionOperatingCycle
    EnergyConsumptionWashingCycle
    EnergyEfficiencyClassOperatingCycle
    EnergyEfficiencyClassWashingCycle
    ProtectiveDrum
    SpinEfficiencyClass
    SpinningRate
    WaterConsumptionOperatingCycle
    WaterConsumptionWashingCycle
    depth
    hight
    width
    weight
    Connectivity
    `,
    ProductKitchenFurniture: `${ProductBaseGQLBody}`,
    ProductCooktop: `${ProductBaseGQLBody}
    EAN
    hight
    width
    depth
    weight
    EnergyEfficiencyClass
    EnergyConsumption
    Connectivity
    InductionType
    `,
    ProductSparePart: `${ProductBaseGQLBody} 
    EAN
    hight
    width
    depth
    weight
    `,
    ProductWarmingDrawer: `${ProductBaseGQLBody}
    EnergyEfficiencyClass
    EnergyConsumption
    Connectivity
    `,
    ProductMicrowaveAndCooker: `${ProductBaseGQLBody} 
    EAN
    hight
    width
    depth
    weight
    EnergyEfficiencyClass
    EnergyConsumption
    steamCooker
    BuiltInDevice
    CookingChamberUsableVolume
    Connectivity`,
    ProductAService: `${ProductBaseGQLBody} 
    `,
    ProductLighting: `${ProductBaseGQLBody}
    EAN
    hight
    width
    depth
    EnergyEfficiencyClass
    EnergyConsumption
    Power
    Connectivity
    `,
    ProductExtractorHood: `${ProductBaseGQLBody}
    EAN
    hight
    width
    depth
    weight
    EnergyEfficiencyClass
    EnergyConsumption
    HasTroughfanOrDowndraft
    BuiltInDevice
    FilterPerformance
    ExhaustAirOrCirculatingAir
    Connectivity
    `,
    ProductKitchen: `${ProductBaseGQLBody}
    grosse
    farbe
    kuchenart
    kuchenform
    kucheninsel
    kuchenstil
    material
    zielgruppe
    `,
    ProductDishwasher: `${ProductBaseGQLBody}           
    EAN
    hight
    width
    depth
    weight
    EnergyEfficiencyClass
    EnergyConsumption
    BuiltInDevice
    NominalCapacity
    WaterConsumption
    AirborneNoiseEmission
    programDuration
    Connectivity`,
    ProductBathroomFurniture: `${ProductBaseGQLBody}
    hight
    width
    depth
    color
    `,
    ProductConsumerElectronics: `${ProductBaseGQLBody}
    EAN
    hight
    width
    depth
    weight
    EnergyEfficiencyClass
    EnergyConsumption`,
    ProductDryer: `${ProductBaseGQLBody}
    EAN
    hight
    width
    depth
    weight
    EnergyEfficiencyClass
    EnergyConsumption
    DryingEfficiencyClass
    BuiltInDevice
    Capacity
    CondensationOrHeatPumpDryer
    `,
    ProductStoveAndOven: `${ProductBaseGQLBody}
    hight
    width
    depth
    weight
    EnergyEfficiencyClass
    EnergyConsumption
    steamCooker
    BuiltInDevice
    UsableVolume
    Connectivity
    Pyrolysis
    energyConsumptionTopBottomHeat
    energyConsumptionHotAir
    `,
    ProductVacuumDrawer: `${ProductBaseGQLBody}
    EnergyEfficiencyClass
    EnergyConsumption
    Connectivity
    `,
    ProductSmallDevice: `${ProductBaseGQLBody}
    EAN
    hight
    width
    depth
    EnergyEfficiencyClass
    Power
    Connectivity
    Category
    `,
    Product: `${ProductBaseGQLBody}
    EAN`,
    ProductRefrigeratorAndFreezer: `${ProductBaseGQLBody}
    EAN
    hight
    width
    depth
    weight
    EnergyEfficiencyClass
    EnergyConsumption
    BuiltInDevice
    UsableCapacityCoolerCompartment
    UsableCapacityFreezerCompartment
    StarRating
    NoiseEmission
    ZeroDegreeZone
    Connectivity
    `,
};

export default `
    ${ProductFragmentGQL}
    query {
        allProduct {
            edges {
                node {
                    ${ProductGQLBodies.Product}  
                }
            }
        }
        allProductAccessoriesAndSupplies {
            edges {
                node {
                    ${ProductGQLBodies.ProductAccessoriesAndSupplies}
                }
            }
        }
        allProductAService {
            edges {
                node {
                    ${ProductGQLBodies.ProductAService}
                }
            }
        }
        allProductBathroomFurniture {
            edges {
                node {
                   ${ProductGQLBodies.ProductBathroomFurniture}
                }
            }
        }
        allProductCoffeeMachine {
            edges {
                node {
                    ${ProductGQLBodies.ProductCoffeeMachine}
                }
            }
        }
        allProductConsumerElectronics {
            edges {
                node {
                    ${ProductGQLBodies.ProductConsumerElectronics}
                }
            }
        }
        allProductCooktop {
            edges {
                node {
                   ${ProductGQLBodies.ProductCooktop}
                }
            }
        }
        allProductDishwasher {
            edges {
                node {
                    ${ProductGQLBodies.ProductDishwasher}
                }
            }
        }
        allProductDryer {
            edges {
                node {
                   
                    ${ProductGQLBodies.ProductDryer}
                }
            }
        }
        allProductExtractorHood {
            edges {
                node {
                    ${ProductGQLBodies.ProductExtractorHood}
                }
            }
        }
        allProductKitchen {
            edges {
                node {
                    ${ProductGQLBodies.ProductKitchen}
                }
            }
        }
        allProductKitchenFurniture {
            edges {
                node {
                    ${ProductGQLBodies.ProductKitchenFurniture}
                }
            }
        }
        allProductLighting {
            edges {
                node {
                    ${ProductGQLBodies.ProductLighting}
                }
            }
        }
        allProductMicrowaveAndCooker {
            edges {
                node {
                    ${ProductGQLBodies.ProductMicrowaveAndCooker}
                }
            }
        }
        allProductRefrigeratorAndFreezer {
            edges {
                node {
                    ${ProductGQLBodies.ProductRefrigeratorAndFreezer}
                }
            }
        }
        allProductSinkAndTap {
            edges {
                node {
                    ${ProductGQLBodies.ProductSinkAndTap}
                }
            }
        }
        allProductSmallDevice {
            edges {
                node {
                    ${ProductGQLBodies.ProductSmallDevice}

                }
            }
        }
        allProductSparePart {
            edges {
                node {
                    ${ProductGQLBodies.ProductSparePart}
                }
            }
        }
        allProductStoveAndOven {
            edges {
                node {
                    ${ProductGQLBodies.ProductStoveAndOven}
                }
            }
        }
        allProductVacuumDrawer {
            edges {
                node {
                    ${ProductGQLBodies.ProductVacuumDrawer}
                }
            }
        }
        allProductWarmingDrawer {
            edges {
                node {
                    ${ProductGQLBodies.ProductWarmingDrawer}
                }
            }
        }
        allProductWashingMachine {
            edges {
                node {
                    ${ProductGQLBodies.ProductWashingMachine}
                }
            }
        }
        allProductWaschDryer {
            edges {
                node {
                    ${ProductGQLBodies.ProductWaschDryer}
                }
            }
        }
        allProductWorktop {
            edges {
                node {
                    ${ProductGQLBodies.ProductWorktop}
                }
            }
        }
    }
`;


// export default gql`
//     ${ProductService}
//     ${Asset}

//     query {
//   allProduct {
//     edges {
//       node {
//         _meta{
//           publishedAt
//           updatedAt
//         }
//         __typename
//         id
//         title
//         articelNumber
//         images {
//           ...Asset
//         }
//         text
//         services {
//           ...ProductService
//         }
//         normalPrice
//         ourPrice
//         mehrwertsteuer
//         productDescription
//         productTechnicalDetails
//         productDownloads {
//           ...Asset
//         }
//         startDate
//         endDate
//         acceptAGB
//         EAN
//       }
//     }
//   }
//   allProductAccessoriesAndSupplies {
//     edges {
//       node {
//                   _meta{
//           publishedAt
//           updatedAt
//         }
//         __typename
//         id

//         title
//         articelNumber
//         images {
//           ...Asset
//         }
//         text
//         services {
//           ...ProductService
//         }
//         normalPrice
//         ourPrice
//         mehrwertsteuer
//         productDescription
//         productTechnicalDetails
//         productDownloads {
//           ...Asset
//         }
//         startDate
//         endDate
//         acceptAGB
//         EAN
//         hight
//         width
//         depth
//         weight
//       }
//     }
//   }
//   allProductAService {
//     edges {
//       node {
//                   _meta{
//           publishedAt
//           updatedAt
//         }
//         __typename
//         id

//         title
//         articelNumber
//         images {
//           ...Asset
//         }
//         text
//         services {
//           ...ProductService
//         }
//         normalPrice
//         ourPrice
//         mehrwertsteuer
//         productDescription
//         productTechnicalDetails
//         productDownloads {
//           ...Asset
//         }
//         startDate
//         endDate
//         acceptAGB
//       }
//     }
//   }
//   allProductBathroomFurniture {
//     edges {
//       node {
//                   _meta{
//           publishedAt
//           updatedAt
//         }
//         __typename
//         id

//         title
//         articelNumber
//         images {
//           ...Asset
//         }
//         text
//         services {
//           ...ProductService
//         }
//         normalPrice
//         ourPrice
//         mehrwertsteuer
//         productDescription
//         productTechnicalDetails
//         productDownloads {
//           ...Asset
//         }
//         startDate
//         endDate
//         acceptAGB
//         hight
//         width
//         depth
//         color
//       }
//     }
//   }
//   allProductCoffeeMachine {
//     edges {
//       node {
//                   _meta{
//           publishedAt
//           updatedAt
//         }
//         __typename
//         id

//         title
//         articelNumber
//         images {
//           ...Asset
//         }
//         text
//         services {
//           ...ProductService
//         }
//         normalPrice
//         ourPrice
//         mehrwertsteuer
//         productDescription
//         productTechnicalDetails
//         productDownloads {
//           ...Asset
//         }
//         startDate
//         endDate
//         acceptAGB
//         EAN
//         hight
//         width
//         depth
//         weight
//         EnergyEfficiencyClass
//         EnergyConsumption
//         BuiltInDevice
//         FullyAutomaticCoffeeMachine
//         Technology
//       }
//     }
//   }
//   allProductConsumerElectronics {
//     edges {
//       node {
//                   _meta{
//           publishedAt
//           updatedAt
//         }
//         __typename
//         id

//         title
//         articelNumber
//         images {
//           ...Asset
//         }
//         text
//         services {
//           ...ProductService
//         }
//         normalPrice
//         ourPrice
//         mehrwertsteuer
//         productDescription
//         productTechnicalDetails
//         productDownloads {
//           ...Asset
//         }
//         startDate
//         endDate
//         acceptAGB
//         EAN
//         hight
//         width
//         depth
//         weight
//         EnergyEfficiencyClass
//         EnergyConsumption
//       }
//     }
//   }
//   allProductCooktop {
//     edges {
//       node {
//                   _meta{
//           publishedAt
//           updatedAt
//         }
//         __typename
//         id

//         title
//         articelNumber
//         images {
//           ...Asset
//         }
//         text
//         services {
//           ...ProductService
//         }
//         normalPrice
//         ourPrice
//         mehrwertsteuer
//         productDescription
//         productTechnicalDetails
//         productDownloads {
//           ...Asset
//         }
//         startDate
//         endDate
//         acceptAGB
//         EAN
//         hight
//         width
//         depth
//         weight
//         EnergyEfficiencyClass
//         EnergyConsumption
//         Connectivity
//         InductionType
//       }
//     }
//   }
//   allProductDishwasher {
//     edges {
//       node {
//                   _meta{
//           publishedAt
//           updatedAt
//         }
//         __typename
//         id

//         title
//         articelNumber
//         images {
//           ...Asset
//         }
//         text
//         services {
//           ...ProductService
//         }
//         normalPrice
//         ourPrice
//         mehrwertsteuer
//         productDescription
//         productTechnicalDetails
//         productDownloads {
//           ...Asset
//         }
//         startDate
//         endDate
//         acceptAGB
//         EAN
//         hight
//         width
//         depth
//         weight
//         EnergyEfficiencyClass
//         EnergyConsumption
//         BuiltInDevice
//         NominalCapacity
//         WaterConsumption
//         Connectivity
//       }
//     }
//   }
//   allProductDryer {
//     edges {
//       node {
//                   _meta{
//           publishedAt
//           updatedAt
//         }
//         __typename
//         id

//         title
//         articelNumber
//         images {
//           ...Asset
//         }
//         text
//         services {
//           ...ProductService
//         }
//         normalPrice
//         ourPrice
//         mehrwertsteuer
//         productDescription
//         productTechnicalDetails
//         productDownloads {
//           ...Asset
//         }
//         startDate
//         endDate
//         acceptAGB
//         EAN
//         hight
//         width
//         depth
//         weight
//         EnergyEfficiencyClass
//         EnergyConsumption
//         DryingEfficiencyClass
//         BuiltInDevice
//         Capacity
//         CondensationOrHeatPumpDryer
//       }
//     }
//   }
//   allProductExtractorHood {
//     edges {
//       node {
//                   _meta{
//           publishedAt
//           updatedAt
//         }
//         __typename
//         id

//         title
//         articelNumber
//         images {
//           ...Asset
//         }
//         text
//         services {
//           ...ProductService
//         }
//         normalPrice
//         ourPrice
//         mehrwertsteuer
//         productDescription
//         productTechnicalDetails
//         productDownloads {
//           ...Asset
//         }
//         startDate
//         endDate
//         acceptAGB
//         EAN
//         hight
//         width
//         depth
//         weight
//         EnergyEfficiencyClass
//         EnergyConsumption
//         HasTroughfanOrDowndraft
//         BuiltInDevice
//         FilterPerformance
//         ExhaustAirOrCirculatingAir
//         Connectivity
//       }
//     }
//   }
//   allProductKitchen {
//     edges {
//       node {
//                   _meta{
//           publishedAt
//           updatedAt
//         }
//         __typename
//         id

//         title
//         articelNumber
//         images {
//           ...Asset
//         }
//         text
//         services {
//           ...ProductService
//         }
//         normalPrice
//         ourPrice
//         mehrwertsteuer
//         productDescription
//         productTechnicalDetails
//         productDownloads {
//           ...Asset
//         }
//         startDate
//         endDate
//         acceptAGB
//       }
//     }
//   }
//   allProductKitchenFurniture {
//     edges {
//       node {
//                   _meta{
//           publishedAt
//           updatedAt
//         }
//         __typename
//         id

//         title
//         articelNumber
//         images {
//           ...Asset
//         }
//         text
//         services {
//           ...ProductService
//         }
//         normalPrice
//         ourPrice
//         mehrwertsteuer
//         productDescription
//         productTechnicalDetails
//         productDownloads {
//           ...Asset
//         }
//         startDate
//         endDate
//         acceptAGB
//       }
//     }
//   }
//   allProductLighting {
//     edges {
//       node {
//                   _meta{
//           publishedAt
//           updatedAt
//         }
//         __typename
//         id

//         title
//         articelNumber
//         images {
//           ...Asset
//         }
//         text
//         services {
//           ...ProductService
//         }
//         normalPrice
//         ourPrice
//         mehrwertsteuer
//         productDescription
//         productTechnicalDetails
//         productDownloads {
//           ...Asset
//         }
//         startDate
//         endDate
//         acceptAGB
//         EAN
//         hight
//         width
//         depth
//         EnergyEfficiencyClass
//         EnergyConsumption
//         Power
//         Connectivity
//       }
//     }
//   }
//   allProductMicrowaveAndCooker {
//     edges {
//       node {
//                   _meta{
//           publishedAt
//           updatedAt
//         }
//         __typename
//         id

//         title
//         articelNumber
//         images {
//           ...Asset
//         }
//         text
//         services {
//           ...ProductService
//         }
//         normalPrice
//         ourPrice
//         mehrwertsteuer
//         productDescription
//         productTechnicalDetails
//         productDownloads {
//           ...Asset
//         }
//         startDate
//         endDate
//         acceptAGB
//         EAN
//         hight
//         width
//         depth
//         weight
//         EnergyEfficiencyClass
//         EnergyConsumption
//         steamCooker
//         BuiltInDevice
//         CookingChamberUsableVolume
//         Connectivity
//       }
//     }
//   }
//   allProductRefrigeratorAndFreezer {
//     edges {
//       node {
//                   _meta{
//           publishedAt
//           updatedAt
//         }
//         __typename
//         id

//         title
//         articelNumber
//         images {
//           ...Asset
//         }
//         text
//         services {
//           ...ProductService
//         }
//         normalPrice
//         ourPrice
//         mehrwertsteuer
//         productDescription
//         productTechnicalDetails
//         productDownloads {
//           ...Asset
//         }
//         startDate
//         endDate
//         acceptAGB
//         EAN
//         hight
//         width
//         depth
//         weight
//         EnergyEfficiencyClass
//         EnergyConsumption
//         BuiltInDevice
//         UsableCapacityCoolerCompartment
//         UsableCapacityFreezerCompartment
//         StarRating
//         NoiseEmission
//         ZeroDegreeZone
//         Connectivity
//       }
//     }
//   }
//   allProductSinkAndTap {
//     edges {
//       node {
//         __typename
//         id
//         title
//         articelNumber
//         images {
//           ...Asset
//         }
//         text
//         services {
//           ...ProductService
//         }
//         normalPrice
//         ourPrice
//         mehrwertsteuer
//         productDescription
//         productTechnicalDetails
//         productDownloads {
//           ...Asset
//         }
//         startDate
//         endDate
//         acceptAGB
//         Martial
//         color
//         Type
//       }
//     }
//   }
//   allProductSmallDevice {
//     edges {
//       node {
//                   _meta{
//           publishedAt
//           updatedAt
//         }
//         __typename
//         id

//         title
//         articelNumber
//         images {
//           ...Asset
//         }
//         text
//         services {
//           ...ProductService
//         }
//         normalPrice
//         ourPrice
//         mehrwertsteuer
//         productDescription
//         productTechnicalDetails
//         productDownloads {
//           ...Asset
//         }
//         startDate
//         endDate
//         acceptAGB
//         EAN
//         hight
//         width
//         depth
//         EnergyEfficiencyClass
//         Power
//         Connectivity
//         Category
//       }
//     }
//   }
//   allProductSparePart {
//     edges {
//       node {
//                   _meta{
//           publishedAt
//           updatedAt
//         }
//         __typename
//         id

//         title
//         articelNumber
//         images {
//           ...Asset
//         }
//         text
//         services {
//           ...ProductService
//         }
//         normalPrice
//         ourPrice
//         mehrwertsteuer
//         productDescription
//         productTechnicalDetails
//         productDownloads {
//           ...Asset
//         }
//         startDate
//         endDate
//         acceptAGB
//         EAN
//         hight
//         width
//         depth
//         weight
//       }
//     }
//   }
//   allProductStoveAndOven {
//     edges {
//       node {
//                   _meta{
//           publishedAt
//           updatedAt
//         }
//         __typename
//         id

//         title
//         articelNumber
//         images {
//           ...Asset
//         }
//         text
//         services {
//           ...ProductService
//         }
//         normalPrice
//         ourPrice
//         mehrwertsteuer
//         productDescription
//         productTechnicalDetails
//         productDownloads {
//           ...Asset
//         }
//         startDate
//         endDate
//         acceptAGB
//         EAN
//         hight
//         width
//         depth
//         weight
//         EnergyEfficiencyClass
//         EnergyConsumption
//         steamCooker
//         BuiltInDevice
//         UsableVolume
//         Connectivity
//         Pyrolysis
//         energyConsumptionTopBottomHeat
//         energyConsumptionHotAir
//       }
//     }
//   }
//   allProductVacuumDrawer {
//     edges {
//       node {
//                   _meta{
//           publishedAt
//           updatedAt
//         }
//         __typename
//         id

//         title
//         articelNumber
//         images {
//           ...Asset
//         }
//         text
//         services {
//           ...ProductService
//         }
//         normalPrice
//         ourPrice
//         mehrwertsteuer
//         productDescription
//         productTechnicalDetails
//         productDownloads {
//           ...Asset
//         }
//         startDate
//         endDate
//         acceptAGB
//         EnergyEfficiencyClass
//         EnergyConsumption
//         Connectivity
//       }
//     }
//   }
//   allProductWarmingDrawer {
//     edges {
//       node {
//                   _meta{
//           publishedAt
//           updatedAt
//         }
//         __typename
//         id

//         title
//         articelNumber
//         images {
//           ...Asset
//         }
//         text
//         services {
//           ...ProductService
//         }
//         normalPrice
//         ourPrice
//         mehrwertsteuer
//         productDescription
//         productTechnicalDetails
//         productDownloads {
//           ...Asset
//         }
//         startDate
//         endDate
//         acceptAGB
//         EnergyEfficiencyClass
//         EnergyConsumption
//         Connectivity
//       }
//     }
//   }
//   allProductWashingMachine {
//     edges {
//       node {
//                   _meta{
//           publishedAt
//           updatedAt
//         }
//         __typename
//         id

//         title
//         articelNumber
//         images {
//           ...Asset
//         }
//         text
//         services {
//           ...ProductService
//         }
//         normalPrice
//         ourPrice
//         mehrwertsteuer
//         productDescription
//         productTechnicalDetails
//         productDownloads {
//           ...Asset
//         }
//         startDate
//         endDate
//         acceptAGB
//         EAN
//         hight
//         width
//         depth
//         weight
//         EnergyEfficiencyClass
//         EnergyConsumption
//         WaterConsumption
//         BuiltInDevice
//         Capacity
//         SpinningRate
//         ProtectiveDrum
//         Connectivity
//       }
//     }
//   }
//   allProductWorktop {
//     edges {
//       node {
//                   _meta{
//           publishedAt
//           updatedAt
//         }
//         __typename
//         id

//         title
//         articelNumber
//         images {
//           ...Asset
//         }
//         text
//         services {
//           ...ProductService
//         }
//         normalPrice
//         ourPrice
//         mehrwertsteuer
//         productDescription
//         productTechnicalDetails
//         productDownloads {
//           ...Asset
//         }
//         startDate
//         endDate
//         acceptAGB
//         hight
//         width
//         depth
//         weight
//         EAN
//       }
//     }
//   }
// }
// `;
