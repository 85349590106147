import gql from "graphql-tag";

const SortingNews = gql`
  query SortingNews($after: String) {
    SortingNews {
      id
      items(after: $after) {
        __typename
        ... on News {
          id
        }
      }
    }
  }
`;

export default SortingNews;
