import gql from "graphql-tag";
import Slider from "./fragments/Slider.gql";
import Asset from "./fragments/Asset.gql";
import MetaInformation from "./fragments/MetaInformation.gql";
import SidebarText from "./fragments/SidebarText.gql";
import CallToAction from "./fragments/CallToAction.gql";
import ThemingColor from "./fragments/ThemingColor.gql";
import Icon from "./fragments/Icon.gql";
import TextElementWithCollapse from "./fragments/TextElementWithCollapse.gql";

const PageBrandOverview = gql`
  ${CallToAction}
  ${Slider}
  ${TextElementWithCollapse}
  ${Asset}
  ${MetaInformation}
  ${ThemingColor}
  ${SidebarText}
  ${Icon}

  query PageBrandOverview {
    PageBrandOverview {
      __typename
      slug
      nameInNavigation
      seo_metaDescription
      seo_pageTitle
      extras {
        __typename
        ...MetaInformation
        ...SidebarText
      }
      elements {
        __typename
        ...Asset
        ...TextElement
        ...Slider
        ...CallToAction
        ...ThemingColor
        ...Icon
      }
      id
      layout
      twoColumnLayout
      imageFixedBackground
      footnote
    }
  }
`;

export default PageBrandOverview;
