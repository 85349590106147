import { IPrimaryNavigation } from "..";

export const getBrandNavigationItem = (navigation: IPrimaryNavigation) => {
  /*
  1. find the first navigation entry that has a direct link on page brand overview
  2. find the first navigation entry that has already a sub link of page brand
  3. find the first navigation entry which inclues string "Marke"
*/
  if (navigation.items) {
      for (const item of navigation.items) {
          if (item?.link?.find((link) => link.__typename === "PageBrandOverview")) {
              return item;
          }
      }
      for (const item of navigation.items) {
          if (item?.subitems?.find((link) => link.__typename === "PageBrand")) {
              return item;
          }
      }
      for (const item of navigation.items) {
          if (item?.label && `${item.label}`.toLowerCase().includes("marke")) {
              return item;
          }
      }
  }

  return undefined;
};