import gql from "graphql-tag";

import Asset from "./Asset.gql";
import Icon from "./Icon.gql";

const TextElement = gql`
    ${Asset}
    ${Icon}
    fragment TextElement on TextElement {
        id
        text
        headline
        collapseAfterXDisabled
        icon {
            __typename
            ...Asset
            ...Icon
        }
    }
`;
export default TextElement;
