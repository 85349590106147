import gql from "graphql-tag";

const SliderComp = gql`
  fragment SliderComp on SliderComp {
    id
    __typename
    elements {
      __typename
      ...Asset
    }
  }
`;
export default SliderComp;
