import gql from "graphql-tag";

export default gql`
    query UniversalTexts {
        __typename
        UniversalTexts {
            planningDateText
            planningDateHeadline
            brandOverviewFormIntro
        }
    }
`;
