import gql from "graphql-tag";
import Asset from "./fragments/Asset.gql";
import MetaInformation from "./fragments/MetaInformation.gql";

export default gql`
  ${Asset}
  ${MetaInformation}
  query News($after: String) {
    allNews(after: $after) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          _meta {
            publishedAt
            updatedAt
          }
          startDate
          endDate
          text
          id
          externalLink
          headline
          extras {
            __typename
            ...Asset
            ...MetaInformation
          }
        }
      }
    }
  }
`;
