import gql from "graphql-tag";

const Asset = gql`
  fragment Asset on Asset{
    _meta {
      publishedAt
    }
    id
    author
    title
    originType
    copyright
    descCaisy: description
    dominantColor
    blurHash
    src
    keywords
    originalName
    width
    height
    description: subtitle
    __typename
  }
`;

export default Asset;
