// @ts-nocheck
import { IAsset } from "src/common/childTypes";

import * as React from "react";
import LazyLoad from "react-lazyload";

import SImg from "./Styles/SImg";
String.prototype.reverse = function() 
{
  return this.split("").reverse().join("");
};

String.prototype.replaceLast = function(what, replacement) 
{
  return this.reverse()
    .replace(new RegExp(what.reverse()), replacement.reverse())
    .reverse();
};

class ImgInner extends React.PureComponent 
{
  public imgRef = React.createRef();

  public state = {
    loaded: this.props.lazyload ? false : true,
  };

  public componentDidMount(): void 
  {
    if(this.imgRef.current) 
    {
      if(this.imgRef.current.complete) 
      {
        this.setState({ loaded: true });
        if(this.props.onLoad)
        {
          this.props.onLoad(this.imgRef.current);
        }
      }
      else 
      {
        this.imgRef.current.onload = () => 
        {
          this.setState({ loaded: true });
          if(this.props.onLoad)
          {
            this.props.onLoad(this.imgRef.current);
          }
        };
      }
    }
  }

  public componentWillUnmount(): void 
  {
    this.imgRef.current.onload = null;
  }

  public render(): JSX.Element 
  {
    const {
      src, alt, quality, children 
    } = this.props;

    const actualSrc: string = typeof src === "string" ? src : src?.[0]?.src;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { loaded } = this.state;
    let _src = actualSrc
      ? actualSrc
        .replace("http:", "https:")
        .replace("res.cloudinary.com", "d5u6artv10pna.cloudfront.net")
        .replaceLast("/upload", `/upload/w_${quality || "50"},f_auto`)
      : "";

    if(!_src.includes(".svg")) 
    {
      _src = _src.includes("?")
        ? _src + `&w=${quality}`
        : _src + `?w=${quality}`;
    }

    let srcSet;
    
    if(_src.includes(`w=${quality}`) && quality >= 1920) 
    {
      srcSet=`${_src} 1920w, ${_src.replace(`w=${quality}`, "w=1920")} 1280w, ${_src.replace(`w=${quality}`, "w=1280")} 640w, ${_src.replace(`w=${quality}`, "w=640")} 320w`;
    }

    return (
      <SImg loaded={loaded} {...this.props} divCss={this.props.divCss}>
        <img css={this.props.css} srcSet={srcSet} src={_src} alt={alt} ref={this.imgRef} />
        {children}
      </SImg>
    );
  }
}

export interface IImgProps 
{
  src: IAsset[] | string /** link to file */;
  children?: any;
  contain?: boolean;
  alt?: string;
  placeholderHeight?: string /** lazyload preloader height ==> default: 0 */;
  offset?: string /** offset lazyload trigger ==> default: 0 */;
  quality?: number /** set the quality of the image in px/width ==> default: 50 */;
  lazyload?: boolean /** should the image be lazyloaded on scroll? ==> default: true */;
  onLoad?: () => void /** trigger when image is loaded ==> default: null */;
  onClick?: any;
  css?: any;
  divCss?: any;
}

const Img = ({ lazyload = true, ...props }: IImgProps): JSX.Element => 
{
  if(lazyload) 
  {
    return (
      <LazyLoad
        height={
          props.placeholderHeight ? `${props.placeholderHeight}` : undefined
        }
        offset={props.offset}
      >
        <ImgInner {...props} />
      </LazyLoad>
    );
  }
  return <ImgInner {...props} lazyload={lazyload} />;
};

export { Img };
