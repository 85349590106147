import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from "react";
import { Context } from "../..";
import { RichText } from "../RichText";
import { Img } from "../Img";
import * as styles from "./SLandingBannerLeft";
import { SWrapperToFixNoPointerOnLoad } from "./SWrapperToFixNoPointerOnLoad";
import { css } from "styled-components";
import { getLinkFromConnection } from "../../utils/getLinkFromConnection";

const LandingBannerLeft: FunctionComponent<{ overrideStyles?: string }> = ({
  overrideStyles,
}) => {
  const [hasBeenClosed, setHasBeenClosed] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const [autoClosed, setAutoClosed] = useState(false);

  useEffect(() => {
    let t = setTimeout(() => {
      setAutoClosed(true);
    }, 7000);
    return () => clearTimeout(t);
  }, []);

  const props = useContext(Context);

  const bannerEvent = props.AllBanner?.sort(
    (a, b) => a._meta.publishedAt - b._meta.publishedAt
  )?.[0];

  if (
    !bannerEvent ||
    (!bannerEvent.text &&
      !bannerEvent.image &&
      (!bannerEvent.linksToPage || bannerEvent.linksToPage?.length < 1))
  ) {
    return null;
  }

  if (!bannerEvent.image) {
    bannerEvent.image = "../../../../images/banner-bg.jpg";
  }
  if (typeof window !== "undefined") {
    // console.log(` bannerEvent`, bannerEvent);
  }
  // const hasLink = !!bannerEvent?.linksToPage?.[0]?.slug;
  const { href, valid, target } = bannerEvent?.linksToPage
    ? getLinkFromConnection(bannerEvent?.linksToPage)
    : ({} as any);

  const hasLink = !!href && valid;

  const Inner = () => (
    <div
      id="lbl"
      css={css`
        ${styles.wrapper}${styles.bannerRich}${overrideStyles}${loaded
          ? `opacity: 1;`
          : `opacity: 0;`}
      `}
      style={hasBeenClosed || autoClosed ? { display: "none" } : null}
    >
      <RichText textContent={bannerEvent?.text} />
      <div onClick={() => setHasBeenClosed(true)}>
        <svg
          css={styles.close}
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <path d="M23.954 21.03l-9.184-9.095 9.092-9.174-2.832-2.807-9.09 9.179-9.176-9.088-2.81 2.81 9.186 9.105-9.095 9.184 2.81 2.81 9.112-9.192 9.18 9.1z" />
        </svg>
      </div>
      {hasLink ? (
        <a id="lbla" href={href} target={target}>
          <Img
            src={bannerEvent.image}
            onLoad={() => {
              setLoaded(true);
            }}
            quality={720}
            css={css`
              ${bannerEvent.image == "../../../../images/banner-bg.jpg"
                ? "img{filter:brightness(0.8);}"
                : ""}
              ${styles.bannerImage}
            `}
          />
        </a>
      ) : (
        <Img
          css={css`
            ${bannerEvent.image == "../../../../images/banner-bg.jpg"
              ? "img{filter:brightness(0.8);}"
              : ""}
            ${styles.bannerImage}
          `}
          src={bannerEvent.image}
          onLoad={() => {
            setLoaded(true);
          }}
          quality={720}
        />
      )}
    </div>
  );

  return (
    <SWrapperToFixNoPointerOnLoad loaded={loaded}>
      <Inner />
    </SWrapperToFixNoPointerOnLoad>
  );
};

export { LandingBannerLeft };
