import gql from "graphql-tag";

import Asset from "./Asset.gql";

const TwoColTextBlockComp = gql`
  ${Asset}
  fragment TwoColTextBlockComp on TwoColTextBlockComp {
    id
    text
    headline
    icon {
      __typename
      ...Asset
    }
  }
`;
export default TwoColTextBlockComp;
