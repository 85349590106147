import gql from "graphql-tag";

import Asset from "./fragments/Asset.gql";
import BlockImageGridComp from "./fragments/BlockImageGridComp.gql";
import DoubleImageElementComp from "./fragments/DoubleImageElementComp.gql";
import DownloadElementComp from "./fragments/DownloadElementComp.gql";
import ImageTextSideBySideElementComp from "./fragments/ImageTextSideBySideElementComp.gql";
import MetaInformation from "./fragments/MetaInformation.gql";
import SectionSeparatorComp from "./fragments/SectionSeparatorComp.gql";
import SliderComp from "./fragments/SliderComp.gql";
import TextElementComp from "./fragments/TextElementComp.gql";
import TripleImageElementComp from "./fragments/TripleImageElementComp.gql";
import TripleImageGridComp from "./fragments/TripleImageGridComp.gql";
import TwoColTextBlockComp from "./fragments/TwoColTextBlockComp.gql";


export default gql`
${SliderComp}
${TextElementComp}
${ImageTextSideBySideElementComp}
${Asset}
${BlockImageGridComp}
${DoubleImageElementComp}
${ImageTextSideBySideElementComp}
${TripleImageGridComp}
${SectionSeparatorComp}
${TripleImageElementComp}
${DownloadElementComp}
${TwoColTextBlockComp}

query allPageBrandTopic($slug: String!) {
  allPageBrandTopic(where: {slug: {eq: $slug}}) {
    edges {
      node {
        __typename
        id
        slug
        nameInNavigation
        elements {
          __typename
          ...Asset
          ...ImageTextSideBySideElementComp
          ...TextElementComp
          ...SliderComp
          ...BlockImageGridComp
          ...DoubleImageElementComp
          ...ImageTextSideBySideElementComp
          ...TripleImageGridComp
          ...TripleImageElementComp
          ...SectionSeparatorComp
          ...DownloadElementComp
          ...TwoColTextBlockComp
        }
      }
    }
  }
}`;
