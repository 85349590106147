const getText = (obj, text) => {
  const keyExists = (obj) => {
    if (!obj || (typeof obj !== "object" && !Array.isArray(obj))) {
      return;
    }
    else if (obj.hasOwnProperty('text')) {
      return text = text +  `${obj['text']}`;
    }
    else if (Array.isArray(obj)) {
      for (let i = 0; i < obj.length; i++) {
        keyExists(obj[i])
      }
    }
    else {
      for (const k in obj) {
        keyExists(obj[k]);
      }
    }
  };

  keyExists(obj);
  return text;
}

export const richToPlainText = (x) => {
  let plainText = ""

  plainText = getText( x, plainText)
  return plainText;
}