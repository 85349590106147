import styled from "styled-components";

import { BREAKPOINTS_DM } from "src/common/constants/mediaquerys";

export const Overlay: any = styled.div<{ isActive: boolean }>`
  background-color: rgba(0, 0, 0, 0.81);
  position: fixed;
  z-index: 9999999999999;
  top: 0;
  left: 0;
  opacity: 1;
  height: 100vh;
  width: 100vw;
  transition: opacity 0.6s ease;
`;

export const SliderContainer: any = styled.div`
  position: fixed;
  top: 5vh;
  left: 10vw;
  height: 90vh;
  width: 80vw;
  img {
    height: 90vh;
    width: 80vw;
  }

  p {
      height: 30px;
      padding-top: 10px;
      width: 100%;
      color: rgba(255, 255, 255, 0.8);
  }

  button.slick-arrow {
    height: 64px;
    width: 40px;
    opacity: .8;

    :hover {
      opacity: 1;
    }

    :after,
    :before {
      bottom: 0;
      right: 20px;
      position: absolute;
      display: block;
      content: "" !important;
      width: 3px;
      height: 38px;
      background-color: rgba(255,255,255,.9);
      transform: rotate(45deg);

      opacity: 1 !important;
    }

    :before {
      transform: rotate(-45deg);
      top: 0;
    }

    &.slick-next {
      right: calc(-5vw - 20px);
    }

    &.slick-prev {
      left: calc(-5vw - 20px);

      :after,
      :before {
        left: 20px;
        transform: rotate(-45deg);
      }

      :before {
        transform: rotate(45deg);
      }
    }
  }
`;

export const CloseIcon: any = styled.div`
  cursor: pointer;
  position: absolute;
  top: 5vh;
  right: 2vw;
  height: 40px;
  width: 40px;
  opacity: .4;
  background: rgba(0,0,0,.01);
  &:hover {
    opacity: 1;
  }

  :after,
  :before {
    left: 20px;
    position: absolute;
    display: block;
    content: "";
    width: 2px;
    height: 38px;
    background-color: rgba(255,255,255,.9);
    transform: rotate(45deg);
  }

  :before {
    transform: rotate(-45deg);
  }
`;
