import { print } from "graphql";
import { GraphQLClient } from "graphql-request";

export const createClient = ({
  URL,
  preview = false,
  headers = {} as any,
  prefix = "",
}) => {
  if (process.env.NODE_ENV === "development") {
    headers = preview
      ? {
          "x-caisy-preview": true,
          "x-caisy-token": "48keek31Syq2eIFaxdxFLZtcw4FzbI3I",
        }
      : { "x-caisy-token": "48keek31Syq2eIFaxdxFLZtcw4FzbI3I" };
    // headers["x-caisy-preview"] = true;
    headers["x-caisy-client-id"] = "loadtest";
  } else {
    headers = preview
      ? {
          "x-caisy-preview": true,
          "x-caisy-token": "48keek31Syq2eIFaxdxFLZtcw4FzbI3I",
        }
      : { "x-caisy-token": "48keek31Syq2eIFaxdxFLZtcw4FzbI3I" };
  }
  const c = new GraphQLClient(URL, { headers });

  return {
    query: async (rest) => {
      try {
        // const printQuery = print(rest.query);
        // const start = `${printQuery}`.substring(
        //   0,
        //   printQuery.length < 50 ? printQuery.length : 50
        // );
        // const key = `${Math.trunc(Math.random() * 100000).toString()} ${start}`;
        // console.time(key);
        const x = await c.request(rest.query, rest.variables);
        // console.timeEnd(key);

        return { data: x };
      } catch (err) {
        console.log(
          ` gql query failed query: ${rest.query && print(rest.query)}`
        );
        console.log(` gql query failed on ${URL} err: ${err}`);
        // throw err;
      }
    },
  };
};
