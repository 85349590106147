import gql from "graphql-tag";

import Asset from "./Asset.gql";

const ImageTextSideBySideElementComp = gql`
  ${Asset}
  fragment ImageTextSideBySideElementComp on ImageTextSideBySideElementComp {
    __typename
    id
    isTextOnLeft
    text
    image {
      ...Asset
    }
  }
`;
export default ImageTextSideBySideElementComp;
