import gql from "graphql-tag";

const BrandTopicTeaserElementComp = gql`
  fragment BrandTopicTeaserElementComp on BrandTopicTeaserElementComp {
    id
    items{ 
      ... on BrandTopicTeaserItemComp{
        id
        __typename
        label
        teaserImage {
          ...Asset
        }
        link{
          slug
          id
          __typename
          nameInNavigation
        }
      }
    }
    __typename
  }
`;
export default BrandTopicTeaserElementComp;
