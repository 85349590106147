import gql from "graphql-tag";
import Asset from "./Asset.gql";

const ProductService = gql`
    ${Asset}
    
    fragment ProductService on ProductService {
        title
        icon {
            __typename
            ... Asset
        }
    }
`;
export { ProductService };
