import gql from "graphql-tag";
import CallToAction from "./fragments/CallToAction.gql";
import Slider from "./fragments/Slider.gql";
import TextElement from "./fragments/TextElement.gql";
import Asset from "./fragments/Asset.gql";
import MetaInformation from "./fragments/MetaInformation.gql";
import SidebarText from "./fragments/SidebarText.gql";
import Icon from "./fragments/Icon.gql";
import ThemingColor from "./fragments/ThemingColor.gql";

const PageNews = gql`
  ${CallToAction}
  ${Slider}
  ${TextElement}
  ${Asset}
  ${MetaInformation}
  ${SidebarText}
  ${Icon}
  ${ThemingColor}

  query PageNews {
    PageNews {
      __typename
      slug
      nameInNavigation
      footnote
      seo_metaDescription
      seo_pageTitle
      extras {
        ...MetaInformation
        ...SidebarText
      }
      elements {
        __typename
        ...Asset
        ...TextElement
        ...Slider
        ...CallToAction
        ...Icon
        ...ThemingColor
      }
    }
  }
`;

export default PageNews;
