import gql from "graphql-tag";
import CallToAction from "./fragments/CallToAction.gql";
import Slider from "./fragments/Slider.gql";
import Asset from "./fragments/Asset.gql";
import MetaInformation from "./fragments/MetaInformation.gql";
import LandingEvent from "./fragments/LandingEvent.gql";
import RichLinkItem from "./fragments/RichLinkItem.gql";
import SidebarText from "./fragments/SidebarText.gql";
import ThemingColor from "./fragments/ThemingColor.gql";
import { ProductService } from "./fragments/ProductService.gql";
import HtmlEmbedding from "./fragments/HtmlEmbedding.gql";
import TextElementWithCollapse from "./fragments/TextElementWithCollapse.gql";
import PhotoGallery from "./fragments/PhotoGallery.gql";
import ContinuingPageGallery from "./fragments/ContinuingPageGallery.gql";

const PageLanding = gql`
  ${CallToAction}
  ${Slider}
  ${TextElementWithCollapse}
  ${Asset}
  ${MetaInformation}
  ${LandingEvent}
  ${RichLinkItem}
  ${SidebarText}
  ${ThemingColor}
  ${ProductService}
  ${HtmlEmbedding}
  ${PhotoGallery}
  ${ContinuingPageGallery}

  query PageLanding {
    PageLanding {
      __typename
      extras {
        ...MetaInformation
        ...SidebarText
        __typename
      }
      seo_metaDescription
      seo_noIndex
      seo_pageTitle
      seo_sitemapPriority
      id
      _meta {
        updatedAt
        publishedAt
        id
      }
      events {
        ...LandingEvent
        __typename
      }
      elements {
        __typename
        ...CallToAction
        ...TextElement
        ...Slider
        ...Asset
        ...RichLinkItem
        ...HtmlEmbedding
        ...PhotoGallery
        ...ContinuingPageGallery
      }
      seo_metaDescription
      seo_pageTitle
      footnote
      promotionalOffer {
        ... on Product {
          __typename
          id
          title
          articelNumber
          images {
            ...Asset
          }
          text
          services {
            ...ProductService
          }
          normalPrice
          ourPrice
          mehrwertsteuer
          productDescription
          productTechnicalDetails
          productDownloads {
            ...Asset
          }
          startDate
          endDate
          acceptAGB
          EAN
        }

        ... on ProductAccessoriesAndSupplies {
          __typename
          id

          title
          articelNumber
          images {
            ...Asset
          }
          text
          services {
            ...ProductService
          }
          normalPrice
          ourPrice
          mehrwertsteuer
          productDescription
          productTechnicalDetails
          productDownloads {
            ...Asset
          }
          startDate
          endDate
          acceptAGB
          EAN
          hight
          width
          depth
          weight
        }

        ... on ProductAService {
          __typename
          id

          title
          articelNumber
          images {
            ...Asset
          }
          text
          services {
            ...ProductService
          }
          normalPrice
          ourPrice
          mehrwertsteuer
          productDescription
          productTechnicalDetails
          productDownloads {
            ...Asset
          }
          startDate
          endDate
          acceptAGB
        }

        ... on ProductBathroomFurniture {
          __typename
          id

          title
          articelNumber
          images {
            ...Asset
          }
          text
          services {
            ...ProductService
          }
          normalPrice
          ourPrice
          mehrwertsteuer
          productDescription
          productTechnicalDetails
          productDownloads {
            ...Asset
          }
          startDate
          endDate
          acceptAGB
          hight
          width
          depth
          color
        }

        ... on ProductCoffeeMachine {
          __typename
          id

          title
          articelNumber
          images {
            ...Asset
          }
          text
          services {
            ...ProductService
          }
          normalPrice
          ourPrice
          mehrwertsteuer
          productDescription
          productTechnicalDetails
          productDownloads {
            ...Asset
          }
          startDate
          endDate
          acceptAGB
          EAN
          hight
          width
          depth
          weight

          EnergyConsumption
          BuiltInDevice
          FullyAutomaticCoffeeMachine
          Technology
        }

        ... on ProductConsumerElectronics {
          __typename
          id

          title
          articelNumber
          images {
            ...Asset
          }
          text
          services {
            ...ProductService
          }
          normalPrice
          ourPrice
          mehrwertsteuer
          productDescription
          productTechnicalDetails
          productDownloads {
            ...Asset
          }
          startDate
          endDate
          acceptAGB
          EAN
          hight
          width
          depth
          weight

          EnergyConsumption
        }

        ... on ProductCooktop {
          __typename
          id

          title
          articelNumber
          images {
            ...Asset
          }
          text
          services {
            ...ProductService
          }
          normalPrice
          ourPrice
          mehrwertsteuer
          productDescription
          productTechnicalDetails
          productDownloads {
            ...Asset
          }
          startDate
          endDate
          acceptAGB
          EAN
          hight
          width
          depth
          weight

          EnergyConsumption
          Connectivity
          InductionType
        }

        ... on ProductDishwasher {
          __typename
          id

          title
          articelNumber
          images {
            ...Asset
          }
          text
          services {
            ...ProductService
          }
          normalPrice
          ourPrice
          mehrwertsteuer
          productDescription
          productTechnicalDetails
          productDownloads {
            ...Asset
          }
          startDate
          endDate
          acceptAGB
          EAN
          hight
          width
          depth
          weight

          EnergyConsumption
          BuiltInDevice
          NominalCapacity
          WaterConsumption
          Connectivity
        }

        ... on ProductDryer {
          __typename
          id

          title
          articelNumber
          images {
            ...Asset
          }
          text
          services {
            ...ProductService
          }
          normalPrice
          ourPrice
          mehrwertsteuer
          productDescription
          productTechnicalDetails
          productDownloads {
            ...Asset
          }
          startDate
          endDate
          acceptAGB
          EAN
          hight
          width
          depth
          weight

          EnergyConsumption
          DryingEfficiencyClass
          BuiltInDevice
          Capacity
          CondensationOrHeatPumpDryer
        }

        ... on ProductExtractorHood {
          __typename
          id

          title
          articelNumber
          images {
            ...Asset
          }
          text
          services {
            ...ProductService
          }
          normalPrice
          ourPrice
          mehrwertsteuer
          productDescription
          productTechnicalDetails
          productDownloads {
            ...Asset
          }
          startDate
          endDate
          acceptAGB
          EAN
          hight
          width
          depth
          weight

          EnergyConsumption
          HasTroughfanOrDowndraft
          BuiltInDevice
          FilterPerformance
          ExhaustAirOrCirculatingAir
          Connectivity
        }

        ... on ProductKitchen {
          __typename
          id

          title
          articelNumber
          images {
            ...Asset
          }
          text
          services {
            ...ProductService
          }
          normalPrice
          ourPrice
          mehrwertsteuer
          productDescription
          productTechnicalDetails
          productDownloads {
            ...Asset
          }
          startDate
          endDate
          acceptAGB
        }

        ... on ProductKitchenFurniture {
          __typename
          id

          title
          articelNumber
          images {
            ...Asset
          }
          text
          services {
            ...ProductService
          }
          normalPrice
          ourPrice
          mehrwertsteuer
          productDescription
          productTechnicalDetails
          productDownloads {
            ...Asset
          }
          startDate
          endDate
          acceptAGB
        }

        ... on ProductLighting {
          __typename
          id

          title
          articelNumber
          images {
            ...Asset
          }
          text
          services {
            ...ProductService
          }
          normalPrice
          ourPrice
          mehrwertsteuer
          productDescription
          productTechnicalDetails
          productDownloads {
            ...Asset
          }
          startDate
          endDate
          acceptAGB
          EAN
          hight
          width
          depth

          EnergyConsumption
          Power
          Connectivity
        }

        ... on ProductMicrowaveAndCooker {
          __typename
          id

          title
          articelNumber
          images {
            ...Asset
          }
          text
          services {
            ...ProductService
          }
          normalPrice
          ourPrice
          mehrwertsteuer
          productDescription
          productTechnicalDetails
          productDownloads {
            ...Asset
          }
          startDate
          endDate
          acceptAGB
          EAN
          hight
          width
          depth
          weight

          EnergyConsumption
          steamCooker
          BuiltInDevice
          CookingChamberUsableVolume
          Connectivity
        }

        ... on ProductRefrigeratorAndFreezer {
          __typename
          id

          title
          articelNumber
          images {
            ...Asset
          }
          text
          services {
            ...ProductService
          }
          normalPrice
          ourPrice
          mehrwertsteuer
          productDescription
          productTechnicalDetails
          productDownloads {
            ...Asset
          }
          startDate
          endDate
          acceptAGB
          EAN
          hight
          width
          depth
          weight

          EnergyConsumption
          BuiltInDevice
          UsableCapacityCoolerCompartment
          UsableCapacityFreezerCompartment
          StarRating
          NoiseEmission
          ZeroDegreeZone
          Connectivity
        }

        ... on ProductSinkAndTap {
          __typename
          id

          title
          articelNumber
          images {
            ...Asset
          }
          text
          services {
            ...ProductService
          }
          normalPrice
          ourPrice
          mehrwertsteuer
          productDescription
          productTechnicalDetails
          productDownloads {
            ...Asset
          }
          startDate
          endDate
          acceptAGB
          Martial
          color
          Type
        }

        ... on ProductSmallDevice {
          __typename
          id

          title
          articelNumber
          images {
            ...Asset
          }
          text
          services {
            ...ProductService
          }
          normalPrice
          ourPrice
          mehrwertsteuer
          productDescription
          productTechnicalDetails
          productDownloads {
            ...Asset
          }
          startDate
          endDate
          acceptAGB
          EAN
          hight
          width
          depth

          Power
          Connectivity
          Category
        }

        ... on ProductSparePart {
          __typename
          id

          title
          articelNumber
          images {
            ...Asset
          }
          text
          services {
            ...ProductService
          }
          normalPrice
          ourPrice
          mehrwertsteuer
          productDescription
          productTechnicalDetails
          productDownloads {
            ...Asset
          }
          startDate
          endDate
          acceptAGB
          EAN
          hight
          width
          depth
          weight
        }

        ... on ProductStoveAndOven {
          __typename
          id

          title
          articelNumber
          images {
            ...Asset
          }
          text
          services {
            ...ProductService
          }
          normalPrice
          ourPrice
          mehrwertsteuer
          productDescription
          productTechnicalDetails
          productDownloads {
            ...Asset
          }
          startDate
          endDate
          acceptAGB
          EAN
          hight
          width
          depth
          weight

          EnergyConsumption
          steamCooker
          BuiltInDevice
          UsableVolume
          Connectivity
          Pyrolysis
          energyConsumptionTopBottomHeat
          energyConsumptionHotAir
        }

        ... on ProductVacuumDrawer {
          __typename
          id

          title
          articelNumber
          images {
            ...Asset
          }
          text
          services {
            ...ProductService
          }
          normalPrice
          ourPrice
          mehrwertsteuer
          productDescription
          productTechnicalDetails
          productDownloads {
            ...Asset
          }
          startDate
          endDate
          acceptAGB

          EnergyConsumption
          Connectivity
        }

        ... on ProductWarmingDrawer {
          __typename
          id

          title
          articelNumber
          images {
            ...Asset
          }
          text
          services {
            ...ProductService
          }
          normalPrice
          ourPrice
          mehrwertsteuer
          productDescription
          productTechnicalDetails
          productDownloads {
            ...Asset
          }
          startDate
          endDate
          acceptAGB

          EnergyConsumption
          Connectivity
        }

        ... on ProductWashingMachine {
          __typename
          id

          title
          articelNumber
          images {
            ...Asset
          }
          text
          services {
            ...ProductService
          }
          normalPrice
          ourPrice
          mehrwertsteuer
          productDescription
          productTechnicalDetails
          productDownloads {
            ...Asset
          }
          startDate
          endDate
          acceptAGB
          EAN
          hight
          width
          depth
          weight

          EnergyConsumption
          WaterConsumption
          BuiltInDevice
          Capacity
          SpinningRate
          ProtectiveDrum
          Connectivity
        }

        ... on ProductWorktop {
          __typename
          id

          title
          articelNumber
          images {
            ...Asset
          }
          text
          services {
            ...ProductService
          }
          normalPrice
          ourPrice
          mehrwertsteuer
          productDescription
          productTechnicalDetails
          productDownloads {
            ...Asset
          }
          startDate
          endDate
          acceptAGB
          hight
          width
          depth
          weight
          EAN
        }
      }
    }
  }
`;

export default PageLanding;
