import gql from "graphql-tag";
import Asset from "./fragments/Asset.gql";

export default gql`
  ${Asset}
  query allFullWidthBanner($after: String){
    allFullWidthBanner(after: $after) {
      edges {
        node {
          id
          bronzeImage {
            ...Asset
          }
          goldImage {
            ...Asset
          }
          diamondImage {
            ...Asset
          }
          linksTo {
            __typename
            ... on PageBrand {
              id
              slug
            }
            ... on PageBrandMain {
              id
              slug
            }
            ... on ExternerLink {
              id
              externalLinkAddress
            }
          }
        }
      }
    }
  }
`;
