import gql from "graphql-tag";

export default gql`
query AllGuestbookEntry {
  __typename
  allGuestbookEntry {
    edges {
      node {
        Evaluation
        Message
        lastname
        firstname
        commentOfDealer
        title
        id
        date
        _meta {
          publishedAt
        }
      }
    }
  }
}

`;
