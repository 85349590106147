import gql from "graphql-tag";

const SectionSeparatorComp = gql`
  fragment SectionSeparatorComp on SectionSeparatorComp {
    __typename
    id
  }
`;

export default SectionSeparatorComp;
