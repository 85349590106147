import React, { FC } from "react";
import { attrsToStyle } from "../attrsToStyle";

const IFrame: FC<{ node: any }> = ({ node: { attrs } }) => (
    <div className="iframe-outer">
        <div className="iframe-container">
            <iframe
                style={attrsToStyle(attrs)}
                src={attrs.src}
                allow={attrs.allow}
                title={attrs.title}
                frameBorder={attrs.frameborder}
                height={attrs.height}
                width={attrs.width}
                allowFullScreen={attrs.allowfullscreen}
            />
        </div>
    </div>
);

export default IFrame;
