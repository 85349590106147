import React, { FC } from "react";

const ListItem: FC = ({ children }) => {
  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { inList: true });
    }
    return child;
  });

  return <li>{childrenWithProps}</li>;
};

export default ListItem;
