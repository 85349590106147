import gql from "graphql-tag";
import TextElement from "./TextElement.gql";
import Slider from "./Slider.gql";

const NavigationItem = gql`
    ${TextElement}
    ${Slider}
    fragment NavigationItem on NavigationItem {
        id
        label
        icon {
            ...Asset
        }
        externalLink {
            __typename
            ... on ExternalLink {
                externalLinkName
                externalLinkAddress
            }
        }
        link {
            __typename
            ... on PageNews {
                slug
                nameInNavigation
            }
            ... on PageImprint {
                slug
                nameInNavigation
            }
            ... on PageContact {
                slug
                nameInNavigation
            }
            ... on PageGuestbook {
                slug
                nameInNavigation
            }
            ... on Page {
                slug
                elements {
                    __typename
                    ...TextElement
                    ...Slider
                }
                nameInNavigation
            }
            ... on PageBrandOverview {
                slug
                nameInNavigation
                elements {
                    __typename
                    ...TextElement
                    ...Slider
                }
            }
            ... on PageDateInquiry {
                slug
                nameInNavigation
            }
            ... on PageRepairInquiry {
                slug
                nameInNavigation
            }
            ... on PageMicro {
                slug
                nameInNavigation
            }
            ... on PageCourse {
                slug
                nameInNavigation
            }
            ... on ExternalLink {
                externalLinkName
                externalLinkAddress
            }
        }
        subitems {
            __typename
            ... on ExternalLink {
                externalLinkName
                externalLinkAddress
            }
            ... on PageNews {
                slug
                nameInNavigation
                elements {
                    __typename
                    ...TextElement
                    ...Asset
                }
            }
            ... on PageImprint {
                slug
                nameInNavigation
            }
            ... on PageContact {
                slug
                nameInNavigation
            }
            ... on PageGuestbook {
                slug
                nameInNavigation
            }
            ... on PageMicro {
                slug
                nameInNavigation
            }
            ... on PageCourse {
                slug
                nameInNavigation
            }
            ... on Page {
                slug
                nameInNavigation
                elements {
                    __typename
                    ...TextElement
                    ...Asset
                    ...Slider
                }
            }
            ... on PageBrandOverview {
                slug
                nameInNavigation
                elements {
                    __typename
                    ...TextElement
                    ...Asset
                    ...Slider
                }
            }
            ... on PageDateInquiry {
                slug
                nameInNavigation
            }
            ... on PageRepairInquiry {
                slug
                nameInNavigation
            }
        }
    }
`;
export default NavigationItem;
