import gql from "graphql-tag";

const Slider = gql`
  fragment Slider on Slider {
    id
    __typename
    elements {
      __typename
      ...Asset
    }
  }
`;
export default Slider;
