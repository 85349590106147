import { css } from "styled-components";

export const wrapper: any = css`
  *{
    opacity: 1 !important;
  }
  &,
  div {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    a,
    li,
    ul,
    span,
    div {
      &,
      span {
        color: white;
      }
    }
  }
  position: fixed;
  width: 350px;
  height: 350px;
  left: 0;
  z-index: 99999;
  top: calc(50% - 150px);
  max-width: 100%;
  padding: 15px;
  /* background-color: #353534; */
  div > img {
    height: auto;
  }
`;

export const WrapperToFixNoPointerOnLoad: any = css``;

export const bannerImage: any = css`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  height: 100%;
  width: 100%;
`;

export const close: any = css`
  position: absolute;
  right: 5px;
  top: 5px;
  fill: white;
  cursor: pointer;
  z-index: 1;
`;

export const bannerRich: any = css`
  > div:first-child {
    &,* {
      pointer-events: none;
    }
    a{
      pointer-events: all;
    }
    color: white;
    font-weight: 300;
    position: relative;
    z-index: 1;
    h1,
    h2,
    h3,
    h4 {
      &,
      span {
        max-width: calc(100% - 10px);
        font-size: 32px;
        min-height: 32px;
        line-height: 1.2;
        margin-bottom: 10px;
        padding-right: 20px;
      }
      a {
        max-width: calc(100% - 10px);
        font-size: 32px;
        min-height: 32px;
        line-height: 1.2;
        margin-bottom: 10px;
        padding-right: 20px;
      }
    }
    p {
      &,
      span {
        font-size: 18px;
        line-height: 1.4;
      }
    }
    a {
      &,
      span {
        opacity: 0.6;
      }
    }
  }
`;
