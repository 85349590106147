import gql from "graphql-tag";

import Asset from "./Asset.gql";

const TripleImageGridComp = gql`
${Asset}
  fragment TripleImageGridComp on TripleImageGridComp {
    id
    images{ 
      ...Asset
    }
    __typename
  }
`;
export default TripleImageGridComp;
