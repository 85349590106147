import { IGenExternalLink } from "../types_dealer_gen";

export const getLinkFromConnection = (connection) => {
  const link = connection?.[0] as any;

  if ((link as IGenExternalLink)?.externalLinkAddress) {
    const externalLinkAddress = `${
      (link as IGenExternalLink).externalLinkAddress
    }`;
    return {
      title: (link as IGenExternalLink).externalLinkName,
      valid: true,
      rel:
        externalLinkAddress.includes("https://") ||
        externalLinkAddress.includes("http://")
          ? "noopener noreferrer"
          : undefined,
      href:
        externalLinkAddress.includes("https://") ||
        externalLinkAddress.includes("http://")
          ? "https://" +
            externalLinkAddress
              .replace("http:", "https:")
              .replace("https://", "")
          : externalLinkAddress,
      target:
        externalLinkAddress.includes("https://") ||
        externalLinkAddress.includes("http://")
          ? "_blank"
          : undefined,
      download: undefined,
    };
  }

  const linkName = link?.nameInNavigation;
  const linkAddress = link?.slug;

  if (!linkAddress) {
    return {
      valid: false,
    };
  }

  return {
    valid: true,
    title: linkName,
    href: `/${link?.slug}`,
    shallow: false,
  };
};

export const getDownloadLinkFromConnection = (connection) => {
  const link = connection?.[0] || (connection as any);
  const linkAddress = link?.src;

  if (!linkAddress) {
    return {
      valid: false,
    };
  }

  return {
    title: (link as IGenExternalLink).externalLinkName,
    valid: true,
    rel: "noopener noreferrer",
    href: linkAddress,
    download: undefined,
    target: "_blank",
  };

  // try {
  //   const url = new URL(linkAddress);

  //   const linkName = link?.title;

  //   return {
  //     valid: true,
  //     title: linkName,
  //     download: linkName,
  //     href: url.pathname,
  //   };
  // } catch (err) {
  //   console.log(` err getDownloadLinkFromConnection`, err);
  //   return {
  //     valid: false,
  //   };
  // }
};
