import styled, { css } from "styled-components";
import { MIN_SILVER, MIN_PLATINUM, MIN_DIAMOND } from "../../../constants/mediaquerys";

const Default = css`
  strong {
    p {
      font-weight: 700;
    }
  }
  svg {
    margin-bottom: -2px;
    path {
      fill: black;
    }
  }
  a {
    cursor: pointer;
    color: unset;
    text-decoration: underline;
  }
  p {
    width: 100%;
    min-height: 8px;
    display: inline-block;
    overflow-wrap: break-word;
  }
  br {
    display: block;
    content: "";
    margin-top: 5px;
    line-height: 0;
  }
  iframe {
    max-width: calc(100vw - 40px);
  }
  ul {
    list-style-position: inside;
    * {
      list-style-position: inside;
    }
    li {
      display: list-item;
      list-style: disc;
    }
  }
  ol {
    list-style-position: inside;
    * {
      list-style-position: inside;
    }
    li {
      display: list-item;
      list-style: decimal;
    }
  }
  em {
    font-style: italic;
  }
`;

const Tablet = css``;

const Desktop = css``;

const Hd = css``;

export interface ISRichText {
  overwriteStyle?: any;
}

const SRichText = styled.div<ISRichText>`
  ${Default}
  ${MIN_SILVER`${Tablet}`};
  ${MIN_PLATINUM`${Desktop}`};
  ${MIN_DIAMOND`${Hd}`};
  ${({ overwriteStyle }) => (overwriteStyle ? overwriteStyle : "")};
`;
export default SRichText;
