

import gql from "graphql-tag";
import Asset from "./fragments/Asset.gql";

export default gql`
${Asset}
query allBrand($after: String) {
  __typename
  allBrand(after: $after) {
    pageInfo{
      hasNextPage
      endCursor
    }
    edges {
      node {
        id
        _meta{
          updatedAt
          publishedAt
          id
        }
        logo{
          ...Asset
        }
        name
      }
    }
  }
}
`;
