import { useContext } from "react";
import { Context } from "..";
import flatten from "lodash/flatten";
// import { richToPlainText } from "./text/plaintext";

type Period = {
    openDay: string;
    closeDay: string;
    openTime: { hours: number; minutes: number };
    closeTime: { hours: number; minutes: number };
};

type TableCellContent = {
    type: string;
    attrs?: { colspan: number; rowspan: number; colwidth: null };
    content: {
        type: string;
        attrs?: { textAlign: string };
        content: { text: string; type: string }[];
    }[];
};

type TableRow = {
    type: string;
    content: TableCellContent[];
};

function consolidateOpeningHours(data) {
    const days = ["Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"];
    const daysShort = {
        Montag: "Mo",
        Dienstag: "Di",
        Mittwoch: "Mi",
        Donnerstag: "Do",
        Freitag: "Fr",
        Samstag: "Sa",
        Sonntag: "So",
    };
    const result: any = {};
    let currentDayGroup: string[] = [];
    let lastSchedule: any = null;

    days.forEach((day) => {
        const schedule = data[day];

        // Convert the schedule to a string for easy comparison
        if (!schedule) {
            return;
        }
        const justTime = schedule.map((period) => {
            return {
                openTime: {
                    hours: period.openTime?.hours || 0,
                    minutes: period.openTime?.minutes || 0,
                },
                closeTime: {
                    hours: period.closeTime?.hours || 0,
                    minutes: period.closeTime?.minutes || 0,
                },
            };
        });
        const scheduleString = JSON.stringify(justTime);

        if (scheduleString === lastSchedule) {
            // If the schedule matches the previous day's, add to the current group
            currentDayGroup.push(day);
        } else {
            // If the schedule is different, save the previous group and start a new one
            if (currentDayGroup.length) {
                const dayRange =
                    currentDayGroup.length > 1
                        ? `${daysShort[currentDayGroup[0]]} - ${daysShort[currentDayGroup[currentDayGroup.length - 1]]}`
                        : currentDayGroup[0];
                result[dayRange] = JSON.parse(lastSchedule);
            }
            currentDayGroup = [day];
            lastSchedule = scheduleString;
        }
    });

    // Add the last group
    if (currentDayGroup.length) {
        const dayRange =
            currentDayGroup.length > 1
                ? `${daysShort[currentDayGroup[0]]} - ${daysShort[currentDayGroup[currentDayGroup.length - 1]]}`
                : currentDayGroup[0];
        result[dayRange] = JSON.parse(lastSchedule);
    }
    return result;
}

function parseOpeningHours(input: { periods: Period[] }): {
    type: string;
    content: TableRow[];
} {
    const dayTranslations: { [key: string]: string } = {
        MONDAY: "Montag",
        TUESDAY: "Dienstag",
        WEDNESDAY: "Mittwoch",
        THURSDAY: "Donnerstag",
        FRIDAY: "Freitag",
        SATURDAY: "Samstag",
        SUNDAY: "Sonntag",
    };

    const formatTime = (hours: number, minutes: number): string =>
        `${hours.toString().padStart(2, "0")}:${(minutes || 0).toString().padStart(2, "0")}`;

    // Group periods by openDay
    const groupedPeriods: { [key: string]: Period[] } = {};
    input.periods.forEach((period) => {
        const dayKeyTranslated = dayTranslations[period.openDay];
        if (!groupedPeriods[dayKeyTranslated]) {
            groupedPeriods[dayKeyTranslated] = [];
        }
        groupedPeriods[dayKeyTranslated].push(period);
    });

    const consolidated = consolidateOpeningHours(groupedPeriods);

    const rows: TableRow[] = flatten(
        Object.keys(consolidated).map((dayKeyTranslated) => {
            const periods = consolidated[dayKeyTranslated];
            const timeSlots = periods.map(
                (period) =>
                    `${formatTime(period.openTime.hours, period.openTime.minutes || 0)} - ${formatTime(
                        period.closeTime.hours,
                        period.closeTime.minutes || 0
                    )} Uhr`
            );

            return timeSlots.map((timeSlot, i) => {
                return {
                    type: "tableRow",
                    content: [
                        {
                            type: "tableCell",
                            attrs: { colspan: 1, rowspan: 1, colwidth: null },
                            content: [
                                {
                                    type: "paragraph",
                                    attrs: { textAlign: "left" },
                                    content: [
                                        {
                                            type: "text",
                                            text: i > 0 ? " " : dayKeyTranslated,
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            type: "tableCell",
                            attrs: { colspan: 1, rowspan: 1, colwidth: null },
                            content: [
                                {
                                    type: "paragraph",
                                    attrs: { textAlign: "left" },
                                    content: [{ type: "text", text: timeSlot }],
                                },
                            ],
                        },
                    ],
                };
            });
        })
    );

    return { type: "table", content: rows };
}

export const getOpeningHoursContent = (withHeadline = true) => {
    const companyInformation = useContext(Context)?.CompanyInformationPublic;
    const openingHours = companyInformation?.openingHours;
    const gmbOpeningHours = companyInformation?.gmbOpeningHours;

    if (!gmbOpeningHours?.periods) {
        return openingHours;
    }

    const parsedOutput = gmbOpeningHours && parseOpeningHours(gmbOpeningHours);

    const returnObject = {
        type: "doc",
        content: [
            ...(parsedOutput && withHeadline
                ? [
                      {
                          type: "heading",
                          attrs: { textAlign: "left", level: 3 },
                          content: [{ type: "text", text: "Öffnungszeiten:" }],
                      },
                  ]
                : []),
            ...(parsedOutput ? [parsedOutput] : []),
            ...(openingHours.content ? openingHours.content : []),
        ],
    };

    return returnObject;
};
