export interface IIHBDealer {
    phnummer?: string;
    dachseite?: boolean;
    caisyProjectId?: string;
    totalReviewsCount?: number;
    averageRating?: number;
}

export const getIHBDealerFromHost = async ({ host: inputHost }: { host: string }): Promise<IIHBDealer> => {
    let host = inputHost.replace("https://", "");
    if (host.includes("/")) {
        host = host.split("/")[0];
    }
    // console.log(` getIHBDealerFromHost:`, host);
    if (host.includes("gedk.caisy.site") || (process.env.SUB_ALIAS && process.env.SUB_ALIAS != "")) {
        const slug = host
            .replace(".dev.gedk.caisy.site", "")
            .replace(".staging.gedk.caisy.site", "")
            .replace(".gedk.caisy.site", "")
            .replace("www.", "")
            .replace(".de", "")
            .replace(".com", "");

        try {
            // console.log(` slug`, slug);
            // console.log(` process.env.SUB_ALIAS`, process.env.SUB_ALIAS);
            const url =
                process.env.NEXT_PUBLIC_GEDK_API_ENDPOINT +
                `/api/v1/ihb/dealer?slug=${
                    process.env.SUB_ALIAS && process.env.SUB_ALIAS != "" ? process.env.SUB_ALIAS : slug
                }`;

                // console.log(` url`, url);
            const x = await fetch(url);

            const resObject = await x.json();
            // console.log(` resObject`, resObject);
            return {
                ...resObject,
                phnummer: resObject.phnummer ? `${resObject.phnummer}` : undefined,
            };
        } catch {}
    } else {
        try {
            // console.log(` host`, host);
            const x = await fetch(process.env.NEXT_PUBLIC_GEDK_API_ENDPOINT + `/api/v1/ihb/dealer?domain=${host}`);

            const resObject = await x.json();
            // console.log(` dealer`, resObject);

            return {
                ...resObject,
                phnummer: resObject.phnummer ? `${resObject.phnummer}` : undefined,
            };
        } catch {}
    }

    return {};
};
