import gql from "graphql-tag";
import TextElement from "./fragments/TextElement.gql";
import Asset from "./fragments/Asset.gql";
import MetaInformation from "./fragments/MetaInformation.gql";
import SidebarText from "./fragments/SidebarText.gql";
import Icon from "./fragments/Icon.gql";
import Course from "./fragments/Course.gql";
export default gql`
  ${TextElement}
  ${Asset}
  ${MetaInformation}
  ${SidebarText}
  ${Icon}
  ${Course}
  query allPageCourse($slug: String!) {
    allPageCourse(where: { slug: { eq: $slug } }) {
      edges {
        node {
          __typename
          slug
          seo_metaDescription
          seo_pageTitle
          nameInNavigation
          extras {
            ...MetaInformation
            ...SidebarText
          }
          elements {
            __typename
            ...Asset
            ...TextElement
            ...Icon
          }
          courses {
            __typename
            ...Course
          }
        }
      }
    }
  }
`;
