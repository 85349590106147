import gql from "graphql-tag";

import Asset from "./Asset.gql";

const DownloadElementComp = gql`
  ${Asset}
  fragment DownloadElementComp on DownloadElementComp {
    id
    headline
    subtitle
    text
    image {
      ...Asset
    }
    downloadAsset{
      ...Asset
    }
  }
`;

export default DownloadElementComp;
