import gql from "graphql-tag";
import Asset from "./Asset.gql";

const ContinuingPageGallery = gql`
    ${Asset}
    fragment ContinuingPageGallery on ContinuingPageGallery {
        __typename
        maxCountPerRow
        items {
            ... on ContinuingPageGalleryItem {
                __typename
                id
                link {
                    __typename
                    ... on ExternalLink {
                        externalLinkName
                        externalLinkAddress
                    }
                    ... on Product {
                      __typename
                      text
                             title
                            ourPrice
                            normalPrice
                            startDate
                            endDate
                            acceptAGB
                    }
                    ... on ProductAccessoriesAndSupplies {
                      text
                             title
                            ourPrice
                            normalPrice
                            startDate
                            endDate
                            acceptAGB
                    }
                    ... on ProductAService {
                      __typename
                      text
                             title
                            ourPrice
                            normalPrice
                            startDate
                            endDate
                            acceptAGB
                    }
                    ... on ProductBathroomFurniture {
                      __typename
                      text
                             title
                            ourPrice
                            normalPrice
                            startDate
                            endDate
                            acceptAGB
                    }
                    ... on ProductCoffeeMachine {
                      __typename
                      text
                             title
                            ourPrice
                            normalPrice
                            startDate
                            endDate
                            acceptAGB
                    }
                    ... on ProductConsumerElectronics {
                      __typename
                      text
                             title
                            ourPrice
                            normalPrice
                            startDate
                            endDate
                            acceptAGB
                    }
                    ... on ProductCooktop {
                      __typename
                      text
                             title
                            ourPrice
                            normalPrice
                            startDate
                            endDate
                            acceptAGB
                    }
                    ... on ProductDishwasher {
                      __typename
                      text
                             title
                            ourPrice
                            normalPrice
                            startDate
                            endDate
                            acceptAGB
                    }
                    ... on ProductDryer {
                      __typename
                      text
                             title
                            ourPrice
                            normalPrice
                            startDate
                            endDate
                            acceptAGB
                    }
                    ... on ProductExtractorHood {
                      __typename
                      text
                             title
                            ourPrice
                            normalPrice
                            startDate
                            endDate
                            acceptAGB
                    }
                    ... on ProductKitchen {
                      __typename
                      text
                             title
                            ourPrice
                            normalPrice
                            startDate
                            endDate
                            acceptAGB
                    }
                    ... on ProductKitchenFurniture {
                      __typename
                      text
                             title
                            ourPrice
                            normalPrice
                            startDate
                            endDate
                            acceptAGB
                    }
                    ... on ProductLighting {
                      __typename
                      text
                             title
                            ourPrice
                            normalPrice
                            startDate
                            endDate
                            acceptAGB
                    }
                    ... on ProductMicrowaveAndCooker {
                      __typename
                      text
                             title
                            ourPrice
                            normalPrice
                            startDate
                            endDate
                            acceptAGB
                    }
                    ... on ProductRefrigeratorAndFreezer {
                      __typename
                      text
                             title
                            ourPrice
                            normalPrice
                            startDate
                            endDate
                            acceptAGB
                    }
                    ... on ProductSinkAndTap {
                      __typename
                      text
                             title
                            ourPrice
                            normalPrice
                            startDate
                            endDate
                            acceptAGB
                    }
                    ... on ProductSmallDevice {
                      __typename
                      text
                             title
                            ourPrice
                            normalPrice
                            startDate
                            endDate
                            acceptAGB
                    }
                    ... on ProductSparePart {
                      __typename
                      text
                             title
                            ourPrice
                            normalPrice
                            startDate
                            endDate
                            acceptAGB
                    }
                    ... on ProductStoveAndOven {
                      __typename
                      text
                             title
                            ourPrice
                            normalPrice
                            startDate
                            endDate
                            acceptAGB
                    }
                    ... on ProductVacuumDrawer {
                      __typename
                      text
                             title
                            ourPrice
                            normalPrice
                            startDate
                            endDate
                            acceptAGB
                    }
                    ... on ProductWarmingDrawer {
                      __typename
                      text
                             title
                            ourPrice
                            normalPrice
                            startDate
                            endDate
                            acceptAGB
                    }
                    ... on ProductWashingMachine {
                      __typename
                      text
                             title
                            ourPrice
                            normalPrice
                            startDate
                            endDate
                            acceptAGB
                    }
                    ... on ProductWaschDryer {
                      __typename
                      text
                             title
                            ourPrice
                            normalPrice
                            startDate
                            endDate
                            acceptAGB
                    }
                    ... on ProductWorktop {
                      __typename
                      text
                             title
                            ourPrice
                            normalPrice
                            startDate
                            endDate
                            acceptAGB
                    }
                    ... on Page {
                      __typename
                      slug
                      nameInNavigation
                      elements {
                          __typename
                          ...TextElement
                      }
                      id
                    }
                    ... on PageBrand {
                      __typename
                      slug
                      nameInNavigation
                      elements {
                          __typename
                          ...TextElement
                      }
                      id
                    }
                }
                thumbnail {
                    ...Asset
                }
                title
                __typename
                text
            }
        }
    }
`;
export default ContinuingPageGallery;
