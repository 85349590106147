import gql from "graphql-tag";
import Asset from "./fragments/Asset.gql";

export default gql`
${Asset}

query CompanyInformationPublic  {
  __typename
  CompanyInformationPublic {
    _meta {
      id
    }
    companyName
    companyNameOverwriteContact
    companyNameOverwriteImprint
    country
    email
    facebook
    fax
    id
    logo{
      ...Asset
    }
    favicon{
      ...Asset
    }
    managingDirector
    openingHours
    gmbOpeningHours
    phone
    telephoneHours
    pintrest
    youtube
    instagram
    tiktok
    whatsapp
    place
    street
    serviceNumber
    postcode
    imprintAddendum
    persHaftendeGesName
    persHaftendeGesSitz
    persHaftendeGesHandelsregister
    taxID
    twitter
    commercialRegister
    companyFormOfLaw
  }
}
`;
