import gql from "graphql-tag";
import CallToAction from "./CallToAction.gql";
import Slider from "./Slider.gql";
import Asset from "./Asset.gql";
import MetaInformation from "./MetaInformation.gql";
import ThemingColor from "./ThemingColor.gql";
import TextElementWithCollapse from "./TextElementWithCollapse.gql";

const RichLinkItem = gql`
  ${CallToAction}
  ${Slider}
  ${TextElementWithCollapse}
  ${Asset}
  ${MetaInformation}
  ${ThemingColor}
  fragment RichLinkItem on RichLinkItem {
    headline
    richDesc: description
    image {
      ...Asset
    }
    linkTo {
      __typename
      ... on PageNews {
        slug
        elements {
          __typename
          ...Asset
          ...TextElement
          ...Slider
          ...CallToAction
          ...ThemingColor
        }
      }
      ... on PageContact {
        slug
        elements {
          __typename
          ...Asset
          ...TextElement
          ...Slider
          ...CallToAction
          ...ThemingColor
        }
      }
      ... on PageGuestbook {
        slug
        elements {
          __typename
          ...Asset
          ...TextElement
          ...Slider
          ...CallToAction
          ...ThemingColor
        }
      }
      ... on Page {
        slug
        elements {
          __typename
          ...Asset
          ...TextElement
          ...Slider
          ...CallToAction
          ...ThemingColor
        }
      }
      ... on PageDateInquiry {
        slug
        elements {
          __typename
          ...Asset
          ...TextElement
          ...Slider
          ...CallToAction
          ...ThemingColor
        }
      }
      ... on PageRepairInquiry {
        slug
        elements {
          __typename
          ...Asset
          ...TextElement
          ...Slider
          ...CallToAction
          ...ThemingColor
        }
      }
      ... on PageBrand {
        slug
        elements {
          __typename
          ...Asset
          ...TextElement
          ...Slider
        }
      }
      ... on PageBrandOverview {
        slug
        elements {
          __typename
          ...Asset
          ...TextElement
          ...Slider
        }
      }
      ... on ExternalLink {
        externalLinkAddress
        externalLinkName
      }
    }
  }
`;
export default RichLinkItem;
