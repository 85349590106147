import gql from "graphql-tag";

export default gql`
  query allPageMicro($slug: String!) {
    allPageMicro(where: { slug: { eq: $slug } }) {
      edges {
        node {
          seo_metaDescription
          seo_pageTitle
          __typename
          slug
          nameInNavigation
          externalLinkAddress
        }
      }
    }
  }
`;
