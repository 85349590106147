import { IAsset, Img } from "src/common";

import React, { FC, useEffect, useState, useRef, useLayoutEffect } from "react";
import ReactDOM from "react-dom";

import * as styles from "./FullscreenImage.style";

if(typeof window === "undefined") { React.useLayoutEffect = React.useEffect; }

interface IProps 
{
  img: IAsset[];
  subtitle: string;
  quality?: any;
  lazyload?: boolean;
  noWrapper?: boolean;
  id?: string;
}

const Modal: FC = (props) => 
{
  const container = useRef<HTMLDivElement>(null);

  useEffect(() => 
  {
    let el;

    if(typeof window !== "undefined") 
    {
      el = document.createElement("div");
      const rootContainer = document.querySelector("#__next");
      rootContainer.appendChild(el);
      container.current = el;
    }

    return () => el?.remove();
  }, []);

  return container.current
    ? ReactDOM.createPortal(props.children, container.current)
    : null;
};

const Inner: FC<IProps> = (props) => 
{
  const [isActive, setIsActive] = useState(false);
  const [captionWidth, setCaptionWidth] = useState<number | undefined>(
    undefined
  );
  const [imageStyles, setImageStyles] = useState<any | undefined>(undefined);

  const imgClass = `img_${props.id}`;

  const computeWidth = () => 
  {
    if(window.innerWidth > 1024) 
    {
      const el = document.getElementsByClassName(imgClass)?.[0];
      const child = el?.firstChild as any;
      if(child) 
      {
        const imgW = child?.naturalWidth;
        const imgH = child?.naturalHeight;
        const windowHeight = window.innerHeight;
        if(imgW && imgH) 
        {
          const imgRatio = imgW / imgH;
          const frameHeight = windowHeight - 90 - 180;
          const newWidth = Math.round(frameHeight * imgRatio);
          if(newWidth > window.innerWidth - 80) 
          {
            setCaptionWidth(window.innerWidth);
            setImageStyles({
              width: "unset",
              objectFit: "unset",
              maxWidth: "calc(100vw - 40px)",
            });
          }
          else 
          {
            setImageStyles(undefined);
            setCaptionWidth(newWidth);
          }
        }
      }
    }
    else 
    {
      setImageStyles(undefined);
      setCaptionWidth(undefined);
    }
  };
  
  const callback = () => 
  {
    isActive && computeWidth();
  };

  React.useLayoutEffect(() => 
  {
    window.addEventListener("resize", callback);

    return () => 
    {
      window.removeEventListener("resize", callback);
    };
  }, [isActive]);

  const setActive = (active) => 
  {
    if(active) 
    {
      computeWidth();
    }
    setIsActive(active);
  };

  return (
    <>
      <Img
        // @ts-ignore
        className={imgClass}
        src={props.img}
        quality={props?.quality || 1920}
        alt={props.subtitle}
        onClick={() => setActive(true)}
        lazyload={props.lazyload}
      />
      <Modal>
        <div
          css={`
            ${styles.wrapper} ${isActive
      ? styles.wrapperActive
      : styles.wrapper}
          `}
          onClick={() => setActive(false)}
        >
          <div
            css={styles.fullScreenWrapper}
            style={isActive ? { display: "block" } : { display: "none" }}
            data-fullscreen-image-wrapper
          >
            <div>
              <img
                src={props.img?.[0]?.src}
                css={styles.imgFullScreen}
                style={imageStyles ? { ...imageStyles } : undefined}
                alt={"image"}
              />
              <div
                className="fullscreen-caption"
                style={captionWidth ? { width: `${captionWidth}px` } : {}}
              >
                {props.subtitle && props.subtitle != "<invalid Value>" && (
                  <p data-title>{props.subtitle}</p>
                )}
                <svg
                  data-close
                  css={styles.svg}
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path d="M23 20.168l-8.185-8.187 8.185-8.174-2.832-2.807-8.182 8.179-8.176-8.179-2.81 2.81 8.186 8.196-8.186 8.184 2.81 2.81 8.203-8.192 8.18 8.192z" />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export const FullscreenImage: FC<IProps> = (props) => 
{
  if(props.noWrapper) { return <Inner key={props.id} {...props} />; }

  return (
    <div css={!props.noWrapper ? styles.img : null} data-fullscreen-image>
      <Inner key={props.id} {...props} />
    </div>
  );
};
