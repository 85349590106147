import React, { FC } from "react";

const Paragraph: FC<{ inList: boolean; node: any }> = ({ children, node, ...props }) => {
  if (props.inList) {
    return (
      <span
        style={
          node?.attrs?.textAlign && node?.attrs?.textAlign !== "left"
            ? { textAlign: node?.attrs?.textAlign }
            : undefined
        }
      >
        {children}
      </span>
    );
  };

  return (
    <p
      style={
        node?.attrs?.textAlign && node?.attrs?.textAlign !== "left"
          ? { textAlign: node?.attrs?.textAlign }
          : undefined
      }
    >
      {children}
    </p>
  );
};

export default Paragraph;
