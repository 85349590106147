import dayjs from "dayjs";
import "dayjs/locale/de";
import React, { FC } from "react";

dayjs.locale("de");

const FORMAT = "DD MMMM YYYY";

const DateFormatted: FC<{ date: Date; format?: string }> = ({ date, format = FORMAT }) => {
  const formatted = dayjs(date).format(format);
  return (
    <span data-formatted-date>{formatted}</span>
  );
};

export default DateFormatted;
