import gql from "graphql-tag";

import Asset from "./Asset.gql";

const DoubleImageElementComp = gql`
  ${Asset}
  fragment DoubleImageElementComp on DoubleImageElementComp {
    id
    __typename
    images{
      ...Asset
    }
  }
`;  

export default DoubleImageElementComp;
