async function postData(url = "", data = {}) {
  // Default options are marked with *
  const response = await fetch(url, {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    mode: "no-cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    headers: {
      "Content-Type": "application/json",
    },
    redirect: "follow", // manual, *follow, error
    referrerPolicy: "no-referrer",
    body: JSON.stringify(data),
  });
  return await response.json(); // parses JSON response into native JavaScript objects
}

const parseDate = (inDate: string) => {
  if (inDate.includes("/")) {
    // const parts = inDate.split("/");

    // if (parts.length === 3) {
    //   const dIn = parts[1] + "-" + parts[0] + "-" + parts[2];
    //   const d = new Date(dIn);
    //   const dString = d.toLocaleDateString("de-DE");
    //   return dString;
    // }
    console.log(` inDate`, inDate);
    const d = new Date(inDate);
    return d.toLocaleDateString("de-DE");
  } else {
    const d = new Date(inDate);
    const dString = d.toLocaleDateString("de-DE");
    return dString;
    // return inDate;
  }
};

export const handleFormSubmit = async (data) => {
  const { errors } = data;
  if (!errors || Object.keys(errors).length === 0) {
    if (data.privacy || !data.checkbox) {
      if (!data.checkbox && typeof window !== undefined) {
        window.alert(`Bitte Datenschutzerklärung akzeptieren`);
      }
      return;
    }

    const body = {
      ...data,
      ...(data?.datum ? { datum: parseDate(`${data.datum}`) } : {}),
      ...(data?.date ? { date: parseDate(`${data.date}`) } : {}),
    };
    console.log(` data`, data);

    delete data.checkbox;
    const mailRes = await postData(`/api/mail`, body);
    console.info(`Mail API said: `, mailRes);
  } else if (typeof window !== undefined) {
    window.alert(`Error: ${errors}`);
  }
};
