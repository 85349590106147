import React, { FC, useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import Slider from "react-slick";
import { IAsset, Img } from "src/common";
import SSlickBase from "../../utils/slick/SSlickBase";
import SSlickTheme from "../../utils/slick/SSlickTheme";
import { LazyLoadTypes } from "react-slick";
import { Overlay, SliderContainer, CloseIcon } from "./FullscreenSlider.style";

const Modal: FC = (props) => {
  const container = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let el;

    if (typeof window !== "undefined") {
      el = document.createElement("div");
      const rootContainer = document.querySelector("#__next");
      rootContainer.appendChild(el);
      container.current = el;
    }

    return () => el?.remove();
  }, []);

  return container.current ? ReactDOM.createPortal(props.children, container.current) : null;
};

const STANDARD_SETTINGS = {
  dots: false,
  infinite: true,
  arrows: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplaySpeed: 6000,
  autoplay: true,
  fade: true,
  lazyLoad: "progressive" as LazyLoadTypes,
};

export const StandardSlider: FC<{
  img: IAsset[];
  subtitle: string;
  quality?: any;
  lazyload?: boolean;
  elements: IAsset[];
}> = ({
  img, quality, subtitle, lazyload, elements,
}) => {
  if(!elements){
    return null;
  }
  const [isActive, setIsActive] = useState(false);
  const current = elements.indexOf(img?.[0]);
  return (
    <>
      <Img src={img} quality={quality} alt={subtitle} onClick={() => setIsActive(true)} lazyload={lazyload} />
      <Modal>
        { isActive && (
          <Overlay>
            <CloseIcon onClick={() => setIsActive(false)}/>
            <SliderContainer data-silder-container>
              <div
                css={`
                  ${SSlickBase}
                  ${SSlickTheme}
                `}
                data-fullscreen-slider
              >
                <Slider {...STANDARD_SETTINGS} initialSlide={current}>
                  {elements.map((element, i) => (
                    <Img quality={1920} key={`fllscreen-slider-image${i}`} contain={true} src={element?.src} alt={element.description ?? "image"} />
                  ))}
                </Slider>
              </div>
            </SliderContainer>
          </Overlay>
        )}
      </Modal>
    </>
  );
};

export default StandardSlider;
