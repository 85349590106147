import gql from "graphql-tag";

export const q_SitemapInfo = gql`
    query SitemapInfo {
        PrimaryNavigation {
            items {
                ... on NavigationItem {
                    link {
                        ... on PageNews {
                            slug
                        }
                        ... on PageContact {
                            slug
                        }
                        ... on PageMicro {
                            slug
                        }
                        ... on PageCourse {
                            slug
                        }
                        ... on PageGuestbook {
                            slug
                        }
                        ... on Page {
                            slug
                        }
                        ... on PageDateInquiry {
                            slug
                        }
                        ... on PageRepairInquiry {
                            slug
                        }
                    }
                }
            }
        }
        PageNews {
            slug
            seo_sitemapPriority
            seo_noIndex
        }
        PageContact {
            slug
            seo_sitemapPriority
            seo_noIndex
        }
        PageGuestbook {
            slug
            seo_sitemapPriority
            seo_noIndex
        }
        PageDateInquiry {
            slug
            seo_sitemapPriority
            seo_noIndex
        }
        PageRepairInquiry {
            slug
            seo_sitemapPriority
            seo_noIndex
        }
        allPageMicro {
            edges {
                node {
                    slug
                    seo_sitemapPriority
                    seo_noIndex
                }
            }
        }
        allPageCourse {
            edges {
                node {
                    slug
                    seo_sitemapPriority
                    seo_noIndex
                }
            }
        }
    }
`;
