
import gql from 'graphql-tag';

export default gql`
query UniversalTextsRoot {
  __typename
  UniversalTexts {
    yourLocation
    CalculateRoute
    StartAddress
    Submit
    captchaSpamProtection
    contactForm
    eMail
    gestbook__back
    gestbook__evaluation
    guestbook__cancel
    guestbook__email
    guestbook__firstname
    guestbook__message
    guestbook__preview
    guestbook__send
    guestbook__title
    guestbook__lastname
    guestbook__thanks
    guestbook_writeContribution
    message
    name
  }
}`;