import gql from "graphql-tag";

import Asset from "./fragments/Asset.gql";

import BlockImageGridComp from "./fragments/BlockImageGridComp.gql";
import BrandTopicTeaserElementComp from "./fragments/BrandTopicTeaserElementComp.gql";
import DoubleImageElementComp from "./fragments/DoubleImageElementComp.gql";
import DownloadElementComp from "./fragments/DownloadElementComp.gql";
import ImageTextSideBySideElementComp from "./fragments/ImageTextSideBySideElementComp.gql";
import SectionSeparatorComp from "./fragments/SectionSeparatorComp.gql";
import SliderComp from "./fragments/SliderComp.gql";
import TextElementComp from "./fragments/TextElementComp.gql";
import TripleImageElementComp from "./fragments/TripleImageElementComp.gql";
import TripleImageGridComp from "./fragments/TripleImageGridComp.gql";
import TwoColTextBlockComp from "./fragments/TwoColTextBlockComp.gql";

export default gql`
  ${Asset}
  ${SliderComp}
  ${TextElementComp}
  ${TwoColTextBlockComp}
  ${BrandTopicTeaserElementComp}
  ${BlockImageGridComp}
  ${DoubleImageElementComp}
  ${ImageTextSideBySideElementComp}
  ${TripleImageGridComp}
  ${SectionSeparatorComp}
  ${TripleImageElementComp}
  ${DownloadElementComp}

  query allPageBrandMain($after: String) {
    allPageBrandMain(after: $after) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          __typename
          slug
          nameInNavigation
          brandLogo{
            ...Asset
          }
          brandDescription
          hideInNavigationPlus
          elements {
            __typename
            ...Asset
            ...TextElementComp
            ...TwoColTextBlockComp
            ...SliderComp
            ...BrandTopicTeaserElementComp
            ...BlockImageGridComp
            ...DoubleImageElementComp
            ...TripleImageElementComp
            ...ImageTextSideBySideElementComp
            ...TripleImageGridComp
            ...SectionSeparatorComp
            ...DownloadElementComp
          }
          subtopics {
            ... on PageBrandTopic {
              __typename
              id
              slug
              nameInNavigation
            }
          }
          id
        }
      }
    }
  }
`;
