import React, { useContext, FunctionComponent } from "react";
import { Context, RichText } from "../..";
import { getContactCompanyNameShort } from "../ImprintStart";
import { getOpeningHoursContent } from "../../utils/getOpeningHoursContent";

export const ContactPublicInformation: FunctionComponent<any> = (props: any) => {
  const {
    companyName,
    companyNameOverwriteContact,
    street,
    place,
    postcode,
    openingHours,
    managingDirector,
    phone,
    fax,
  } = useContext(Context)?.CompanyInformationPublic;
  const openingHoursContent = getOpeningHoursContent();

  const cN = companyNameOverwriteContact || companyName;
  return (
    <>
       {cN && (
          <h2>
            {getContactCompanyNameShort()}
          </h2>
        )}
    <div>
      <div>
        {managingDirector && (
          <h4>
            {managingDirector}
          </h4>
        )}
        {street && <p>{street}</p>}
        {postcode && place && (
          <p>
            {postcode} {place}
          </p>
        )}
        <br />
        {phone && <p>Tel: {phone}</p>}
        {fax && <p>Fax: {fax}</p>}
      </div>
      <div>
        {openingHoursContent && <RichText fragment textContent={openingHoursContent}></RichText>}
        {props.children}
      </div>
    </div>
    </>
  );
};
