import React, { FC } from "react";

type Marks = "bold" | "italic" | "strike" | "link" | "underline";

const Mark: FC<{ mark: Marks; attrs: any }> = ({ mark, attrs, children }) => {
  switch (mark) {
    case "bold":
      return <strong>{children}</strong>;
    case "italic":
      return <em>{children}</em>;
    case "strike":
      return <s>{children}</s>;
    case "link":
      // making all links have target blank

      // https://kuechenplaner.
      let classnames = [];
      if (`${attrs.href}`.endsWith(".pdf")) {
        classnames.push("pdf");
      }
      if (`${attrs.href}`.endsWith(".doc")) {
        classnames.push("pdf");
      }
      if (`${attrs.href}`.endsWith(".docx")) {
        classnames.push("pdf");
      }

      if (`${attrs.href}`.indexOf("https://kuechenplaner.") == 0) {
        classnames.push("kuechenplaner");
      }

      let target: any = undefined;
      if (`${attrs.href}`.includes("://")){
        target = "_blank";
      }

      if (typeof window !== "undefined" && `${attrs.href}`.includes(window.location.hostname)) {
        target = undefined;
      }

      return (
        <a
          href={attrs.href}
          target={target}
          className={classnames.join(",")}
        >
          {children}
        </a>
      );

    case "underline":
      return <u>{children}</u>;

    default:
      if (children) {
        return <>{children}</>;
      } else {
        return null;
      }
  }
};

const Text: FC<{ node: any }> = ({ node }) =>
  node.marks ? (
    node.marks.reduce(
      (memo: JSX.Element, mark: { type: Marks; attrs: any }) => {
        return (
          <Mark mark={mark.type} attrs={mark.attrs}>
            {memo}
          </Mark>
        );
      },
      <>{node.text}</>
    )
  ) : (
    <>{node.text}</>
  );

export default Text;
