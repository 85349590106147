
import gql from "graphql-tag";

export default gql`
query allDeviceType{
  allDeviceType{
    edges{
      node{
        name
      }
    }
  }
}

`;
