import gql from "graphql-tag";
export const q_allPagePaginated =  gql`
query allPagePaginated($after: String) {
  allPage(after: $after) {
    totalCount
    pageInfo {
      endCursor
      hasNextPage
    }
    edges {
      node {
        slug
        seo_sitemapPriority
        seo_noIndex
        id
      }
    }
  }
}`;
