import gql from 'graphql-tag';
import NavigationItem from './fragments/NavigationItem.gql';
import Asset from './fragments/Asset.gql';

const PrimaryNavigation = gql`
${NavigationItem}
${Asset}
query PrimaryNavigation {
  PrimaryNavigation {
    items {
      ...NavigationItem
    }
  }
}`;

export default PrimaryNavigation;
