import dynamic from "next/dynamic";
import React, {
  FunctionComponent,
} from "react";
// @ts-ignore
const LogoBannerHorizontalNoSSR = dynamic(async () => import("./LogoBannerHorizontalNoSSR").then(mod => mod.LogoBannerHorizontalNoSSR), { ssr: false });

const LogoBannerHorizontal: FunctionComponent<{shadowOverlayVisible?: boolean; whiteBackground?: boolean}> = ({
  shadowOverlayVisible,
  whiteBackground,
}) => 
  <LogoBannerHorizontalNoSSR whiteBackground={whiteBackground} shadowOverlayVisible={shadowOverlayVisible} />;

export { LogoBannerHorizontal };
