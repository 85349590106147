import gql from "graphql-tag";
import CallToAction from "./fragments/CallToAction.gql";
import Slider from "./fragments/Slider.gql";
import TextElement from "./fragments/TextElement.gql";
import Asset from "./fragments/Asset.gql";
import MetaInformation from "./fragments/MetaInformation.gql";
import SidebarText from "./fragments/SidebarText.gql";

const PageContact = gql`
  ${CallToAction}
  ${Slider}
  ${TextElement}
  ${Asset}
  ${MetaInformation}
  ${SidebarText}
  query PageContact {
    PageContact {
      __typename
      slug
      seo_metaDescription
      seo_pageTitle
      nameInNavigation
      footnote
      extras {
        ...MetaInformation
        ...SidebarText
      }
      location {
        latitude
        longitude
        zoom
        formattedAddress
      }
      elements {
        __typename
        ...Asset
        ...TextElement
        ...Slider
        ...CallToAction
      }
    }
  }
`;

export default PageContact;
