import gql from "graphql-tag";
import CallToAction from "./fragments/CallToAction.gql";
import Slider from "./fragments/Slider.gql";
import TextElement from "./fragments/TextElement.gql";
import Asset from "./fragments/Asset.gql";
import MetaInformation from "./fragments/MetaInformation.gql";
import SidebarText from "./fragments/SidebarText.gql";

const PageDateInquiry = gql`
  ${CallToAction}
  ${Slider}
  ${TextElement}
  ${Asset}
  ${MetaInformation}
  ${SidebarText}

  query PageDateInquiry {
    PageDateInquiry {
      __typename
      slug
      nameInNavigation
      footnote
      seo_metaDescription
      seo_pageTitle
      extras {
        ...MetaInformation
        ...SidebarText
      }
      elements {
        __typename
        ...Asset
        ...TextElement
        ...Slider
        ...CallToAction
      }
    }
  }
`;

export default PageDateInquiry;
