import { INavigationItem, ISubNavigationItem, ITextElement, IContext } from "./index";
import { IPageBrand } from "./rootTypes";
import { richToPlainText } from "./utils/text/plaintext";

export const getPage = (p: IContext) => {
    switch (true) {
        case p.slug === undefined || p.slug === null:
            return p.PageLanding;
        case p.PageImprint?.slug === p?.slug:
            return p.PageImprint;
        case p.PageBrandOverview?.slug === p?.slug:
            return p.PageBrandOverview;
        case p.PageBrand?.slug === p?.slug:
            return p.PageBrand as unknown as IPageBrand;
        case p.PageContact?.slug === p?.slug:
            return p.PageContact;
        case p.PageDateInquiry?.slug === p?.slug:
            return p.PageDateInquiry;
        case p.PageGuestbook?.slug === p?.slug:
            return p.PageGuestbook;
        case p.PageImprint?.slug === p?.slug:
            return p.PageImprint;
        case p.PageRepairInquiry?.slug === p?.slug:
            return p.PageRepairInquiry;
        case p.PageNews?.slug === p?.slug:
            return p.PageNews;
        case p.PageCourse?.slug === p?.slug:
            return p.PageCourse;
        case p.Page?.slug === p?.slug:
            return p.Page;
        default:
            return p.PageLanding;
    }
};

interface IHeaderItemProps {
    title: string;
    link: string;
    headline?: string;
    external?: boolean;
}

export const getHeaderNavItemProps = (item: INavigationItem | ISubNavigationItem): IHeaderItemProps | false => {
    const linkItem = item?.link?.[0] || (item as any)?.externalLink?.[0];

    if ((linkItem as any)?.externalLinkAddress || (linkItem as any)?.__typename === "ExternalLink") {
        return {
            link:
                "https://" +
                `${(linkItem as any).externalLinkAddress}`.replace("http:", "https:").replace("https://", ""),
            title: `${(item as any).label ?? (linkItem as any).externalLinkName}`,
            external: true,
        };
    }

    const link = linkItem?.slug || (item as any)?.slug;
    const title = (linkItem as any)?.nameInNavigation || (item as any)?.nameInNavigation;
    let headline;

    if (!link || !title) {
        return false;
    }

    if (
        linkItem?.__typename === "Page" ||
        linkItem?.__typename === "PageNews" ||
        linkItem?.__typename === "PageGuestbook"
    ) {
        headline = linkItem.elements?.find((e) => e?.__typename == "TextElement")?.[0]?.headline;
    }

    return {
        link,
        title,
        headline,
    };
};

export const getIfNewsShouldBeDisplayByDate = (eventDates: {
    endDate: string | Date;
    startDate: string | Date;
}): boolean => {
    if (!eventDates.startDate && !eventDates.endDate) {
        return true;
    } else if (!eventDates.startDate && eventDates.endDate) {
        return new Date() <= new Date(eventDates.endDate);
    } else if (eventDates.startDate && !eventDates.endDate) {
        return new Date() >= new Date(eventDates.startDate);
    } else {
        return new Date() >= new Date(eventDates.startDate) && new Date() <= new Date(eventDates.endDate);
    }
};
export const getFirstTextBlock = (page: any) => {
    return page?.elements?.find((e) => e?.__typename == "TextElement") as ITextElement;
};
export const getFirstTextBlockCompined = (page: any) => {
    const ele = page?.elements?.find((e) => e?.__typename == "TextElement") as ITextElement;
    const text = ele?.text ?? "";
    const headline = ele?.headline;
    if (headline) {
        return `# ${headline}

    ${text}`;
    }
    return text;
};

export const getAllTextBlockCompined = (page: any) => {
    const fElements = (
        page?.elements
            ?.filter((e) => e?.__typename == "TextElement")
            .map((ele) => {
                const text = ele?.text ?? "";
                const headline = ele?.headline;
                if (headline) {
                    return `# ${headline}
                ${text}`;
                }
                return text;
            }) as string[]
    )?.filter((e) => !!e);
    return fElements;
};

export const getTextBlocks = (page: any) => {
    return page?.elements?.filter((e) => e?.__typename == "TextElement");
};
export const getTextBlocksJoined = (page: any) => {
    return getTextBlocks(page)
        ?.map((e) => e?.text ?? "")
        .join("");
};

export const getCurrentSubNavItem = (subNavItems: any[], slug: string): boolean => {
    return !!subNavItems?.find((item) => {
        return item?.slug === slug;
    });
};

export const getCurrentNavItem = (navItems: any[], slug: string): INavigationItem =>
    slug &&
    navItems?.find((item) => {
        return item?.link?.[0]?.slug === slug || getCurrentSubNavItem(item?.subitems as any[], slug);
    });

// const hashCode = s => s.split('').reduce((a,b)=>{a=((a<<5)-a)+b.charCodeAt(0);return a&a},0)

export const getRandomColor = (): string => {
    const colors = ["#008bc5", "#fed061", "#ee765e", "#83d0f5", "#69c0ac"];
    return colors[Math.floor(Math.random() * colors.length)];
};

// Input: ExtrasArray and desired type
// Returns: An Array with the items of the desired type or [null]
// DONT CHECK FOR ARRAY.length because this will return 1
// Just check for !array[0]. If its null, the array is empty
// imgSubtitle Boolean for img + subTitle

export const extrasMapper = (extrasArray, typename, imgSubTitle?: boolean): any => {
    if (!extrasArray) {
        /* console.log("extras Array is null");*/
        return [null];
    }

    let result = extrasArray.map((extra) => {
        // For future types
        switch (extra?.__typename) {
            case "Asset":
                if (extra?.__typename !== typename) {
                    return null;
                }
                return extra;
            case "SidebarText":
                if (extra?.__typename !== typename) {
                    return null;
                }
                return extra.sidebarText;
            case "MetaInformation":
                if (extra?.__typename !== typename) {
                    return null;
                }
                return extra;
            default:
                /* console.log("Type is not in helper function! Type found: [" + extra?.__typename + "]");*/
                return null;
        }
    });

    // Filter out nulls returned from map
    result = result?.filter((item) => item);

    if (result.length === 0) {
        /* console.log("No types from the extras array matched the function input");*/
        return [null];
    }

    return result;
};

interface Size {
    width: number;
    height: number;
}

export const getObjectFitMath = (parent: Size, child: Size, fitMode: "cover" | "contain"): Size => {
    const wr = parent.width / child.width;
    const hr = parent.height / child.height;
    const ratio = fitMode === "cover" ? Math.max(wr, hr) : Math.min(wr, hr);

    const width = child.width * ratio;
    const height = child.height * ratio;
    const size = { width, height };

    return size;
};

export const truncateText = (text: any, amountOfCharsToTruncateAfter: number) => {
    if (!text) return text;

    let truncatedText = "";
    for (let i = 0; i < text.length; i++) {
        if (i > amountOfCharsToTruncateAfter && text.charAt(i) === " ") {
            break;
        }
        truncatedText += text.charAt(i);
    }
    return truncatedText + "...";
};

export const getChunkedRichtext = ({
    text,
    maxTextCharacter = 250,
}: {
    /** richtext object */
    text: any;
    /** Default is 250 chars */
    maxTextCharacter?: number;
}) => {
    if (!text) {
        return {
            chunkedText: text,
            didChunk: false,
        };
    }

    if (richToPlainText(text)?.length <= maxTextCharacter) {
        return {
            chunkedText: text,
            didChunk: false,
        };
    }

    let wordsCounter: number = 0;
    let currentIndex = 0;

    const elementIterator = (element, child = false) => {
        const elementContent: any[] = element?.content;
        if (!Array.isArray(elementContent) || elementContent?.length <= 0) return;

        elementContent.forEach((element, index) => {
            const subElementContent: any[] = element?.content;
            if (!Array.isArray(subElementContent) || subElementContent?.length <= 0) return;

            for (const childElement of subElementContent) {
                if (childElement?.content?.length > 0) {
                    elementIterator(childElement, true);
                }

                const textChunk = childElement?.text;

                if (textChunk?.length > 0 && wordsCounter <= maxTextCharacter) {
                    const chars = textChunk.length;
                    wordsCounter += chars;
                } else {
                    break;
                }

                if (!child) {
                    currentIndex = index;
                }
            }
        });
    };

    elementIterator(text);

    // const chunkedPart = text?.content?.slice(0, currentIndex + 1);
    const rest = text?.content?.slice(currentIndex + 1);

    const chunkMakesSense = rest && rest.length > 0 && richToPlainText(rest)?.length > 0;
    if (!chunkMakesSense) {
        return {
            chunkedText: text,
            didChunk: false,
        };
    }

    return {
        chunkedText: {
            ...text,
            content: text?.content?.slice(0, currentIndex + 1),
        },
        didChunk: true,
    };
};

export const debounce = (params: { callBack: () => void; delay: number }) => {
    let timer: any;
    return () => {
        clearTimeout(timer);
        timer = setTimeout(() => params.callBack(), params.delay);
    };
};
