// GQL
import QuerySettings from "./graphql/Settings.gql";
import QueryAllDeviceType from "./graphql/AllDeviceType.gql";
import QueryAllGuestbookEntry from "./graphql/AllGuestBookEntry.gql";
import QueryCompanyInformationPrivate from "./graphql/CompanyInformationPrivate.gql";
import QueryCompanyInformationPublic from "./graphql/CompanyInformationPublic.gql";
import QueryPrimaryNavigation from "./graphql/PrimaryNavigation.gql";
import QueryUniversalTexts from "./graphql/UniversalTexts.gql";
import QueryUniversalTextsRoot from "./graphql/UniversalTextsRoot.gql";
import QueryFooter from "./graphql/Footer.gql";
import QueryPageLanding from "./graphql/PageLanding.gql";
import QueryPageContact from "./graphql/PageContact.gql";
import QueryPageBrandOverview from "./graphql/PageBrandOverview.gql";
import QueryAllProduct from "./graphql/AllProduct.gql";
import QueryPageDateInquiry from "./graphql/PageDateInquiry.gql";
import QueryPageRepairInquiry from "./graphql/PageRepairInquiry.gql";
import QueryPageGuestbook from "./graphql/PageGuestbook.gql";
import QueryPageNews from "./graphql/PageNews.gql";
import QueryPageImprint from "./graphql/PageImprint.gql";
import QueryAllPageCourse from "./graphql/AllPageCourse.gql";
import QueryAllPageMicro from "./graphql/AllPageMicro.gql";
import QueryAllPageBrand from "./graphql/AllPageBrand.gql";
import QueryAllPage from "./graphql/AllPage.gql";
import QueryAllBanner from "./graphql/AllBanner.gql";
import QueryAllPageBrandTopic from "./graphql/AllPageBrandTopic.gql";

import React from "react";
export { ServiceForm } from "./components/ServiceForm";
export { RichText } from "./components/RichText";
export { LandingBannerLeft } from "./components/LandingBannerLeft";
export { Img } from "./components/Img";
export { DateFormatted } from "./components/DateFormatted";
export { FullscreenImage } from "./components/FullscreenImage";
export { FullscreenSlider } from "./components/FullscreenSlider";
export { ImprintStart } from "./components/ImprintStart";
export { ContactPublicInformation } from "./components/ContactPublicInformation";
export { LogoBannerHorizontal } from "./components/LogoBannerHorizontal";
export { ShareLinks } from "./components/ShareLinks";
import {
    IAsset,
    IIcon,
    IMetaInformation,
    IProduct as IProductChild,
    ISidebarText,
    ITextElement,
    Maybe,
    Scalars,
} from "./childTypes";
import { applyDefaultColorsToSettings } from "./data-fetching/applyDefaultColorsToSettings";

var slugify = require("slugify");

function fixCompanyInformationPrivate(inputRespose) {
    const lineItems = [
        { key: "none", value: "Keine Linie Anzeigen" },
        { key: "dk", value: "dk das küchenhaus" },
        { key: "gelectra", value: "Gelectra" },
        { key: "gk", value: "GK Gute Küchen" },
        { key: "ihs", value: "Ihr Hausgerätespezialist" },
        { key: "kg", value: "Küchengilde" },
        { key: "kgp", value: "Küchen.Geräte.Partner." },
        { key: "kw", value: "kw küchenwerkstatt" },
        { key: "pk", value: "Perfekte Küchen" },
    ];

    if (inputRespose && inputRespose.data && inputRespose.data.CompanyInformationPrivate) {
        return {
            data: {
                CompanyInformationPrivate: {
                    ...inputRespose.data.CompanyInformationPrivate,
                    line: lineItems.find(
                        (li) =>
                            li.key == inputRespose.data.CompanyInformationPrivate.line ||
                            li.value == inputRespose.data.CompanyInformationPrivate.line
                    )?.key,
                },
            },
        };
    }

    return inputRespose;
}

// import {print} from 'graphql';
// console.info(print(QueryPageLanding));
export const Context = React.createContext<IContext>(null);

export const GlobalPageWrapper = (children, ...props) => {
    return (
        <Context.Provider value={props as any}>
            <div>
                <Context.Provider value={props as any}>{children ?? null}</Context.Provider>
            </div>
        </Context.Provider>
    );
};

export const GlobalLandingWrapper = (children, ...props) => {
    return (
        <Context.Provider value={props as any}>
            <div>
                <Context.Provider value={props as any}>{children ?? null}</Context.Provider>
            </div>
        </Context.Provider>
    );
};

function removeUnf(obj) {
    for (const key in obj) {
        if (obj[key] && typeof obj[key] === "object") {
            removeUnf(obj[key]);
        } else obj[key] = obj[key] === undefined ? null : obj[key];
    }
}

import { getClient, getRootClient } from "./utils/apolloClient";
import {
    IPageContact,
    IPage,
    IPrimaryNavigation,
    IPageNews as IPageNewsPartial,
    IPageRepairInquiry,
    IPageDateInquiry,
    IPageGuestbook,
    IPageCourse,
    ICompanyInformationPublic,
    ICompanyInformationPrivate,
    IFooter,
    IPageLanding as IPageLandingPartial,
    IGuestbookEntry,
    IPageBrandOverview,
    IBanner,
    IPageBrand,
    INews,
    ISettings,
} from "./childTypes";

import { IPageImprint, IBrand, IUniversalTexts, IDeviceType } from "./rootTypes";
import { getIfNewsShouldBeDisplayByDate } from "./helper";
import { getIHBDealerFromHost, IIHBDealer } from "./utils/getIHBDealer";
import { getNews } from "./data-fetching/getNews";
import { getNav } from "./data-fetching/getNav";
import { getAllRootPageBrand } from "./data-fetching/getAllRootPageBrand";
import { getAllBrand } from "./data-fetching/allBrand";
import gql from "graphql-tag";
import { q_SitemapInfo } from "./graphql/SitemapInfo.gql";
import { allPagePaginated } from "./data-fetching/allPageMeta";
import { IGenPageBrandMain, IGenPageBrandTopic } from "./__generated/root/types";
import { getAllFullWidthBanner } from "./data-fetching/allFullWidthBanner";
import { fixLayoutEnum } from "./utils/fix-select-enum/layout";
import { getAllRootPageBrandOld } from "./data-fetching/getAllRootPageBrandOld";
import { getBrandNavigationItem } from "./utils/getBrandNavigationItem";

interface IPageNews extends IPageNewsPartial {
    NewsArticles?: INews[];
    NewsArticlesCustom?: INews[];
}

interface IPageLanding extends IPageLandingPartial {
    [x: string]: any;
    News?: INews[];
    NewsCustom?: INews[];
}

export interface IProduct extends IProductChild {
    slug: string;
}

export type ICourse_Extras = IMetaInformation | ISidebarText | undefined;
export type ICourse_Elements = IAsset | IIcon | ITextElement | undefined;

export type ICourse = {
    __typename?: "Course";
    id?: Maybe<Scalars["ID"]>;
    registrationDeadline?: Maybe<Scalars["DateTime"]>;
    pricePerParticipantInEuro?: Maybe<Scalars["Float"]>;
    briefDescription?: any;
    kursNr?: Maybe<Scalars["Float"]>;
    maxParticipants?: Maybe<Scalars["Float"]>;
    endDate?: Maybe<Scalars["DateTime"]>;
    organizer?: Maybe<Scalars["String"]>;
    startDate?: Maybe<Scalars["DateTime"]>;
    extras?: Maybe<Array<Maybe<ICourse_Extras>>>;
    elements?: Maybe<Array<Maybe<ICourse_Elements>>>;
    nameInNavigation?: Maybe<Scalars["String"]>;
    _meta?: Maybe<ICaisyDocument_Meta>;
};

export type ICaisyDocument_Meta = {
    __typename?: "CaisyDocument_Meta";
    locale?: Maybe<Scalars["String"]>;
    locales?: Maybe<Array<Maybe<Scalars["String"]>>>;
    publishedAt?: Maybe<Scalars["DateTime"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
    id?: Maybe<Scalars["ID"]>;
};

export type IPageMicro = {
    __typename?: "PageMicro";
    id?: Maybe<Scalars["ID"]>;
    slug?: Maybe<Scalars["String"]>;
    externalLinkAddress?: Maybe<Scalars["String"]>;
    nameInNavigation?: Maybe<Scalars["String"]>;
    _meta?: Maybe<ICaisyDocument_Meta>;
};
export type IFullWidthBanner = {
    id?: Maybe<Scalars["ID"]>;
    goldImage?: IAsset;
    bronzeImage?: IAsset;
    diamondImage?: IAsset;
};

export interface IContext {
    Page?: IPage;
    PageCourse?: IPageCourse;
    PageMicro?: IPageMicro;
    PageBrand?: IPageBrand;
    PageBrandOverview?: IPageBrandOverview;
    PageBrandTopic?: IGenPageBrandTopic;
    allRootBrandPages?: IGenPageBrandMain[];
    PageNews?: IPageNews;
    PageImprint?: IPageImprint;
    PageGuestbook?: IPageGuestbook;
    AllGuestbookEntry?: IGuestbookEntry[];
    AllDeviceType?: IDeviceType[];
    currentItemId: string;
    CompanyInformationPublic: ICompanyInformationPublic;
    CompanyInformationPrivate: ICompanyInformationPrivate;
    PageRepairInquiry?: IPageRepairInquiry;
    PageDateInquiry?: IPageDateInquiry;
    PrimaryNavigation?: IPrimaryNavigation;
    Footer?: IFooter;
    AllBrand: IBrand[];
    AllBanner: IBanner[];
    AllProduct: IProduct[];
    UniversalTexts: IUniversalTexts;
    PageContact?: IPageContact;
    Settings?: ISettings;
    dealer?: IIHBDealer;
    slug: string;
    PageLanding: IPageLanding;
    NewsSlug: string;
    host?: string;
    fullWidthBanner?: IFullWidthBanner;
    ccmScript?: string;
}

const getAllowedIds = async (dealer) => {
    let fetchedData: any = undefined;
    try {
        const endpoint = process.env.NEXT_PUBLIC_GEDK_API_ENDPOINT;
        const res = await fetch(
            endpoint +
                "/api/v1/common/dv4-documents?phnummer=" +
                dealer.phnummer +
                "&dtype=read&project_id=69983cb0-621b-4cf0-8886-367b6f4a4d47"
        );
        fetchedData = await res.json();
    } catch (e) {
        console.log("Failed to fetch", e);
    }
    const allowedIds = fetchedData?.documentIds;
    return allowedIds || [];
};

const formatFooter = async ({ footer, imprint }: any) => {
    const ImprintNames = imprint?.nameInNavigation.split("&");
    let Footer = (await footer).data?.Footer;

    if (Footer?.Navigationspunkte && Array.isArray(Footer?.Navigationspunkte)) {
        const newNavPoints: any[] = [];
        Footer.Navigationspunkte.forEach((np) => {
            if (np?.nameInNavigation?.includes("&")) {
                const newNames = np.nameInNavigation.split("&");
                newNames.forEach((name) => {
                    newNavPoints.push({
                        slug: np.slug + `#${name.trim()}`,
                        nameInNavigation: name,
                        __typename: np?.__typename,
                    });
                });
            } else {
                newNavPoints.push(np);
            }
        });
        Footer.Navigationspunkte = newNavPoints;
    }
    if (!Footer) {
        Footer = {};
    }
    if (!(Footer?.Navigationspunkte && Array.isArray(Footer?.Navigationspunkte))) {
        Footer.Navigationspunkte = [];
    }

    Footer.Navigationspunkte = Footer.Navigationspunkte.filter((e) => !!e);

    if (ImprintNames) {
        ImprintNames.map((a) => {
            if (!Footer.Navigationspunkte.find((e) => e?.nameInNavigation === a)) {
                Footer.Navigationspunkte.push({
                    slug: imprint.slug + `#${a.trim()}`,
                    nameInNavigation: a.trim(),
                    __typename: imprint?.__typename,
                });
            }
        });
    } else {
        console.warn(`MISSING Imprint!`);
    }

    return Footer;
};

const getAllBrandsFilterd = ({ allBrandsRoot, companyInformationPrivate }) => {
    return (
        allBrandsRoot?.filter((b) => {
            return !!companyInformationPrivate?.brands?.selectedBrands?.find((sb) => sb.id == b.id);
        }) ?? []
    );
};

const getAllBannerFilterd = ({ allBanner }: { allBanner: any }) => {
    const currentDate = new Date();
    const edges = allBanner?.data?.allBanner?.edges ?? [];

    return edges
        ?.map((e) => {
            const expireAt = e?.node?.expireAt && new Date(e?.node?.expireAt);
            if (expireAt && currentDate > expireAt) {
                return null;
            }

            if (e?.node?.asset?.src) {
                return {
                    ...e.node,
                    image: e.node.asset.src,
                };
            }

            return e?.node;
        })
        .filter((e) => !!e);
};

export const getCCMScript = async ({ host }: { host: string }): Promise<string | undefined> => {
    if (!host) {
        return undefined;
    }

    const NEXT_PUBLIC_GEDK_API_ENDPOINT = process.env.NEXT_PUBLIC_GEDK_API_ENDPOINT;
    try {
        const url = `${NEXT_PUBLIC_GEDK_API_ENDPOINT}/api/v1/ccm/script?domain=${host}`;
        // console.log(` NEXT_PUBLIC_GEDK_API_ENDPOINT url`, url);
        const res = await fetch(url);
        const text = await res.text();
        // console.log(` text`, text);
        const data = JSON.parse(text);
        // console.log(` data`, data);
        return data?.snippet;
    } catch (err) {
        // console.log(`err getCCMScript:`, err);
        return undefined;
    }
};
// export const settingsWithCCM = ({settings, snippet}: {settings: ISettings, host: string}): ISettings => {
//   props?.CompanyInformationPrivate?.customHtml?.value
//   return settings
// };

export const getAllMeta = async (ctx): Promise<any> => {
    const slugs = [];
    try {
        let host = `${ctx.req?.headers?.["host"]}`;
        const xF = `${ctx.req?.headers?.["cxdomain"] || ctx.req?.headers?.["domain"] || ctx.req?.headers?.["X-Forwarded-For"]}`;
        const f = `${ctx.req?.headers?.["Forwarded"]}`;
        host =
            (xF && xF !== "" && xF !== "undefined" ? xF : undefined) ||
            (f && f !== "" && f !== "undefined" ? f : undefined) ||
            host;

        const dealer = await getIHBDealerFromHost({ host: host });
        const allowedIdsProm = getAllowedIds(dealer);
        const rootClient = await getRootClient({ ctx });
        const allPageBrandRootProm = getAllRootPageBrand({ rootClient });
        const apolloClient = await getClient({
            ctx,
            projectId: dealer.caisyProjectId,
            host,
        });
        const pageNews = apolloClient.query({ query: QueryPageNews });
        const allPageProm = allPagePaginated({ client: apolloClient });

        const sitemapInfo = apolloClient.query({
            query: q_SitemapInfo,
        });
        const allowedIds = await allowedIdsProm;

        return {
            ...((await sitemapInfo).data ? (await sitemapInfo).data : {}),
            NewsSlug: (await pageNews)?.data?.PageNews?.slug || null,
            allPage: await allPageProm,
            allPageBrand: ((await allPageBrandRootProm) ?? [])
                .filter((p) => allowedIds.includes(p.id))
                .map((p: any) => ({
                    slug: p.slug,
                    id: p.id,
                })),
        };
    } catch (err) {
        console.log(`getAllSlugs err`, err);
    }

    return {};
};

const fetchBrandPages = async ({
    companyInformationPrivate,
    rootClient,
    host,
    type,
}: {
    companyInformationPrivate: any;
    host: string;
    rootClient: any;
    type: string;
}): Promise<any[]> => {
    if (type !== "old" && type !== "new") {
        throw new Error("fetchBrandPages type must be old or new");
    }

    // const companyInformationPrivateResolved = fixCompanyInformationPrivate(
    //   await companyInformationPrivate
    // )?.data?.CompanyInformationPrivate

    // const useMarkenwelt = companyInformationPrivateResolved?.useMarkenwelt?.value === true || host?.includes(".dev.gedk.");

    // console.log(` fetchBrandPages useMarkenwelt=${useMarkenwelt} type=${type}`, );

    if (type === "old") {
        // const allPageBrandOldRootProm = getAllRootPageBrandOld({ rootClient });
        return [];
    }
    const allPageBrandRootProm = getAllRootPageBrand({ rootClient });
    return allPageBrandRootProm;
};

const getPageBrandUnique = ({ pageBrandRoot, pageBrandRootOld, allowedIds }) => {
    const pageBrandRootAllowed = pageBrandRoot.filter((p) => allowedIds.includes(p.id));
    const pageBrandRootOldAllowed = pageBrandRootOld.filter((p) => allowedIds.includes(p.id));

    // const slugsOld = pageBrandRootOldAllowed.filter((p) => p.slug && p.slug.toLowerCase() == 'siemens').map(s => s.slug);
    // const slugsNew = pageBrandRootAllowed.filter((p) => p.slug && p.slug.toLowerCase() == 'siemens');
    // const slugsNew = pageBrandRootAllowed.filter((p) => p.slug).map(s => s.slug);

    return [
        ...pageBrandRootAllowed,
        ...pageBrandRootOldAllowed.filter(
            (item) =>
                !pageBrandRootAllowed.some(
                    (existingItem) =>
                        existingItem?.slug == item?.slug || existingItem?.nameInNavigation == item?.nameInNavigation
                )
        ),
    ];
};

export const getInitialPropsLanding = async (ctx): Promise<any> => {
    try {
        let host = `${ctx.req?.headers?.["host"]}`;
        const xF = `${ctx.req?.headers?.["cxdomain"] || ctx.req?.headers?.["domain"] || ctx.req?.headers?.["X-Forwarded-For"]}`;
        const f = `${ctx.req?.headers?.["Forwarded"]}`;
        host =
            (xF && xF !== "" && xF !== "undefined" ? xF : undefined) ||
            (f && f !== "" && f !== "undefined" ? f : undefined) ||
            host;

        const ccmScriptProm = getCCMScript({ host });
        const dealer = await getIHBDealerFromHost({ host: host });
        const allowedIdsProm = getAllowedIds(dealer);
        const rootClient = await getRootClient({ ctx });

        const allBrand = getAllBrand({ rootClient });
        const apolloClient = await getClient({
            ctx,
            projectId: dealer.caisyProjectId,
            host,
        });

        const companyInformationPrivate = apolloClient.query({
            query: QueryCompanyInformationPrivate,
        });

        const allPageBrandRootProm = fetchBrandPages({ companyInformationPrivate, rootClient, host, type: "new" });

        const settings = apolloClient.query({ query: QuerySettings });
        const primaryNavigation = apolloClient.query({
            query: QueryPrimaryNavigation,
        });
        const landingData = apolloClient.query({ query: QueryPageLanding });
        const companyInformationPublicData = apolloClient.query({
            query: QueryCompanyInformationPublic,
        });

        const footer = apolloClient.query({ query: QueryFooter });
        const universalTextsRoot = rootClient.query({
            query: QueryUniversalTextsRoot,
        });

        const universalTexts = apolloClient.query({ query: QueryUniversalTexts });
        const pageNews = apolloClient.query({ query: QueryPageNews });
        const pageDateInquiry = apolloClient.query({ query: QueryPageDateInquiry });
        const pageRepairInquiry = apolloClient.query({
            query: QueryPageRepairInquiry,
        });
        const pageContact = apolloClient.query({ query: QueryPageContact });
        const allBanner = apolloClient.query({ query: QueryAllBanner });

        const allowedIds = await allowedIdsProm;

        const fullWidthBanner = (await getAllFullWidthBanner({ rootClient })).filter((n) =>
            allowedIds.includes(n.id)
        )[0];

        const news = await getNews(allowedIds, apolloClient, rootClient);
        const newsCustom = await getNews(allowedIds, apolloClient, rootClient, true);
        const pageImprint = rootClient.query({ query: QueryPageImprint });

        const newsToRender = news?.filter((newsItem) =>
            getIfNewsShouldBeDisplayByDate({
                startDate: newsItem?.startDate,
                endDate: newsItem?.endDate,
            })
        );
        const newsToRenderCustom = newsCustom?.filter((newsItem) =>
            getIfNewsShouldBeDisplayByDate({
                startDate: newsItem?.startDate,
                endDate: newsItem?.endDate,
            })
        );

        const PageLanding = ((await landingData) as any)?.data?.PageLanding;
        if (PageLanding) {
            PageLanding.News = newsToRender.length < 8 ? newsToRender : newsToRender.slice(0, 7);
            PageLanding.NewsCustom =
                newsToRenderCustom.length < 8 ? newsToRenderCustom : newsToRenderCustom.slice(0, 7);
            PageLanding.promotionalOffer =
                PageLanding.promotionalOffer?.map((p) => {
                    return {
                        slug: slugify(p?.title ?? "", { strict: true, lower: true }),
                        ...p,
                    };
                }) ?? [];
        }

        const pageBrandRoot = await allPageBrandRootProm;
        const pageBrandRootOld = [];

        const fixedCompanyInformationPrivate = fixCompanyInformationPrivate(await companyInformationPrivate);
        const line = fixedCompanyInformationPrivate?.data?.CompanyInformationPrivate?.line;
        const template = fixedCompanyInformationPrivate?.data?.CompanyInformationPrivate?.template;
        const Settings = applyDefaultColorsToSettings((await settings).data?.Settings, line, template);

        const nav = await getNav({
            settings: Settings,
            navigation: (await primaryNavigation).data?.PrimaryNavigation,
            pageBrandRoot: getPageBrandUnique({ pageBrandRoot, pageBrandRootOld, allowedIds: await allowedIdsProm }),
            allowedIds: await allowedIdsProm,
        });

        // t0 iframe tempalte for brand area redirect
        if (Settings.template == "t0") {
            const pageBrandOverviewPromise = apolloClient.query({
                query: QueryPageBrandOverview,
            });

            const pageBrandOverview = (await pageBrandOverviewPromise)?.data?.PageBrandOverview;

            if (pageBrandOverview && pageBrandOverview.slug) {
                console.log(
                    ` Settings.template t0 that is why we redirect to brands page, instead of landing`,
                    `${Settings.template}`
                );
                ctx.res.writeHead(307, { Location: `/${pageBrandOverview.slug}` });
                ctx.res.end();

                return { props: {} };
            }
        }

        if (PageLanding) {
            if (PageLanding?.layout) {
                PageLanding.layout = fixLayoutEnum(PageLanding.layout);
            }
        }

        const res = {
            PageContact: (await pageContact)?.data?.PageContact,
            PrimaryNavigation: nav,
            fullWidthBanner: fullWidthBanner ?? null,
            CompanyInformationPrivate: fixCompanyInformationPrivate(await companyInformationPrivate)?.data
                ?.CompanyInformationPrivate,
            CompanyInformationPublic: (await companyInformationPublicData).data?.CompanyInformationPublic,
            Footer: await formatFooter({
                footer,
                imprint: (await pageImprint)?.data.PageImprint,
            }),
            dealer,
            AllBanner: getAllBannerFilterd({ allBanner: await allBanner }),
            AllBrand: getAllBrandsFilterd({
                allBrandsRoot: await allBrand,
                companyInformationPrivate: fixCompanyInformationPrivate((await companyInformationPrivate).data)
                    ?.CompanyInformationPrivate,
            }),
            UniversalTexts: {
                ...(await universalTextsRoot).data?.UniversalTexts,
                ...(await universalTexts).data?.UniversalTexts,
            },
            PageDateInquiry: (await pageDateInquiry)?.data.PageDateInquiry,
            PageRepairInquiry: (await pageRepairInquiry)?.data.PageRepairInquiry,
            NewsSlug: (await pageNews)?.data?.PageNews?.slug,
            slug: ctx?.query?.slug?.[0] ?? null,
            Settings,
            PageLanding,
            ccmScript: await ccmScriptProm,
        };

        removeUnf(res);
        return {
            props: res,
        };
    } catch (err) {
        if (ctx && ctx.res && ctx.res.statusCode) {
            ctx.res.statusCode = 500;
        }
        console.log(` err`, err);
        return {
            props: {
                error: `${(err as any).message}`,
            },
        } as any;
    }
};

export const getInitialPropsSlug = async (ctx): Promise<any> => {
    try {
        const slug = ctx?.query?.slug?.[0];
        const slugs = ctx?.query?.slug ?? [];

        const fullUrl = ctx?.query?.slug?.join("/");
        if (slug === "fonts" || slug === "favicon.ico" || slug === "images" || slug.includes("service-worker")) {
            return {
                props: {},
            } as any;
        }

        const somePage: any = {};

        let host = `${ctx.req?.headers?.["host"]}`;
        const xF = `${ctx.req?.headers?.["cxdomain"] || ctx.req?.headers?.["domain"] || ctx.req?.headers?.["X-Forwarded-For"]}`;
        const f = `${ctx.req?.headers?.["Forwarded"]}`;
        host =
            (xF && xF !== "" && xF !== "undefined" ? xF : undefined) ||
            (f && f !== "" && f !== "undefined" ? f : undefined) ||
            host;
        const ccmScriptProm = getCCMScript({ host });
        const dealer = await getIHBDealerFromHost({ host: host });
        const allowedIdsProm = getAllowedIds(dealer);
        const rootClient = await getRootClient({ ctx });
        const apolloClient = await getClient({
            ctx,
            projectId: dealer.caisyProjectId,
            host,
        });

        const companyInformationPrivate = apolloClient.query({
            query: QueryCompanyInformationPrivate,
        });

        const allPageBrandRootProm = fetchBrandPages({ companyInformationPrivate, rootClient, host, type: "new" });

        const settings = apolloClient.query({ query: QuerySettings });
        const pageContact = apolloClient.query({ query: QueryPageContact });
        const pageNews = apolloClient.query({ query: QueryPageNews });
        const pageGuestbook = apolloClient.query({ query: QueryPageGuestbook });
        const pageBrandOverview = apolloClient.query({
            query: QueryPageBrandOverview,
        });
        const pageDateInquiry = apolloClient.query({ query: QueryPageDateInquiry });
        const pageRepairInquiry = apolloClient.query({
            query: QueryPageRepairInquiry,
        });
        const pageImprint = rootClient.query({ query: QueryPageImprint });
        const qPrimaryNavigation = apolloClient.query({
            query: QueryPrimaryNavigation,
        });
        const companyInformationPublicData = apolloClient.query({
            query: QueryCompanyInformationPublic,
        });

        if ((await pageBrandOverview)?.data?.PageBrandOverview?.slug === slug) {
            somePage.PageBrandOverview = (await pageBrandOverview)?.data?.PageBrandOverview;
        } else if (slug === (await pageNews)?.data?.PageNews?.slug) {
            somePage.PageNews = (await pageNews)?.data.PageNews;
            const allowedIds = await allowedIdsProm;
            // console.log(` allowedIds`, allowedIds.filter((id) => id == `55ca7caa-2a96-491f-9cc5-26f730f54cb2`));
            const news = await getNews(allowedIds, apolloClient, rootClient);
            const newsCustom = await getNews(allowedIds, apolloClient, rootClient, true, true);

            somePage.PageNews.NewsArticles = news;
            somePage.PageNews.NewsArticlesCustom = newsCustom;
        } else if (slug === (await pageGuestbook)?.data?.PageGuestbook?.slug) {
            somePage.PageGuestbook = (await pageGuestbook)?.data?.PageGuestbook;
            const allGuestbookEntry = apolloClient.query({
                query: QueryAllGuestbookEntry,
            });
            somePage.AllGuestbookEntry = (await allGuestbookEntry)?.data?.allGuestbookEntry?.edges
                ?.map((e) => e?.node)
                .sort((a, b) => {
                    try {
                        return new Date(a.date) > new Date(b.date) ? -1 : 1;
                    } catch {
                        return -1;
                    }
                });
        } else {
            const pageBrandRoot = await allPageBrandRootProm;
            const pageBrandRootOld = [];
            const allowedIds = await allowedIdsProm;

            let distributedBrandPage = [...pageBrandRoot].find(
                (pb) =>
                    (allowedIds.indexOf(pb.id) !== -1 ||
                        dealer.caisyProjectId == "cc6c878f-2acf-41cf-b8de-d0140abc134e") &&
                    pb?.slug == slug
            );
            // if (!distributedBrandPage) {
            //     distributedBrandPage = [...pageBrandRootOld].find(
            //         (pb) =>
            //             (allowedIds.indexOf(pb.id) !== -1 ||
            //                 dealer.caisyProjectId == "cc6c878f-2acf-41cf-b8de-d0140abc134e") &&
            //             pb?.slug == slug
            //     );
            // }

            if (distributedBrandPage) {
                somePage.PageBrand = distributedBrandPage;
                // here we exclude gallery link items that are linking to pages that are not distributed
                if (somePage.PageBrand && somePage.PageBrand.elements) {
                    somePage.PageBrand.elements = somePage.PageBrand.elements.map((e) => {
                        if (e?.__typename == "ContinuingPageGalleryRoot") {
                            return {
                                ...e,
                                items:
                                    e?.items?.filter((i) => {
                                        if (i?.link?.[0] && `${i.link[0].__typename}`.includes("PageBrand")) {
                                            return (
                                                allowedIds.indexOf(i.link[0].id) !== -1 ||
                                                dealer.caisyProjectId == "cc6c878f-2acf-41cf-b8de-d0140abc134e"
                                            );
                                        }
                                        return true;
                                    }) ?? [],
                            };
                        } else if (e?.__typename == "BrandTopicTeaserElementComp") {
                            return {
                                ...e,
                                items:
                                    e?.items?.filter((i: any) => {
                                        if (i?.__typename == "BrandTopicTeaserItemComp" && i?.link?.id) {
                                            return (
                                                allowedIds.indexOf(i.link.id) !== -1 ||
                                                dealer.caisyProjectId == "cc6c878f-2acf-41cf-b8de-d0140abc134e"
                                            );
                                        }
                                        return true;
                                    }) ?? [],
                            };
                        } else {
                            return e;
                        }
                    });
                }
            } else {
                const [pageR, pageMicroR, pageCourseR] = await Promise.all([
                    await apolloClient.query({
                        query: QueryAllPage,
                        variables: { slug },
                    }),
                    await apolloClient.query({
                        query: QueryAllPageMicro,
                        variables: { slug },
                    }),
                    await apolloClient.query({
                        query: QueryAllPageCourse,
                        variables: { slug },
                    }),
                ]);
                somePage.Page = pageR?.data?.allPage?.edges?.[0]?.node;
                // fetch data for the subpage preview
                // not needed since in nav already
                // if(somePage.Page?.layout && ['subnavsmall', 'subnavbig'].includes(somePage.Page.layout)){
                //   const PrimaryNavigation: IPrimaryNavigation = (await qPrimaryNavigation).data
                //   ?.PrimaryNavigation;

                //   const subitemsSlugs = PrimaryNavigation?.items?.find(item => item?.link?.find(link => link?.slug === somePage.Page.slug))?.subitems?.map(s => s.slug);
                //   const subpages = await Promise.all(subitemsSlugs.map(slug => apolloClient.query({ query: QueryAllPage, variables: { slug } })));

                //   somePage.Page.subpages = subpages?.map(x => x?.data?.allPage?.edges?.[0]?.node);
                // }
                // generate slug for linked products in ContinuingPageGallery
                if (somePage.Page) {
                    somePage.Page.elements?.map((e, i) => {
                        if (e?.__typename === "ContinuingPageGallery") {
                            e.items?.map((galleryitem, j) => {
                                galleryitem?.link?.map((link, h) => {
                                    if (link?.title) {
                                        somePage.Page.elements[i].items[j].link[h].slug = slugify(link.title, {
                                            strict: true,
                                            lower: true,
                                        });
                                    }
                                });
                            });
                        }
                    });
                }
                // somePage.PageBrand = pageBrandR?.data?.allPageBrand?.edges?.[0]?.node;
                somePage.PageMicro = pageMicroR?.data?.allPageMicro?.edges?.[0]?.node;
                somePage.PageCourse = pageCourseR?.data?.allPageCourse?.edges?.[0]?.node;
            }
        }

        if (somePage.PageCourse && somePage.PageCourse.courses) {
            somePage.PageCourse.courses = await Promise.all(
                somePage.PageCourse.courses.map(async (c) => {
                    try {
                        const r = await fetch(
                            `${process.env.NEXT_PUBLIC_GEDK_API_ENDPOINT}/api//v1/ihb/course/count?document_id=${c.id}&project_id=${dealer.caisyProjectId}`
                        ).then((r) => r.json());
                        if (r?.courseSignupCount) {
                            return {
                                ...c,
                                maxParticipants:
                                    c.maxParticipants - r.courseSignupCount > 0
                                        ? c.maxParticipants - r.courseSignupCount
                                        : 0,
                            };
                        }
                    } catch (e) {
                        console.log(`course/count failed: `, e);
                    }

                    return { ...c };
                })
            );
        }
        const allowedIds = await allowedIdsProm;
        let pageBrandTopicPromise: any = null;
        if (somePage?.PageBrand) {
            if (slugs.length == 2 && somePage?.PageBrand?.slug == slugs[0] && slugs[1]) {
                const foundBrandTopic = somePage?.PageBrand?.subtopics?.find(
                    (n) =>
                        n?.slug == slugs[1] &&
                        (allowedIds?.includes(n?.id) ||
                            dealer?.caisyProjectId == "cc6c878f-2acf-41cf-b8de-d0140abc134e")
                );

                pageBrandTopicPromise = rootClient.query({
                    query: QueryAllPageBrandTopic,
                    variables: { slug: slugs[1] },
                });
                somePage.PageBrandTopic = foundBrandTopic;
            }
        }
        if (somePage?.PageBrandTopic || somePage?.PageBrand) {
            const resolvedOverviewPage = (await pageBrandOverview)?.data?.PageBrandOverview;

            if (resolvedOverviewPage) {
                somePage.PageBrandOverview = {
                    slug: resolvedOverviewPage.slug,
                    id: resolvedOverviewPage.id,
                    nameInNavigation: resolvedOverviewPage.nameInNavigation,
                    __typename: resolvedOverviewPage.__typename,
                };
            }
        }

        await pageContact;
        await pageBrandOverview;
        await pageGuestbook;
        await pageNews;
        await pageImprint;
        await pageDateInquiry;
        await pageRepairInquiry;

        somePage.PageContact = (await pageContact)?.data?.PageContact;
        somePage.PageImprint = (await pageImprint)?.data.PageImprint;
        somePage.PageDateInquiry = (await pageDateInquiry)?.data.PageDateInquiry;
        somePage.PageRepairInquiry = (await pageRepairInquiry)?.data.PageRepairInquiry;

        const footer = apolloClient.query({ query: QueryFooter });
        const allBrand = getAllBrand({ rootClient });
        const allBanner = apolloClient.query({ query: QueryAllBanner });
        const universalTextsRoot = rootClient.query({
            query: QueryUniversalTextsRoot,
        });
        const universalTexts = apolloClient.query({ query: QueryUniversalTexts });
        const allDeviceType = rootClient.query({ query: QueryAllDeviceType });
        const AllDeviceType = (await allDeviceType).data?.allDeviceType?.edges?.map((e) => e.node);
        const PrimaryNavigation = (await qPrimaryNavigation).data?.PrimaryNavigation;

        const allPageBrandRoot = await allPageBrandRootProm;
        const allPageBrandOldRoot = [];

        const uniquePageBrandRoot = getPageBrandUnique({
            pageBrandRoot: allPageBrandRoot,
            pageBrandRootOld: allPageBrandOldRoot,
            allowedIds,
        });

        // console.log(` allPageBrandRoot`, allPageBrandRoot.filter((p) => allowedIds.includes(p.id)).map((p) => `${p.nameInNavigation} ${p.slug} ${p.__typename}`));
        // console.log(` allPageBrandOldRoot`, allPageBrandOldRoot.filter((p) => allowedIds.includes(p.id)).map((p) => `${p.nameInNavigation} ${p.slug} ${p.__typename}`));
        // console.log(` uniquePageBrandRoot`, uniquePageBrandRoot.map((p) => `${p.nameInNavigation} ${p.slug} ${p.__typename}`));

        const allDistributedBrandPages = uniquePageBrandRoot.filter(
            (pb) =>
                (allowedIds.includes(pb.id) || dealer.caisyProjectId == "cc6c878f-2acf-41cf-b8de-d0140abc134e") &&
                !pb.hideInNavigationPlus &&
                !pb.hideInNavigation
        );

        const fixedCompanyInformationPrivate = fixCompanyInformationPrivate(await companyInformationPrivate);
        const line = fixedCompanyInformationPrivate?.data?.CompanyInformationPrivate?.line;
        const template = fixedCompanyInformationPrivate?.data?.CompanyInformationPrivate?.template;
        const Settings = applyDefaultColorsToSettings((await settings).data?.Settings, line, template);

        const nav = await getNav({
            settings: Settings,
            navigation: PrimaryNavigation,
            pageBrandRoot: uniquePageBrandRoot,
            allowedIds: allowedIds,
        });

        if ((Settings.template === "t3" || Settings.template === "t27") && somePage?.PageBrand?.elements) {
            somePage.PageBrand.elements.map((e, i) => {
                if (e?.__typename === "ContinuingPageGalleryRoot") {
                    somePage.PageBrand.elements[i].__typename = "ContinuingPageGallery";
                }
            });
        }

        if (Settings.template === "t27" && somePage.PageGuestbook) {
            somePage.Page = somePage.PageGuestbook as any;
            somePage.Page.__typename = "Page";
            delete somePage.PageGuestbook;
        }

        if (pageBrandTopicPromise) {
            const topicRes = await pageBrandTopicPromise;
            const r = topicRes?.data?.allPageBrandTopic?.edges?.[0]?.node ?? null;
            if (allowedIds.includes(r?.id) || dealer.caisyProjectId == "cc6c878f-2acf-41cf-b8de-d0140abc134e") {
                somePage.PageBrandTopic = r;
            }
        }

        // console.log(` dealer`, dealer);
        if (somePage.PageBrand && somePage.PageBrand.subtopics) {
            somePage.PageBrand.subtopics =
                somePage?.PageBrand?.subtopics?.filter(
                    (n) =>
                        allowedIds?.includes(n?.id) || dealer.caisyProjectId == "cc6c878f-2acf-41cf-b8de-d0140abc134e"
                ) ?? [];
        }
        if (somePage) {
            Object.keys(somePage).map((key) => {
                if (somePage[key]?.layout) {
                    somePage[key].layout = fixLayoutEnum(somePage[key].layout);
                }
            });
        }

        const props = {
            allRootBrandPages:
                somePage.PageBrand || somePage.PageBrandOverview || somePage.PageBrandTopic
                    ? allDistributedBrandPages.map((rootPage) => {
                          const page = {
                              slug: rootPage.slug,
                              id: rootPage.id,
                              hideInNavigationPlus: rootPage.hideInNavigationPlus || false,
                              nameInNavigation: rootPage.nameInNavigation,
                              brandDescription: rootPage.brandDescription,
                              brandLogo: rootPage.brandLogo,
                              __typename: rootPage.__typename,
                              __extTypename: rootPage.__extTypename,
                          };
                          return page;
                      })
                    : null,
            PrimaryNavigation: nav,
            CompanyInformationPrivate: fixCompanyInformationPrivate(await companyInformationPrivate)?.data
                ?.CompanyInformationPrivate,
            CompanyInformationPublic: (await companyInformationPublicData).data?.CompanyInformationPublic,
            Footer: await formatFooter({ footer, imprint: somePage.PageImprint }),
            Settings: Settings,
            AllBrand: getAllBrandsFilterd({
                allBrandsRoot: await allBrand,
                companyInformationPrivate: fixCompanyInformationPrivate((await companyInformationPrivate).data)
                    ?.CompanyInformationPrivate,
            }),
            AllBanner: getAllBannerFilterd({ allBanner: await allBanner }),
            UniversalTexts: {
                ...(await universalTextsRoot).data?.UniversalTexts,
                ...(await universalTexts).data?.UniversalTexts,
            },
            AllDeviceType,
            currentItemId: ctx?.query?.slug?.[1],
            slug,
            slugs: slugs,
            fullUrl,
            dealer,
            ccmScript: await ccmScriptProm,
            ...somePage,
        };

        // check for wrong slugs that are old links and redirect
        if (slugs.length >= 2) {
            let isNewsSubpage = false;
            let isBrandTopicPage =
                slugs.length == 2 && props?.PageBrand?.slug == slugs[0] && slugs[1] && somePage.PageBrandTopic;

            // console.log(` somePage.PageBrandTopic`, !!somePage.PageBrandTopic);
            // console.log(` props?.PageBrand?.slug`, props?.PageBrand?.slug);
            // console.log(` slugs`, slugs);
            // console.log(` isBrandTopicPage`, isBrandTopicPage);

            if (slugs.length == 2 && props?.PageNews?.slug == slugs[0] && slugs[1]) {
                const foundNews = props?.PageNews?.NewsArticles?.find((n) => n?.id == slugs[1]);
                const foundCustomNews = props?.PageNews?.NewsArticlesCustom?.find((n) => n?.id == slugs[1]);
                isNewsSubpage = foundNews || foundCustomNews;
            }
            if (!isNewsSubpage && !isBrandTopicPage) {
                console.log(` slug redirect to`, `/${props.slug}`, slugs);
                ctx.res.writeHead(301, { Location: `/${props.slug}` });
                ctx.res.end();

                return { props: {} };
            }
        }

        const is404 = () => {
            switch (`${props?.slug}`) {
                case `${props?.Page?.slug}`:
                case `${props?.PageMicro?.slug}`:
                case `${props?.PageCourse?.slug}`:
                case `${props?.PageBrandOverview?.slug}`:
                case `${props?.PageNews?.slug}`:
                case `${props?.PageGuestbook?.slug}`:
                case `${props?.PageDateInquiry?.slug}`:
                case `${props?.PageRepairInquiry?.slug}`:
                case `${props?.PageImprint?.slug}`:
                case `${props?.PageBrandTopic?.slug}`:
                case `${props?.PageBrand?.slug}`:
                case `${props?.PageContact?.slug}`:
                    return false;
                default:
                    return true;
            }
        };
        if (is404() && ctx?.res?.statusCode) {
            ctx.res.statusCode = 404;
        }

        removeUnf(props);
        return {
            props,
        };
    } catch (err) {
        console.error(` err`, err);
        if (ctx && ctx.res && ctx.res.statusCode) {
            ctx.res.statusCode = 500;
        }
        return {
            props: {
                error: `${(err as any).message}`,
            },
        } as any;
    }
};

export const getInitialPropsProduct = async (ctx): Promise<any> => {
    try {
        let host = `${ctx.req?.headers?.["host"]}`;
        const xF = `${ctx.req?.headers?.["cxdomain"] || ctx.req?.headers?.["domain"] || ctx.req?.headers?.["X-Forwarded-For"]}`;
        const f = `${ctx.req?.headers?.["Forwarded"]}`;
        host =
            (xF && xF !== "" && xF !== "undefined" ? xF : undefined) ||
            (f && f !== "" && f !== "undefined" ? f : undefined) ||
            host;

        const ccmScriptProm = getCCMScript({ host });

        const dealer = await getIHBDealerFromHost({ host: host });
        const rootClient = await getRootClient({ ctx });

        const allowedIdsProm = getAllowedIds(dealer);

        const apolloClient = await getClient({
            ctx,
            projectId: dealer.caisyProjectId,
            host,
        });

        const companyInformationPrivate = apolloClient.query({
            query: QueryCompanyInformationPrivate,
        });

        const allPageBrandRootProm = fetchBrandPages({ companyInformationPrivate, rootClient, host, type: "new" });

        const settings = apolloClient.query({ query: QuerySettings });
        const primaryNavigation = apolloClient.query({
            query: QueryPrimaryNavigation,
        });
        const companyInformationPublicData = apolloClient.query({
            query: QueryCompanyInformationPublic,
        });

        const footer = apolloClient.query({ query: QueryFooter });
        const universalTextsRoot = rootClient.query({
            query: QueryUniversalTextsRoot,
        });
        const universalTexts = apolloClient.query({ query: QueryUniversalTexts });
        const pageNews = apolloClient.query({ query: QueryPageNews });
        const pageDateInquiry = apolloClient.query({ query: QueryPageDateInquiry });
        const pageRepairInquiry = apolloClient.query({
            query: QueryPageRepairInquiry,
        });
        const allProduct = apolloClient.query({
            query: gql`
                ${QueryAllProduct}
            `,
        });
        const pageContact = apolloClient.query({ query: QueryPageContact });
        const pageImprint = rootClient.query({ query: QueryPageImprint });

        const allProductData = (await allProduct)?.data;
        const AllProduct: any[] = [];

        Object.keys(allProductData).map((key) => {
            allProductData[key]?.edges.map((e) => {
                const b = getIfNewsShouldBeDisplayByDate({
                    startDate: e?.node?.startDate,
                    endDate: e?.node?.endDate,
                });
                if (b && !!e?.node?.acceptAGB) {
                    if (!!e?.node?.EnergyEfficiencyClass) {
                        e.node.EnergyEfficiencyClass = e?.node?.EnergyEfficiencyClass.replace(/Plus/g, "+");

                        if (e.node.EnergyEfficiencyClass == "Für dieses Produkt ist kein Energielabel existent") {
                            e.node.EnergyEfficiencyClass = "Für dieses Produkt nicht existent";
                        }
                    }
                    AllProduct.push({
                        slug: slugify(e?.node?.title || "", { strict: true, lower: true }),
                        ...e?.node,
                    });
                }
            });
        });

        const allProductSorted = AllProduct.sort((a, b) => {
            const _a = a?._meta?.publishedAt ? new Date(a._meta.publishedAt).getTime() : 0;
            const _b = b?._meta?.publishedAt ? new Date(b._meta.publishedAt).getTime() : 0;
            return _b - _a;
        })
            .filter((it, i, self) => self.findIndex((a) => a.id === it.id) === i)
            .filter((e) =>
                getIfNewsShouldBeDisplayByDate({
                    startDate: e?.startDate,
                    endDate: e?.endDate,
                })
            );

        const pageBrandRoot = await allPageBrandRootProm;
        const pageBrandRootOld = [];

        const fixedCompanyInformationPrivate = fixCompanyInformationPrivate(await companyInformationPrivate);
        const line = fixedCompanyInformationPrivate?.data?.CompanyInformationPrivate?.line;
        const template = fixedCompanyInformationPrivate?.data?.CompanyInformationPrivate?.template;
        const Settings = applyDefaultColorsToSettings((await settings).data?.Settings, line, template);

        const nav = await getNav({
            settings: Settings,
            navigation: (await primaryNavigation).data?.PrimaryNavigation,
            pageBrandRoot: getPageBrandUnique({ pageBrandRoot, pageBrandRootOld, allowedIds: await allowedIdsProm }),
            allowedIds: await allowedIdsProm,
        });

        const res = {
            PrimaryNavigation: nav,
            CompanyInformationPrivate: fixCompanyInformationPrivate(await companyInformationPrivate)?.data
                ?.CompanyInformationPrivate,
            CompanyInformationPublic: (await companyInformationPublicData).data?.CompanyInformationPublic,
            PageImprint: (await pageImprint)?.data.PageImprint,
            Footer: await formatFooter({
                footer,
                imprint: (await pageImprint)?.data.PageImprint,
            }),
            UniversalTexts: {
                ...(await universalTextsRoot).data?.UniversalTexts,
                ...(await universalTexts).data?.UniversalTexts,
            },
            PageDateInquiry: (await pageDateInquiry)?.data.PageDateInquiry,
            PageRepairInquiry: (await pageRepairInquiry)?.data.PageRepairInquiry,
            NewsSlug: (await pageNews)?.data?.PageNews?.slug,
            AllProduct: allProductSorted,
            dealer,
            ccmScript: await ccmScriptProm,
            PageContact: (await pageContact)?.data?.PageContact,
            slug: slugify(ctx?.query?.product?.[0], { strict: true, lower: true }),
            Settings: Settings,
        };

        removeUnf(res);
        return {
            props: res,
        };
    } catch (err) {
        if (ctx && ctx.res && ctx.res.statusCode) {
            ctx.res.statusCode = 500;
        }

        return {
            props: {
                error: `${(err as any).message}`,
            },
        } as any;
    }
};

const DEFAULT_TEMPLATE = "t0";
export { DEFAULT_TEMPLATE };
export * from "./childTypes";
export * from "./helper";
