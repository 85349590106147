import gql from 'graphql-tag';
import Asset from './fragments/Asset.gql';
import TextElement from './fragments/TextElement.gql';
import MetaInformation from './fragments/MetaInformation.gql';

const PageImprint = gql`
${TextElement}
${Asset}
${MetaInformation}

query PageImprint {
  PageImprint {
    __typename
    slug
    nameInNavigation
    footnote
    extras {
      ...MetaInformation
    }
    elements {
      __typename
      ...Asset
      ...TextElement
    }
  }
}
`;

export default PageImprint;




