import gql from "graphql-tag";

const LandingEvent = gql`
  fragment LandingEvent on LandingEvent {
    id
    endDate
    headline
    icon {
      ...Asset
    }
    _meta {
      publishedAt
      updatedAt
    }
    startDate
    text
    linksTo {
      __typename
      ... on ExternalLink {
        externalLinkName
        externalLinkAddress
      }
      ... on PageNews {
        slug
        nameInNavigation
      }
      ... on PageImprint {
        slug
        nameInNavigation
      }
      ... on PageContact {
        slug
        nameInNavigation
      }
      ... on PageGuestbook {
        slug
        nameInNavigation
      }
      ... on Page {
        slug
        nameInNavigation
      }
      ... on PageBrand {
        slug
        nameInNavigation
      }
      ... on PageMicro {
        slug
        nameInNavigation
      }
      ... on PageDateInquiry {
        slug
        nameInNavigation
      }
      ... on PageRepairInquiry {
        slug
        nameInNavigation
      }
    }
    downloadLink {
      ...Asset
    }
  }
`;
export default LandingEvent;
