import gql from "graphql-tag";
import Asset from "./fragments/Asset.gql";

export default gql`
  ${Asset}
  query CompanyInformationPrivate {
    __typename
    CompanyInformationPrivate {
      showInKitchenCompass
      kitchensSalePartner
      householdApplianceMakerPartner
      line
      id
      customHtml
      brands
      template
      livePreview
      websiteDisabled
      furtherAwards {
        ...Asset
      }
      ccm
    }
  }
`;
