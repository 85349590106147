import gql from "graphql-tag";

const Banner = gql`
  fragment Banner on Banner {
    id
    text
    image
    asset{
      src
    }
    expireAt
    linksToPage {
      __typename
      ... on ExternalLink {
        externalLinkName
        externalLinkAddress
      }
      ... on PageNews {
        slug
        nameInNavigation
      }
      ... on PageContact {
        slug
        nameInNavigation
      }
      ... on PageMicro {
        slug
        nameInNavigation
      }
      ... on PageCourse {
        slug
        nameInNavigation
      }
      ... on PageGuestbook {
        slug
        nameInNavigation
      }
      ... on Page {
        slug
        nameInNavigation
      }
      ... on PageBrand {
        slug
        nameInNavigation
      }
      ... on PageRepairInquiry {
        slug
        nameInNavigation
      }
    }
    _meta {
      publishedAt
      updatedAt
    }
    __typename
  }
`;
export default Banner;
