import gql from "graphql-tag";

import Asset from "./Asset.gql";

const BlockImageGridComp = gql`
${Asset}
  fragment BlockImageGridComp on BlockImageGridComp {
    id
    images{ 
      ...Asset
    }
    __typename
  }
`;
export default BlockImageGridComp;
