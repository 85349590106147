import { IGenSettings_T11_ConnectionsOnLanding, IGenTextLinkItem } from "./types_dealer_gen";

export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: string;
    String: string;
    Boolean: boolean;
    Int: number;
    Float: number;
    /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
    DateTime: any;
    /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
    JSONObject: any;
    /** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
    Date: any;
    /** The `JSON` scalar type  */
    JSON: any;
};

export type IQuery = {
    __typename: "Query";
    DeviceType?: Maybe<IDeviceType>;
    allDeviceType?: Maybe<IDeviceType_Connection>;
    SortingNews?: Maybe<ISortingNews>;
    SubNavigationItem?: Maybe<ISubNavigationItem>;
    allSubNavigationItem?: Maybe<ISubNavigationItem_Connection>;
    PrimaryNavigation?: Maybe<IPrimaryNavigation>;
    ProductService?: Maybe<IProductService>;
    allProductService?: Maybe<IProductService_Connection>;
    Brand?: Maybe<IBrand>;
    allBrand?: Maybe<IBrand_Connection>;
    Footer?: Maybe<IFooter>;
    Article?: Maybe<IArticle>;
    allArticle?: Maybe<IArticle_Connection>;
    ContinuingPageGalleryItemRoot?: Maybe<IContinuingPageGalleryItemRoot>;
    allContinuingPageGalleryItemRoot?: Maybe<IContinuingPageGalleryItemRoot_Connection>;
    Banner?: Maybe<IBanner>;
    allBanner?: Maybe<IBanner_Connection>;
    ContinuingPageGalleryItem?: Maybe<IContinuingPageGalleryItem>;
    allContinuingPageGalleryItem?: Maybe<IContinuingPageGalleryItem_Connection>;
    Settings?: Maybe<ISettings>;
    NavigationItem?: Maybe<INavigationItem>;
    allNavigationItem?: Maybe<INavigationItem_Connection>;
    News?: Maybe<INews>;
    allNews?: Maybe<INews_Connection>;
    CompanyInformationPrivate?: Maybe<ICompanyInformationPrivate>;
    LandingEvent?: Maybe<ILandingEvent>;
    allLandingEvent?: Maybe<ILandingEvent_Connection>;
    PageImprint?: Maybe<IPageImprint>;
    PageRepairInquiry?: Maybe<IPageRepairInquiry>;
    GuestbookEntry?: Maybe<IGuestbookEntry>;
    allGuestbookEntry?: Maybe<IGuestbookEntry_Connection>;
    PageLanding?: Maybe<IPageLanding>;
    Page?: Maybe<IPage>;
    allPage?: Maybe<IPage_Connection>;
    PageDateInquiry?: Maybe<IPageDateInquiry>;
    PageBrandOverview?: Maybe<IPageBrandOverview>;
    PageNews?: Maybe<IPageNews>;
    PageGuestbook?: Maybe<IPageGuestbook>;
    PageBrand?: Maybe<IPageBrand>;
    allPageBrand?: Maybe<IPageBrand_Connection>;
    PageContact?: Maybe<IPageContact>;
    Asset?: Maybe<IAsset>;
    allAsset?: Maybe<IAsset_Connection>;
    ProductAService?: Maybe<IProductAService>;
    allProductAService?: Maybe<IProductAService_Connection>;
    ProductKitchenFurniture?: Maybe<IProductKitchenFurniture>;
    allProductKitchenFurniture?: Maybe<IProductKitchenFurniture_Connection>;
    ProductKitchen?: Maybe<IProductKitchen>;
    allProductKitchen?: Maybe<IProductKitchen_Connection>;
    Product?: Maybe<IProduct>;
    allProduct?: Maybe<IProduct_Connection>;
    ProductVacuumDrawer?: Maybe<IProductVacuumDrawer>;
    allProductVacuumDrawer?: Maybe<IProductVacuumDrawer_Connection>;
    ProductWarmingDrawer?: Maybe<IProductWarmingDrawer>;
    allProductWarmingDrawer?: Maybe<IProductWarmingDrawer_Connection>;
    ProductSinkAndTap?: Maybe<IProductSinkAndTap>;
    allProductSinkAndTap?: Maybe<IProductSinkAndTap_Connection>;
    ProductBathroomFurniture?: Maybe<IProductBathroomFurniture>;
    allProductBathroomFurniture?: Maybe<IProductBathroomFurniture_Connection>;
    ProductAccessoriesAndSupplies?: Maybe<IProductAccessoriesAndSupplies>;
    allProductAccessoriesAndSupplies?: Maybe<IProductAccessoriesAndSupplies_Connection>;
    ProductSparePart?: Maybe<IProductSparePart>;
    allProductSparePart?: Maybe<IProductSparePart_Connection>;
    ProductWorktop?: Maybe<IProductWorktop>;
    allProductWorktop?: Maybe<IProductWorktop_Connection>;
    CompanyInformationPublic?: Maybe<ICompanyInformationPublic>;
    ProductConsumerElectronics?: Maybe<IProductConsumerElectronics>;
    allProductConsumerElectronics?: Maybe<IProductConsumerElectronics_Connection>;
    UniversalTexts?: Maybe<IUniversalTexts>;
    ProductSmallDevice?: Maybe<IProductSmallDevice>;
    allProductSmallDevice?: Maybe<IProductSmallDevice_Connection>;
    ProductLighting?: Maybe<IProductLighting>;
    allProductLighting?: Maybe<IProductLighting_Connection>;
    ProductCooktop?: Maybe<IProductCooktop>;
    allProductCooktop?: Maybe<IProductCooktop_Connection>;
    ProductCoffeeMachine?: Maybe<IProductCoffeeMachine>;
    allProductCoffeeMachine?: Maybe<IProductCoffeeMachine_Connection>;
    ProductMicrowaveAndCooker?: Maybe<IProductMicrowaveAndCooker>;
    allProductMicrowaveAndCooker?: Maybe<IProductMicrowaveAndCooker_Connection>;
    ProductDryer?: Maybe<IProductDryer>;
    allProductDryer?: Maybe<IProductDryer_Connection>;
    ProductExtractorHood?: Maybe<IProductExtractorHood>;
    allProductExtractorHood?: Maybe<IProductExtractorHood_Connection>;
    ProductDishwasher?: Maybe<IProductDishwasher>;
    allProductDishwasher?: Maybe<IProductDishwasher_Connection>;
    ProductWashingMachine?: Maybe<IProductWashingMachine>;
    allProductWashingMachine?: Maybe<IProductWashingMachine_Connection>;
    ProductStoveAndOven?: Maybe<IProductStoveAndOven>;
    allProductStoveAndOven?: Maybe<IProductStoveAndOven_Connection>;
    ProductRefrigeratorAndFreezer?: Maybe<IProductRefrigeratorAndFreezer>;
    allProductRefrigeratorAndFreezer?: Maybe<IProductRefrigeratorAndFreezer_Connection>;
    Caisy_Document?: Maybe<ICaisy_Document>;
};

export type IQueryDeviceTypeArgs = {
    id: Scalars["ID"];
    locale?: Maybe<Scalars["String"]>;
};

export type IQueryAllDeviceTypeArgs = {
    locale?: Maybe<Scalars["String"]>;
    first?: Maybe<Scalars["Int"]>;
    skip?: Maybe<Scalars["Int"]>;
    before?: Maybe<Scalars["String"]>;
    after?: Maybe<Scalars["String"]>;
    sort?: Maybe<Array<Maybe<IDeviceType_Sort>>>;
    where?: Maybe<Array<Maybe<IDeviceType_Where>>>;
};

export type IQuerySortingNewsArgs = {
    locale?: Maybe<Scalars["String"]>;
};

export type IQuerySubNavigationItemArgs = {
    id: Scalars["ID"];
    locale?: Maybe<Scalars["String"]>;
};

export type IQueryAllSubNavigationItemArgs = {
    locale?: Maybe<Scalars["String"]>;
    first?: Maybe<Scalars["Int"]>;
    skip?: Maybe<Scalars["Int"]>;
    before?: Maybe<Scalars["String"]>;
    after?: Maybe<Scalars["String"]>;
    sort?: Maybe<Array<Maybe<ISubNavigationItem_Sort>>>;
    where?: Maybe<Array<Maybe<ISubNavigationItem_Where>>>;
};

export type IQueryPrimaryNavigationArgs = {
    locale?: Maybe<Scalars["String"]>;
};

export type IQueryProductServiceArgs = {
    id: Scalars["ID"];
    locale?: Maybe<Scalars["String"]>;
};

export type IQueryAllProductServiceArgs = {
    locale?: Maybe<Scalars["String"]>;
    first?: Maybe<Scalars["Int"]>;
    skip?: Maybe<Scalars["Int"]>;
    before?: Maybe<Scalars["String"]>;
    after?: Maybe<Scalars["String"]>;
    sort?: Maybe<Array<Maybe<IProductService_Sort>>>;
    where?: Maybe<Array<Maybe<IProductService_Where>>>;
};

export type IQueryBrandArgs = {
    id: Scalars["ID"];
    locale?: Maybe<Scalars["String"]>;
};

export type IQueryAllBrandArgs = {
    locale?: Maybe<Scalars["String"]>;
    first?: Maybe<Scalars["Int"]>;
    skip?: Maybe<Scalars["Int"]>;
    before?: Maybe<Scalars["String"]>;
    after?: Maybe<Scalars["String"]>;
    sort?: Maybe<Array<Maybe<IBrand_Sort>>>;
    where?: Maybe<Array<Maybe<IBrand_Where>>>;
};

export type IQueryFooterArgs = {
    locale?: Maybe<Scalars["String"]>;
};

export type IQueryArticleArgs = {
    id: Scalars["ID"];
    locale?: Maybe<Scalars["String"]>;
};

export type IQueryAllArticleArgs = {
    locale?: Maybe<Scalars["String"]>;
    first?: Maybe<Scalars["Int"]>;
    skip?: Maybe<Scalars["Int"]>;
    before?: Maybe<Scalars["String"]>;
    after?: Maybe<Scalars["String"]>;
    sort?: Maybe<Array<Maybe<IArticle_Sort>>>;
    where?: Maybe<Array<Maybe<IArticle_Where>>>;
};

export type IQueryContinuingPageGalleryItemRootArgs = {
    id: Scalars["ID"];
    locale?: Maybe<Scalars["String"]>;
};

export type IQueryAllContinuingPageGalleryItemRootArgs = {
    locale?: Maybe<Scalars["String"]>;
    first?: Maybe<Scalars["Int"]>;
    skip?: Maybe<Scalars["Int"]>;
    before?: Maybe<Scalars["String"]>;
    after?: Maybe<Scalars["String"]>;
    sort?: Maybe<Array<Maybe<IContinuingPageGalleryItemRoot_Sort>>>;
    where?: Maybe<Array<Maybe<IContinuingPageGalleryItemRoot_Where>>>;
};

export type IQueryBannerArgs = {
    id: Scalars["ID"];
    locale?: Maybe<Scalars["String"]>;
};

export type IQueryAllBannerArgs = {
    locale?: Maybe<Scalars["String"]>;
    first?: Maybe<Scalars["Int"]>;
    skip?: Maybe<Scalars["Int"]>;
    before?: Maybe<Scalars["String"]>;
    after?: Maybe<Scalars["String"]>;
    sort?: Maybe<Array<Maybe<IBanner_Sort>>>;
    where?: Maybe<Array<Maybe<IBanner_Where>>>;
};

export type IQueryContinuingPageGalleryItemArgs = {
    id: Scalars["ID"];
    locale?: Maybe<Scalars["String"]>;
};

export type IQueryAllContinuingPageGalleryItemArgs = {
    locale?: Maybe<Scalars["String"]>;
    first?: Maybe<Scalars["Int"]>;
    skip?: Maybe<Scalars["Int"]>;
    before?: Maybe<Scalars["String"]>;
    after?: Maybe<Scalars["String"]>;
    sort?: Maybe<Array<Maybe<IContinuingPageGalleryItem_Sort>>>;
    where?: Maybe<Array<Maybe<IContinuingPageGalleryItem_Where>>>;
};

export type IQuerySettingsArgs = {
    locale?: Maybe<Scalars["String"]>;
};

export type IQueryNavigationItemArgs = {
    id: Scalars["ID"];
    locale?: Maybe<Scalars["String"]>;
};

export type IQueryAllNavigationItemArgs = {
    locale?: Maybe<Scalars["String"]>;
    first?: Maybe<Scalars["Int"]>;
    skip?: Maybe<Scalars["Int"]>;
    before?: Maybe<Scalars["String"]>;
    after?: Maybe<Scalars["String"]>;
    sort?: Maybe<Array<Maybe<INavigationItem_Sort>>>;
    where?: Maybe<Array<Maybe<INavigationItem_Where>>>;
};

export type IQueryNewsArgs = {
    id: Scalars["ID"];
    locale?: Maybe<Scalars["String"]>;
};

export type IQueryAllNewsArgs = {
    locale?: Maybe<Scalars["String"]>;
    first?: Maybe<Scalars["Int"]>;
    skip?: Maybe<Scalars["Int"]>;
    before?: Maybe<Scalars["String"]>;
    after?: Maybe<Scalars["String"]>;
    sort?: Maybe<Array<Maybe<INews_Sort>>>;
    where?: Maybe<Array<Maybe<INews_Where>>>;
};

export type IQueryCompanyInformationPrivateArgs = {
    locale?: Maybe<Scalars["String"]>;
};

export type IQueryLandingEventArgs = {
    id: Scalars["ID"];
    locale?: Maybe<Scalars["String"]>;
};

export type IQueryAllLandingEventArgs = {
    locale?: Maybe<Scalars["String"]>;
    first?: Maybe<Scalars["Int"]>;
    skip?: Maybe<Scalars["Int"]>;
    before?: Maybe<Scalars["String"]>;
    after?: Maybe<Scalars["String"]>;
    sort?: Maybe<Array<Maybe<ILandingEvent_Sort>>>;
    where?: Maybe<Array<Maybe<ILandingEvent_Where>>>;
};

export type IQueryPageImprintArgs = {
    locale?: Maybe<Scalars["String"]>;
};

export type IQueryPageRepairInquiryArgs = {
    locale?: Maybe<Scalars["String"]>;
};

export type IQueryGuestbookEntryArgs = {
    id: Scalars["ID"];
    locale?: Maybe<Scalars["String"]>;
};

export type IQueryAllGuestbookEntryArgs = {
    locale?: Maybe<Scalars["String"]>;
    first?: Maybe<Scalars["Int"]>;
    skip?: Maybe<Scalars["Int"]>;
    before?: Maybe<Scalars["String"]>;
    after?: Maybe<Scalars["String"]>;
    sort?: Maybe<Array<Maybe<IGuestbookEntry_Sort>>>;
    where?: Maybe<Array<Maybe<IGuestbookEntry_Where>>>;
};

export type IQueryPageLandingArgs = {
    locale?: Maybe<Scalars["String"]>;
};

export type IQueryPageArgs = {
    id: Scalars["ID"];
    locale?: Maybe<Scalars["String"]>;
};

export type IQueryAllPageArgs = {
    locale?: Maybe<Scalars["String"]>;
    first?: Maybe<Scalars["Int"]>;
    skip?: Maybe<Scalars["Int"]>;
    before?: Maybe<Scalars["String"]>;
    after?: Maybe<Scalars["String"]>;
    sort?: Maybe<Array<Maybe<IPage_Sort>>>;
    where?: Maybe<Array<Maybe<IPage_Where>>>;
};

export type IQueryPageDateInquiryArgs = {
    locale?: Maybe<Scalars["String"]>;
};

export type IQueryPageBrandOverviewArgs = {
    locale?: Maybe<Scalars["String"]>;
};

export type IQueryPageNewsArgs = {
    locale?: Maybe<Scalars["String"]>;
};

export type IQueryPageGuestbookArgs = {
    locale?: Maybe<Scalars["String"]>;
};

export type IQueryPageBrandArgs = {
    id: Scalars["ID"];
    locale?: Maybe<Scalars["String"]>;
};

export type IQueryAllPageBrandArgs = {
    locale?: Maybe<Scalars["String"]>;
    first?: Maybe<Scalars["Int"]>;
    skip?: Maybe<Scalars["Int"]>;
    before?: Maybe<Scalars["String"]>;
    after?: Maybe<Scalars["String"]>;
    sort?: Maybe<Array<Maybe<IPageBrand_Sort>>>;
    where?: Maybe<Array<Maybe<IPageBrand_Where>>>;
};

export type IQueryPageContactArgs = {
    locale?: Maybe<Scalars["String"]>;
};

export type IQueryAssetArgs = {
    id: Scalars["ID"];
    locale?: Maybe<Scalars["String"]>;
};

export type IQueryAllAssetArgs = {
    locale?: Maybe<Scalars["String"]>;
    first?: Maybe<Scalars["Int"]>;
    skip?: Maybe<Scalars["Int"]>;
    before?: Maybe<Scalars["String"]>;
    after?: Maybe<Scalars["String"]>;
    sort?: Maybe<Array<Maybe<IAsset_Sort>>>;
    where?: Maybe<Array<Maybe<IAsset_Where>>>;
};

export type IQueryProductAServiceArgs = {
    id: Scalars["ID"];
    locale?: Maybe<Scalars["String"]>;
};

export type IQueryAllProductAServiceArgs = {
    locale?: Maybe<Scalars["String"]>;
    first?: Maybe<Scalars["Int"]>;
    skip?: Maybe<Scalars["Int"]>;
    before?: Maybe<Scalars["String"]>;
    after?: Maybe<Scalars["String"]>;
    sort?: Maybe<Array<Maybe<IProductAService_Sort>>>;
    where?: Maybe<Array<Maybe<IProductAService_Where>>>;
};

export type IQueryProductKitchenFurnitureArgs = {
    id: Scalars["ID"];
    locale?: Maybe<Scalars["String"]>;
};

export type IQueryAllProductKitchenFurnitureArgs = {
    locale?: Maybe<Scalars["String"]>;
    first?: Maybe<Scalars["Int"]>;
    skip?: Maybe<Scalars["Int"]>;
    before?: Maybe<Scalars["String"]>;
    after?: Maybe<Scalars["String"]>;
    sort?: Maybe<Array<Maybe<IProductKitchenFurniture_Sort>>>;
    where?: Maybe<Array<Maybe<IProductKitchenFurniture_Where>>>;
};

export type IQueryProductKitchenArgs = {
    id: Scalars["ID"];
    locale?: Maybe<Scalars["String"]>;
};

export type IQueryAllProductKitchenArgs = {
    locale?: Maybe<Scalars["String"]>;
    first?: Maybe<Scalars["Int"]>;
    skip?: Maybe<Scalars["Int"]>;
    before?: Maybe<Scalars["String"]>;
    after?: Maybe<Scalars["String"]>;
    sort?: Maybe<Array<Maybe<IProductKitchen_Sort>>>;
    where?: Maybe<Array<Maybe<IProductKitchen_Where>>>;
};

export type IQueryProductArgs = {
    id: Scalars["ID"];
    locale?: Maybe<Scalars["String"]>;
};

export type IQueryAllProductArgs = {
    locale?: Maybe<Scalars["String"]>;
    first?: Maybe<Scalars["Int"]>;
    skip?: Maybe<Scalars["Int"]>;
    before?: Maybe<Scalars["String"]>;
    after?: Maybe<Scalars["String"]>;
    sort?: Maybe<Array<Maybe<IProduct_Sort>>>;
    where?: Maybe<Array<Maybe<IProduct_Where>>>;
};

export type IQueryProductVacuumDrawerArgs = {
    id: Scalars["ID"];
    locale?: Maybe<Scalars["String"]>;
};

export type IQueryAllProductVacuumDrawerArgs = {
    locale?: Maybe<Scalars["String"]>;
    first?: Maybe<Scalars["Int"]>;
    skip?: Maybe<Scalars["Int"]>;
    before?: Maybe<Scalars["String"]>;
    after?: Maybe<Scalars["String"]>;
    sort?: Maybe<Array<Maybe<IProductVacuumDrawer_Sort>>>;
    where?: Maybe<Array<Maybe<IProductVacuumDrawer_Where>>>;
};

export type IQueryProductWarmingDrawerArgs = {
    id: Scalars["ID"];
    locale?: Maybe<Scalars["String"]>;
};

export type IQueryAllProductWarmingDrawerArgs = {
    locale?: Maybe<Scalars["String"]>;
    first?: Maybe<Scalars["Int"]>;
    skip?: Maybe<Scalars["Int"]>;
    before?: Maybe<Scalars["String"]>;
    after?: Maybe<Scalars["String"]>;
    sort?: Maybe<Array<Maybe<IProductWarmingDrawer_Sort>>>;
    where?: Maybe<Array<Maybe<IProductWarmingDrawer_Where>>>;
};

export type IQueryProductSinkAndTapArgs = {
    id: Scalars["ID"];
    locale?: Maybe<Scalars["String"]>;
};

export type IQueryAllProductSinkAndTapArgs = {
    locale?: Maybe<Scalars["String"]>;
    first?: Maybe<Scalars["Int"]>;
    skip?: Maybe<Scalars["Int"]>;
    before?: Maybe<Scalars["String"]>;
    after?: Maybe<Scalars["String"]>;
    sort?: Maybe<Array<Maybe<IProductSinkAndTap_Sort>>>;
    where?: Maybe<Array<Maybe<IProductSinkAndTap_Where>>>;
};

export type IQueryProductBathroomFurnitureArgs = {
    id: Scalars["ID"];
    locale?: Maybe<Scalars["String"]>;
};

export type IQueryAllProductBathroomFurnitureArgs = {
    locale?: Maybe<Scalars["String"]>;
    first?: Maybe<Scalars["Int"]>;
    skip?: Maybe<Scalars["Int"]>;
    before?: Maybe<Scalars["String"]>;
    after?: Maybe<Scalars["String"]>;
    sort?: Maybe<Array<Maybe<IProductBathroomFurniture_Sort>>>;
    where?: Maybe<Array<Maybe<IProductBathroomFurniture_Where>>>;
};

export type IQueryProductAccessoriesAndSuppliesArgs = {
    id: Scalars["ID"];
    locale?: Maybe<Scalars["String"]>;
};

export type IQueryAllProductAccessoriesAndSuppliesArgs = {
    locale?: Maybe<Scalars["String"]>;
    first?: Maybe<Scalars["Int"]>;
    skip?: Maybe<Scalars["Int"]>;
    before?: Maybe<Scalars["String"]>;
    after?: Maybe<Scalars["String"]>;
    sort?: Maybe<Array<Maybe<IProductAccessoriesAndSupplies_Sort>>>;
    where?: Maybe<Array<Maybe<IProductAccessoriesAndSupplies_Where>>>;
};

export type IQueryProductSparePartArgs = {
    id: Scalars["ID"];
    locale?: Maybe<Scalars["String"]>;
};

export type IQueryAllProductSparePartArgs = {
    locale?: Maybe<Scalars["String"]>;
    first?: Maybe<Scalars["Int"]>;
    skip?: Maybe<Scalars["Int"]>;
    before?: Maybe<Scalars["String"]>;
    after?: Maybe<Scalars["String"]>;
    sort?: Maybe<Array<Maybe<IProductSparePart_Sort>>>;
    where?: Maybe<Array<Maybe<IProductSparePart_Where>>>;
};

export type IQueryProductWorktopArgs = {
    id: Scalars["ID"];
    locale?: Maybe<Scalars["String"]>;
};

export type IQueryAllProductWorktopArgs = {
    locale?: Maybe<Scalars["String"]>;
    first?: Maybe<Scalars["Int"]>;
    skip?: Maybe<Scalars["Int"]>;
    before?: Maybe<Scalars["String"]>;
    after?: Maybe<Scalars["String"]>;
    sort?: Maybe<Array<Maybe<IProductWorktop_Sort>>>;
    where?: Maybe<Array<Maybe<IProductWorktop_Where>>>;
};

export type IQueryCompanyInformationPublicArgs = {
    locale?: Maybe<Scalars["String"]>;
};

export type IQueryProductConsumerElectronicsArgs = {
    id: Scalars["ID"];
    locale?: Maybe<Scalars["String"]>;
};

export type IQueryAllProductConsumerElectronicsArgs = {
    locale?: Maybe<Scalars["String"]>;
    first?: Maybe<Scalars["Int"]>;
    skip?: Maybe<Scalars["Int"]>;
    before?: Maybe<Scalars["String"]>;
    after?: Maybe<Scalars["String"]>;
    sort?: Maybe<Array<Maybe<IProductConsumerElectronics_Sort>>>;
    where?: Maybe<Array<Maybe<IProductConsumerElectronics_Where>>>;
};

export type IQueryUniversalTextsArgs = {
    locale?: Maybe<Scalars["String"]>;
};

export type IQueryProductSmallDeviceArgs = {
    id: Scalars["ID"];
    locale?: Maybe<Scalars["String"]>;
};

export type IQueryAllProductSmallDeviceArgs = {
    locale?: Maybe<Scalars["String"]>;
    first?: Maybe<Scalars["Int"]>;
    skip?: Maybe<Scalars["Int"]>;
    before?: Maybe<Scalars["String"]>;
    after?: Maybe<Scalars["String"]>;
    sort?: Maybe<Array<Maybe<IProductSmallDevice_Sort>>>;
    where?: Maybe<Array<Maybe<IProductSmallDevice_Where>>>;
};

export type IQueryProductLightingArgs = {
    id: Scalars["ID"];
    locale?: Maybe<Scalars["String"]>;
};

export type IQueryAllProductLightingArgs = {
    locale?: Maybe<Scalars["String"]>;
    first?: Maybe<Scalars["Int"]>;
    skip?: Maybe<Scalars["Int"]>;
    before?: Maybe<Scalars["String"]>;
    after?: Maybe<Scalars["String"]>;
    sort?: Maybe<Array<Maybe<IProductLighting_Sort>>>;
    where?: Maybe<Array<Maybe<IProductLighting_Where>>>;
};

export type IQueryProductCooktopArgs = {
    id: Scalars["ID"];
    locale?: Maybe<Scalars["String"]>;
};

export type IQueryAllProductCooktopArgs = {
    locale?: Maybe<Scalars["String"]>;
    first?: Maybe<Scalars["Int"]>;
    skip?: Maybe<Scalars["Int"]>;
    before?: Maybe<Scalars["String"]>;
    after?: Maybe<Scalars["String"]>;
    sort?: Maybe<Array<Maybe<IProductCooktop_Sort>>>;
    where?: Maybe<Array<Maybe<IProductCooktop_Where>>>;
};

export type IQueryProductCoffeeMachineArgs = {
    id: Scalars["ID"];
    locale?: Maybe<Scalars["String"]>;
};

export type IQueryAllProductCoffeeMachineArgs = {
    locale?: Maybe<Scalars["String"]>;
    first?: Maybe<Scalars["Int"]>;
    skip?: Maybe<Scalars["Int"]>;
    before?: Maybe<Scalars["String"]>;
    after?: Maybe<Scalars["String"]>;
    sort?: Maybe<Array<Maybe<IProductCoffeeMachine_Sort>>>;
    where?: Maybe<Array<Maybe<IProductCoffeeMachine_Where>>>;
};

export type IQueryProductMicrowaveAndCookerArgs = {
    id: Scalars["ID"];
    locale?: Maybe<Scalars["String"]>;
};

export type IQueryAllProductMicrowaveAndCookerArgs = {
    locale?: Maybe<Scalars["String"]>;
    first?: Maybe<Scalars["Int"]>;
    skip?: Maybe<Scalars["Int"]>;
    before?: Maybe<Scalars["String"]>;
    after?: Maybe<Scalars["String"]>;
    sort?: Maybe<Array<Maybe<IProductMicrowaveAndCooker_Sort>>>;
    where?: Maybe<Array<Maybe<IProductMicrowaveAndCooker_Where>>>;
};

export type IQueryProductDryerArgs = {
    id: Scalars["ID"];
    locale?: Maybe<Scalars["String"]>;
};

export type IQueryAllProductDryerArgs = {
    locale?: Maybe<Scalars["String"]>;
    first?: Maybe<Scalars["Int"]>;
    skip?: Maybe<Scalars["Int"]>;
    before?: Maybe<Scalars["String"]>;
    after?: Maybe<Scalars["String"]>;
    sort?: Maybe<Array<Maybe<IProductDryer_Sort>>>;
    where?: Maybe<Array<Maybe<IProductDryer_Where>>>;
};

export type IQueryProductExtractorHoodArgs = {
    id: Scalars["ID"];
    locale?: Maybe<Scalars["String"]>;
};

export type IQueryAllProductExtractorHoodArgs = {
    locale?: Maybe<Scalars["String"]>;
    first?: Maybe<Scalars["Int"]>;
    skip?: Maybe<Scalars["Int"]>;
    before?: Maybe<Scalars["String"]>;
    after?: Maybe<Scalars["String"]>;
    sort?: Maybe<Array<Maybe<IProductExtractorHood_Sort>>>;
    where?: Maybe<Array<Maybe<IProductExtractorHood_Where>>>;
};

export type IQueryProductDishwasherArgs = {
    id: Scalars["ID"];
    locale?: Maybe<Scalars["String"]>;
};

export type IQueryAllProductDishwasherArgs = {
    locale?: Maybe<Scalars["String"]>;
    first?: Maybe<Scalars["Int"]>;
    skip?: Maybe<Scalars["Int"]>;
    before?: Maybe<Scalars["String"]>;
    after?: Maybe<Scalars["String"]>;
    sort?: Maybe<Array<Maybe<IProductDishwasher_Sort>>>;
    where?: Maybe<Array<Maybe<IProductDishwasher_Where>>>;
};

export type IQueryProductWashingMachineArgs = {
    id: Scalars["ID"];
    locale?: Maybe<Scalars["String"]>;
};

export type IQueryAllProductWashingMachineArgs = {
    locale?: Maybe<Scalars["String"]>;
    first?: Maybe<Scalars["Int"]>;
    skip?: Maybe<Scalars["Int"]>;
    before?: Maybe<Scalars["String"]>;
    after?: Maybe<Scalars["String"]>;
    sort?: Maybe<Array<Maybe<IProductWashingMachine_Sort>>>;
    where?: Maybe<Array<Maybe<IProductWashingMachine_Where>>>;
};

export type IQueryProductStoveAndOvenArgs = {
    id: Scalars["ID"];
    locale?: Maybe<Scalars["String"]>;
};

export type IQueryAllProductStoveAndOvenArgs = {
    locale?: Maybe<Scalars["String"]>;
    first?: Maybe<Scalars["Int"]>;
    skip?: Maybe<Scalars["Int"]>;
    before?: Maybe<Scalars["String"]>;
    after?: Maybe<Scalars["String"]>;
    sort?: Maybe<Array<Maybe<IProductStoveAndOven_Sort>>>;
    where?: Maybe<Array<Maybe<IProductStoveAndOven_Where>>>;
};

export type IQueryProductRefrigeratorAndFreezerArgs = {
    id: Scalars["ID"];
    locale?: Maybe<Scalars["String"]>;
};

export type IQueryAllProductRefrigeratorAndFreezerArgs = {
    locale?: Maybe<Scalars["String"]>;
    first?: Maybe<Scalars["Int"]>;
    skip?: Maybe<Scalars["Int"]>;
    before?: Maybe<Scalars["String"]>;
    after?: Maybe<Scalars["String"]>;
    sort?: Maybe<Array<Maybe<IProductRefrigeratorAndFreezer_Sort>>>;
    where?: Maybe<Array<Maybe<IProductRefrigeratorAndFreezer_Where>>>;
};

export type IQueryCaisy_DocumentArgs = {
    id?: Maybe<Scalars["String"]>;
    locale?: Maybe<Scalars["String"]>;
};

export type IDeviceType = {
    __typename: "DeviceType";
    id?: Maybe<Scalars["ID"]>;
    name?: Maybe<Scalars["String"]>;
    _meta?: Maybe<ICaisyDocument_Meta>;
};

export type ICaisyDocument_Meta = {
    __typename: "CaisyDocument_Meta";
    locale?: Maybe<Scalars["String"]>;
    locales?: Maybe<Array<Maybe<Scalars["String"]>>>;
    publishedAt?: Maybe<Scalars["DateTime"]>;
    updatedAt?: Maybe<Scalars["DateTime"]>;
    id?: Maybe<Scalars["ID"]>;
};

export type IDeviceType_Connection = {
    __typename: "DeviceType_Connection";
    edges?: Maybe<Array<Maybe<IDeviceType_ConnectionEdge>>>;
    pageInfo?: Maybe<IPageInfo>;
    totalCount?: Maybe<Scalars["Int"]>;
};

export type IDeviceType_ConnectionEdge = {
    __typename: "DeviceType_ConnectionEdge";
    node?: Maybe<IDeviceType>;
    cursor?: Maybe<Scalars["String"]>;
};

export type IPageInfo = {
    __typename: "PageInfo";
    hasNextPage?: Maybe<Scalars["Boolean"]>;
    hasPreviousPage?: Maybe<Scalars["Boolean"]>;
    startCursor?: Maybe<Scalars["String"]>;
    endCursor?: Maybe<Scalars["String"]>;
};

export type IDeviceType_Sort = {
    name?: Maybe<IOrder>;
};

export enum IOrder {
    Asc = "ASC",
    Desc = "DESC",
}

export type IDeviceType_Where = {
    name?: Maybe<ICaisyField_String_Where>;
    OR?: Maybe<Array<Maybe<IDeviceType_Where>>>;
    AND?: Maybe<Array<Maybe<IDeviceType_Where>>>;
};

export type ICaisyField_String_Where = {
    neq?: Maybe<Scalars["String"]>;
    eq?: Maybe<Scalars["String"]>;
    contains?: Maybe<Scalars["String"]>;
};

export type ISortingNews = {
    __typename: "SortingNews";
    id?: Maybe<Scalars["ID"]>;
    items?: Maybe<Array<Maybe<ISortingNews_Items>>>;
    _meta?: Maybe<ICaisyDocument_Meta>;
};

export type ISortingNewsItemsArgs = {
    locale?: Maybe<Scalars["String"]>;
};

export type ISortingNews_Items = INews;

export type INews = {
    externalLink: string;
    __typename: "News";
    id?: Maybe<Scalars["ID"]>;
    text?: Maybe<Scalars["JSONObject"]>;
    endDate?: Maybe<Scalars["Date"]>;
    startDate?: Maybe<Scalars["DateTime"]>;
    extras?: Maybe<Array<Maybe<INews_Extras>>>;
    headline?: Maybe<Scalars["String"]>;
    teaser?: Maybe<Scalars["String"]>;
    _meta?: Maybe<ICaisyDocument_Meta>;
};

export type INews_Extras = IMetaInformation | IAsset;

export type IMetaInformation = {
    __typename: "MetaInformation";
    pageTitle?: Maybe<Scalars["String"]>;
    author?: Maybe<Scalars["String"]>;
    description?: Maybe<Scalars["String"]>;
    metaTags?: Maybe<Scalars["String"]>;
};

export type IAsset = {
    __typename: "Asset";
    id?: Maybe<Scalars["ID"]>;
    dominantColor?: Maybe<Scalars["String"]>;
    author?: Maybe<Scalars["String"]>;
    copyright?: Maybe<Scalars["String"]>;
    originType?: Maybe<Scalars["String"]>;
    originalName?: Maybe<Scalars["String"]>;
    keywords?: Maybe<Scalars["String"]>;
    title?: Maybe<Scalars["String"]>;
    descCaisy?: Maybe<Scalars["String"]>;
    description?: Maybe<Scalars["String"]>;
    subtitle?: Maybe<Scalars["String"]>;
    src?: Maybe<Scalars["String"]>;
    _meta?: Maybe<ICaisyDocument_Meta>;
};

export type IAssetSrcArgs = {
    crop?: Maybe<Scalars["String"]>;
};

export type ICaisy_Field_Document_NotFound = {
    __typename: "Caisy_Field_Document_NotFound";
    id?: Maybe<Scalars["String"]>;
    message?: Maybe<Scalars["String"]>;
};

export type ICaisy_UnknownSlice = {
    __typename: "Caisy_UnknownSlice";
    error?: Maybe<Scalars["String"]>;
    raw?: Maybe<Scalars["JSON"]>;
};

export type ISubNavigationItem = {
    __typename: "SubNavigationItem";
    id?: Maybe<Scalars["ID"]>;
    link?: Maybe<Array<Maybe<ISubNavigationItem_Link>>>;
    _meta?: Maybe<ICaisyDocument_Meta>;
};

export type ISubNavigationItemLinkArgs = {
    locale?: Maybe<Scalars["String"]>;
};

export type ISubNavigationItem_Link =
    | IPage
    | IPageRepairInquiry
    | IPageDateInquiry
    | IPageGuestbook
    | IPageNews
    | IPageImprint
    | IPageContact
    | IPageBrand
    | IPageBrandOverview;

export type IPage = {
    __typename: "Page";
    id?: Maybe<Scalars["ID"]>;
    elements?: Maybe<Array<Maybe<IPage_Elements>>>;
    imageFixedBackground?: Maybe<Scalars["Boolean"]>;
    footnote?: Maybe<Scalars["JSONObject"]>;
    extras?: Maybe<Array<Maybe<IPage_Extras>>>;
    twoColumnLayout?: Maybe<Scalars["Boolean"]>;
    nameInNavigation?: Maybe<Scalars["String"]>;
    layout?: null | "twocol" | "fixedonecol" | "fixedtwocol" | "subnavsmall" | "subnavbig" | "default" | "subnavhidden";
    slug?: Maybe<Scalars["String"]>;
    _meta?: Maybe<ICaisyDocument_Meta>;
};

export type IPage_Elements =
    | IAsset
    | IIcon
    | ICallToAction
    | ITextElement
    | ISlider
    | IThemingColor
    | IContinuingPageGallery
    | IPhotoGallery
    | IAsset;

export type IIcon = {
    __typename: "Icon";
    file?: Maybe<Array<Maybe<IIcon_File>>>;
    subtitle?: Maybe<Scalars["String"]>;
};

export type IIconFileArgs = {
    locale?: Maybe<Scalars["String"]>;
};

export type IIcon_File = IAsset;

export type ICallToAction = {
    __typename: "CallToAction";
    linkTo?: Maybe<Array<Maybe<ICallToAction_LinkTo>>>;
    TextAboveButton?: Maybe<Scalars["JSONObject"]>;
    ButtonLabel?: Maybe<Scalars["String"]>;
    TextBelowButton?: Maybe<Scalars["JSONObject"]>;
};

export type ICallToActionLinkToArgs = {
    locale?: Maybe<Scalars["String"]>;
};

export type ICallToAction_LinkTo =
    | IPage
    | IPageRepairInquiry
    | IPageDateInquiry
    | IPageGuestbook
    | IPageNews
    | IPageImprint
    | IPageContact
    | IPageBrand
    | IPageBrandOverview;

export type IPageRepairInquiry = {
    __typename: "PageRepairInquiry";
    id?: Maybe<Scalars["ID"]>;
    extras?: Maybe<Array<Maybe<IPageRepairInquiry_Extras>>>;
    nameInNavigation?: Maybe<Scalars["String"]>;
    elements?: Maybe<Array<Maybe<IPageRepairInquiry_Elements>>>;
    slug?: Maybe<Scalars["String"]>;
    twoColumnLayout?: Maybe<Scalars["Boolean"]>;
    footnote?: Maybe<Scalars["JSONObject"]>;
    imageFixedBackground?: Maybe<Scalars["Boolean"]>;
    _meta?: Maybe<ICaisyDocument_Meta>;
};

export type IPageRepairInquiry_Extras = IMetaInformation | ISidebarText;

export type ISidebarText = {
    __typename: "SidebarText";
    text?: Maybe<Scalars["JSONObject"]>;
};

export type IPageRepairInquiry_Elements = IAsset | IIcon | ICallToAction | ITextElement | ISlider | IThemingColor;

export type ITextElement = {
    __typename: "TextElement";
    icon?: Maybe<Array<Maybe<ITextElement_Icon>>>;
    headline?: Maybe<Scalars["String"]>;
    text?: Maybe<Scalars["JSONObject"]>;
};

export type ITextElement_Icon = IAsset;

export type ISlider = {
    __typename: "Slider";
    elements?: Maybe<Array<Maybe<ISlider_Elements>>>;
};

export type ISliderElementsArgs = {
    locale?: Maybe<Scalars["String"]>;
};

export type ISlider_Elements = IAsset;

export type IThemingColor = {
    __typename: "ThemingColor";
    color?: Maybe<Scalars["String"]>;
};

export type IPageDateInquiry = {
    __typename: "PageDateInquiry";
    id?: Maybe<Scalars["ID"]>;
    elements?: Maybe<Array<Maybe<IPageDateInquiry_Elements>>>;
    extras?: Maybe<Array<Maybe<IPageDateInquiry_Extras>>>;
    nameInNavigation?: Maybe<Scalars["String"]>;
    twoColumnLayout?: Maybe<Scalars["Boolean"]>;
    footnote?: Maybe<Scalars["JSONObject"]>;
    imageFixedBackground?: Maybe<Scalars["Boolean"]>;
    slug?: Maybe<Scalars["String"]>;
    _meta?: Maybe<ICaisyDocument_Meta>;
};

export type IPageDateInquiry_Elements = IAsset | IIcon | ICallToAction | ITextElement | ISlider | IThemingColor;

export type IPageDateInquiry_Extras = IMetaInformation | ISidebarText;

export type IPageGuestbook = {
    __typename: "PageGuestbook";
    id?: Maybe<Scalars["ID"]>;
    extras?: Maybe<Array<Maybe<IPageGuestbook_Extras>>>;
    elements?: Maybe<Array<Maybe<IPageGuestbook_Elements>>>;
    twoColumnLayout?: Maybe<Scalars["Boolean"]>;
    imageFixedBackground?: Maybe<Scalars["Boolean"]>;
    footnote?: Maybe<Scalars["JSONObject"]>;
    nameInNavigation?: Maybe<Scalars["String"]>;
    slug?: Maybe<Scalars["String"]>;
    _meta?: Maybe<ICaisyDocument_Meta>;
};

export type IPageGuestbook_Extras = IMetaInformation | ISidebarText;

export type IPageGuestbook_Elements = IAsset | IIcon | ICallToAction | ITextElement | ISlider | IThemingColor;

export type IPageNews = {
    __typename: "PageNews";
    id?: Maybe<Scalars["ID"]>;
    elements?: Maybe<Array<Maybe<IPageNews_Elements>>>;
    footnote?: Maybe<Scalars["JSONObject"]>;
    extras?: Maybe<Array<Maybe<IPageNews_Extras>>>;
    nameInNavigation?: Maybe<Scalars["String"]>;
    imageFixedBackground?: Maybe<Scalars["Boolean"]>;
    twoColumnLayout?: Maybe<Scalars["Boolean"]>;
    slug?: Maybe<Scalars["String"]>;
    _meta?: Maybe<ICaisyDocument_Meta>;
};

export type IPageNews_Elements = IAsset | IAsset | IIcon | ICallToAction | ITextElement | ISlider | IThemingColor;

export type IPageNews_Extras = IMetaInformation | ISidebarText;

export type IPageImprint = {
    __typename: "PageImprint";
    id?: Maybe<Scalars["ID"]>;
    elements?: Maybe<Array<Maybe<IPageImprint_Elements>>>;
    imageFixedBackground?: Maybe<Scalars["Boolean"]>;
    footnote?: Maybe<Scalars["JSONObject"]>;
    twoColumnLayout?: Maybe<Scalars["Boolean"]>;
    extras?: Maybe<Array<Maybe<IPageImprint_Extras>>>;
    slug?: Maybe<Scalars["String"]>;
    nameInNavigation?: Maybe<Scalars["String"]>;
    _meta?: Maybe<ICaisyDocument_Meta>;
};

export type IExternalLink = {
    __typename?: "ExternalLink";
    id?: Maybe<Scalars["ID"]>;
    externalLinkAddress?: Maybe<Scalars["String"]>;
    externalLinkName?: Maybe<Scalars["String"]>;
};

export type IPageImprint_Elements = IAsset | ITextElement | IPhotoGallery | ISlider | IAsset;

export enum IPhotoGallery_Modal {
    Image = "image",
    Hide = "hide",
    Slider = "slider",
}

export enum IPhotoGallery_Visualization {
    Standard = "standard",
    Slideshowwithoutthumbnails = "slideshowwithoutthumbnails",
    Gallerywiththumbnails = "gallerywiththumbnails",
    Gallerybeforeafter = "gallerybeforeafter",
    Gallerywithimagedescriptionundermainimages = "gallerywithimagedescriptionundermainimages",
    Riverimage = "riverimage",
    Mosaicimage = "mosaicimage",
    Fadeeffect = "fadeeffect",
    Flipeffect = "flipeffect",
    Zoomeffect = "zoomeffect",
}

export type IPhotoGallery = {
    __typename?: "PhotoGallery";
    elements?: Maybe<Array<Maybe<IPhotoGallery_Elements>>>;
    maxCountPerRow?: number;
    modal?: Maybe<IPhotoGallery_Modal>;
    visualization?: Maybe<IPhotoGallery_Visualization>;
};

export type IPhotoGalleryElementsArgs = {
    locale?: Maybe<Scalars["String"]>;
};

export type IPhotoGallery_Elements = IAsset;

export type IPageImprint_Extras = IMetaInformation;

export type IPageContact = {
    __typename: "PageContact";
    id?: Maybe<Scalars["ID"]>;
    twoColumnLayout?: Maybe<Scalars["Boolean"]>;
    location?: Maybe<ICaisy_Field_GeoPoint>;
    elements?: Maybe<Array<Maybe<IPageContact_Elements>>>;
    imageFixedBackground?: Maybe<Scalars["Boolean"]>;
    extras?: Maybe<Array<Maybe<IPageContact_Extras>>>;
    footnote?: Maybe<Scalars["JSONObject"]>;
    slug?: Maybe<Scalars["String"]>;
    nameInNavigation?: Maybe<Scalars["String"]>;
    _meta?: Maybe<ICaisyDocument_Meta>;
};

export type ICaisy_Field_GeoPoint = {
    __typename: "Caisy_Field_GeoPoint";
    latitude?: Maybe<Scalars["Float"]>;
    longitude?: Maybe<Scalars["Float"]>;
    zoom?: Maybe<Scalars["Int"]>;
    formattedAddress?: Maybe<Scalars["String"]>;
};

export type IPageContact_Elements = IAsset | IAsset | IIcon | ICallToAction | ITextElement | ISlider | IThemingColor;

export type IPageContact_Extras = IMetaInformation | ISidebarText;

export type IPageBrand = {
    __typename: "PageBrand";
    id?: Maybe<Scalars["ID"]>;
    imageFixedBackground?: Maybe<Scalars["Boolean"]>;
    footnote?: Maybe<Scalars["JSONObject"]>;
    extras?: Maybe<Array<Maybe<IPageBrand_Extras>>>;
    connectedArticles?: Maybe<Array<Maybe<IPageBrand_ConnectedArticles>>>;
    twoColumnLayout?: Maybe<Scalars["Boolean"]>;
    slug?: Maybe<Scalars["String"]>;
    nameInNavigation?: Maybe<Scalars["String"]>;
    elements?: Maybe<Array<Maybe<IPageBrand_Elements>>>;
    _meta?: Maybe<ICaisyDocument_Meta>;
};

export type IPageBrandConnectedArticlesArgs = {
    locale?: Maybe<Scalars["String"]>;
};

export type IPageBrand_Extras = IMetaInformation;

export type IPageBrand_ConnectedArticles = IArticle;

export type IArticle = {
    __typename: "Article";
    id?: Maybe<Scalars["ID"]>;
    headline?: Maybe<Scalars["String"]>;
    extras?: Maybe<Array<Maybe<IArticle_Extras>>>;
    mainText?: Maybe<Scalars["JSONObject"]>;
    _meta?: Maybe<ICaisyDocument_Meta>;
};

export type IArticle_Extras = IMetaInformation | IAsset | IAsset;

export type IPageBrand_Elements = IAsset | IAsset | IContinuingPageGalleryRoot | ITextElement | IPhotoGallery | ISlider;

export type IContinuingPageGalleryRoot = {
    __typename: "ContinuingPageGalleryRoot";
    items?: Maybe<Array<Maybe<IContinuingPageGalleryRoot_Items>>>;
    maxCountPerRow?: Maybe<Scalars["Float"]>;
};

export type IContinuingPageGalleryRootItemsArgs = {
    locale?: Maybe<Scalars["String"]>;
};

export type IContinuingPageGalleryRoot_Items = IContinuingPageGalleryItemRoot;

export type IContinuingPageGalleryItemRoot = {
    __typename: "ContinuingPageGalleryItemRoot";
    id?: Maybe<Scalars["ID"]>;
    title?: Maybe<Scalars["String"]>;
    thumbnail?: Maybe<Array<Maybe<IContinuingPageGalleryItemRoot_Thumbnail>>>;
    link?: Maybe<Array<Maybe<IContinuingPageGalleryItemRoot_Link>>>;
    _meta?: Maybe<ICaisyDocument_Meta>;
    text?: Maybe<Scalars["JSONObject"]>;
};

export type IContinuingPageGalleryItemRootThumbnailArgs = {
    locale?: Maybe<Scalars["String"]>;
};

export type IContinuingPageGalleryItemRootLinkArgs = {
    locale?: Maybe<Scalars["String"]>;
};

export type IContinuingPageGalleryItemRoot_Thumbnail = IAsset;

export type IContinuingPageGalleryItemRoot_Link =
    | IProduct
    | IProductAccessoriesAndSupplies
    | IProductAService
    | IProductBathroomFurniture
    | IProductCoffeeMachine
    | IProductConsumerElectronics
    | IProductCooktop
    | IProductDishwasher
    | IProductDryer
    | IProductExtractorHood
    | IProductKitchen
    | IProductKitchenFurniture
    | IProductLighting
    | IProductMicrowaveAndCooker
    | IProductRefrigeratorAndFreezer
    | IProductSinkAndTap
    | IProductSmallDevice
    | IProductSparePart
    | IProductStoveAndOven
    | IProductVacuumDrawer
    | IProductWarmingDrawer
    | IProductWashingMachine
    | IProductWorktop
    | IPageBrand;

export type IProduct = {
    __typename: "Product";
    id?: Maybe<Scalars["ID"]>;
    title?: Maybe<Scalars["String"]>;
    services?: Maybe<Array<Maybe<IProduct_Services>>>;
    images?: Maybe<Array<Maybe<IProduct_Images>>>;
    text?: Maybe<Scalars["JSONObject"]>;
    normalPrice?: Maybe<Scalars["String"]>;
    ourPrice?: Maybe<Scalars["String"]>;
    mehrwertsteuer?: Maybe<Scalars["Float"]>;
    startDate?: Maybe<Scalars["DateTime"]>;
    endDate?: Maybe<Scalars["DateTime"]>;
    articelNumber?: Maybe<Scalars["Float"]>;
    productDownloads?: Maybe<Array<Maybe<IProduct_ProductDownloads>>>;
    EAN?: Maybe<Scalars["Float"]>;
    productTechnicalDetails?: Maybe<Scalars["JSONObject"]>;
    productDescription?: Maybe<Scalars["JSONObject"]>;
    acceptAGB?: Maybe<Scalars["Boolean"]>;
    _meta?: Maybe<ICaisyDocument_Meta>;
};

export type IProductServicesArgs = {
    locale?: Maybe<Scalars["String"]>;
};

export type IProductImagesArgs = {
    locale?: Maybe<Scalars["String"]>;
};

export type IProductProductDownloadsArgs = {
    locale?: Maybe<Scalars["String"]>;
};

export type IProduct_Services = IProductService;

export type IProductService = {
    __typename: "ProductService";
    id?: Maybe<Scalars["ID"]>;
    title?: Maybe<Scalars["String"]>;
    icon?: Maybe<Array<Maybe<IProductService_Icon>>>;
    _meta?: Maybe<ICaisyDocument_Meta>;
};

export type IProductServiceIconArgs = {
    locale?: Maybe<Scalars["String"]>;
};

export type IProductService_Icon = IAsset;

export type IProduct_Images = IAsset;

export type IProduct_ProductDownloads = IAsset;

export type IProductAccessoriesAndSupplies = {
    __typename: "ProductAccessoriesAndSupplies";
    id?: Maybe<Scalars["ID"]>;
    title?: Maybe<Scalars["String"]>;
    services?: Maybe<Array<Maybe<IProductAccessoriesAndSupplies_Services>>>;
    images?: Maybe<Array<Maybe<IProductAccessoriesAndSupplies_Images>>>;
    ourPrice?: Maybe<Scalars["String"]>;
    normalPrice?: Maybe<Scalars["String"]>;
    text?: Maybe<Scalars["JSONObject"]>;
    mehrwertsteuer?: Maybe<Scalars["Float"]>;
    startDate?: Maybe<Scalars["DateTime"]>;
    endDate?: Maybe<Scalars["DateTime"]>;
    productDescription?: Maybe<Scalars["JSONObject"]>;
    articelNumber?: Maybe<Scalars["Float"]>;
    productTechnicalDetails?: Maybe<Scalars["JSONObject"]>;
    productDownloads?: Maybe<Array<Maybe<IProductAccessoriesAndSupplies_ProductDownloads>>>;
    hight?: Maybe<Scalars["Float"]>;
    width?: Maybe<Scalars["Float"]>;
    EAN?: Maybe<Scalars["Float"]>;
    depth?: Maybe<Scalars["Float"]>;
    acceptAGB?: Maybe<Scalars["Boolean"]>;
    weight?: Maybe<Scalars["Float"]>;
    _meta?: Maybe<ICaisyDocument_Meta>;
};

export type IProductAccessoriesAndSuppliesServicesArgs = {
    locale?: Maybe<Scalars["String"]>;
};

export type IProductAccessoriesAndSuppliesImagesArgs = {
    locale?: Maybe<Scalars["String"]>;
};

export type IProductAccessoriesAndSuppliesProductDownloadsArgs = {
    locale?: Maybe<Scalars["String"]>;
};

export type IProductAccessoriesAndSupplies_Services = IProductService;

export type IProductAccessoriesAndSupplies_Images = IAsset;

export type IProductAccessoriesAndSupplies_ProductDownloads = IAsset;

export type IProductAService = {
    __typename: "ProductAService";
    id?: Maybe<Scalars["ID"]>;
    title?: Maybe<Scalars["String"]>;
    normalPrice?: Maybe<Scalars["String"]>;
    images?: Maybe<Array<Maybe<IProductAService_Images>>>;
    services?: Maybe<Array<Maybe<IProductAService_Services>>>;
    text?: Maybe<Scalars["JSONObject"]>;
    ourPrice?: Maybe<Scalars["String"]>;
    mehrwertsteuer?: Maybe<Scalars["Float"]>;
    endDate?: Maybe<Scalars["DateTime"]>;
    startDate?: Maybe<Scalars["DateTime"]>;
    articelNumber?: Maybe<Scalars["Float"]>;
    productDescription?: Maybe<Scalars["JSONObject"]>;
    productTechnicalDetails?: Maybe<Scalars["JSONObject"]>;
    productDownloads?: Maybe<Array<Maybe<IProductAService_ProductDownloads>>>;
    acceptAGB?: Maybe<Scalars["Boolean"]>;
    _meta?: Maybe<ICaisyDocument_Meta>;
};

export type IProductAServiceImagesArgs = {
    locale?: Maybe<Scalars["String"]>;
};

export type IProductAServiceServicesArgs = {
    locale?: Maybe<Scalars["String"]>;
};

export type IProductAServiceProductDownloadsArgs = {
    locale?: Maybe<Scalars["String"]>;
};

export type IProductAService_Images = IAsset;

export type IProductAService_Services = IProductService;

export type IProductAService_ProductDownloads = IAsset;

export type IProductBathroomFurniture = {
    __typename: "ProductBathroomFurniture";
    id?: Maybe<Scalars["ID"]>;
    title?: Maybe<Scalars["String"]>;
    images?: Maybe<Array<Maybe<IProductBathroomFurniture_Images>>>;
    text?: Maybe<Scalars["JSONObject"]>;
    services?: Maybe<Array<Maybe<IProductBathroomFurniture_Services>>>;
    ourPrice?: Maybe<Scalars["String"]>;
    normalPrice?: Maybe<Scalars["String"]>;
    mehrwertsteuer?: Maybe<Scalars["Float"]>;
    startDate?: Maybe<Scalars["DateTime"]>;
    color?: Maybe<Scalars["String"]>;
    endDate?: Maybe<Scalars["DateTime"]>;
    articelNumber?: Maybe<Scalars["Float"]>;
    productDescription?: Maybe<Scalars["JSONObject"]>;
    productTechnicalDetails?: Maybe<Scalars["JSONObject"]>;
    width?: Maybe<Scalars["Float"]>;
    productDownloads?: Maybe<Array<Maybe<IProductBathroomFurniture_ProductDownloads>>>;
    depth?: Maybe<Scalars["Float"]>;
    hight?: Maybe<Scalars["Float"]>;
    acceptAGB?: Maybe<Scalars["Boolean"]>;
    _meta?: Maybe<ICaisyDocument_Meta>;
};

export type IProductBathroomFurnitureImagesArgs = {
    locale?: Maybe<Scalars["String"]>;
};

export type IProductBathroomFurnitureServicesArgs = {
    locale?: Maybe<Scalars["String"]>;
};

export type IProductBathroomFurnitureProductDownloadsArgs = {
    locale?: Maybe<Scalars["String"]>;
};

export type IProductBathroomFurniture_Images = IAsset;

export type IProductBathroomFurniture_Services = IProductService;

export type IProductBathroomFurniture_ProductDownloads = IAsset;

export type IProductCoffeeMachine = {
    __typename: "ProductCoffeeMachine";
    id?: Maybe<Scalars["ID"]>;
    title?: Maybe<Scalars["String"]>;
    images?: Maybe<Array<Maybe<IProductCoffeeMachine_Images>>>;
    ourPrice?: Maybe<Scalars["String"]>;
    normalPrice?: Maybe<Scalars["String"]>;
    services?: Maybe<Array<Maybe<IProductCoffeeMachine_Services>>>;
    text?: Maybe<Scalars["JSONObject"]>;
    mehrwertsteuer?: Maybe<Scalars["Float"]>;
    startDate?: Maybe<Scalars["DateTime"]>;
    endDate?: Maybe<Scalars["DateTime"]>;
    acceptAGB?: Maybe<Scalars["Boolean"]>;
    Technology?: Maybe<IProductCoffeeMachine_Technology>;
    articelNumber?: Maybe<Scalars["Float"]>;
    productDownloads?: Maybe<Array<Maybe<IProductCoffeeMachine_ProductDownloads>>>;
    hight?: Maybe<Scalars["Float"]>;
    EAN?: Maybe<Scalars["Float"]>;
    width?: Maybe<Scalars["Float"]>;
    EnergyEfficiencyClass?: Maybe<IProductCoffeeMachine_EnergyEfficiencyClass>;
    depth?: Maybe<Scalars["Float"]>;
    EnergyConsumption?: Maybe<Scalars["String"]>;
    BuiltInDevice?: Maybe<Scalars["Boolean"]>;
    productDescription?: Maybe<Scalars["JSONObject"]>;
    productTechnicalDetails?: Maybe<Scalars["JSONObject"]>;
    weight?: Maybe<Scalars["Float"]>;
    FullyAutomaticCoffeeMachine?: Maybe<Scalars["Boolean"]>;
    _meta?: Maybe<ICaisyDocument_Meta>;
};

export type IProductCoffeeMachineImagesArgs = {
    locale?: Maybe<Scalars["String"]>;
};

export type IProductCoffeeMachineServicesArgs = {
    locale?: Maybe<Scalars["String"]>;
};

export type IProductCoffeeMachineProductDownloadsArgs = {
    locale?: Maybe<Scalars["String"]>;
};

export type IProductCoffeeMachine_Images = IAsset;

export type IProductCoffeeMachine_Services = IProductService;

export enum IProductCoffeeMachine_Technology {
    Filterkaffeemachine = "Filterkaffeemachine",
    Hebelmaschine = "Hebelmaschine",
    Kaffeevollautomat = "Kaffeevollautomat",
}

export type IProductCoffeeMachine_ProductDownloads = IAsset;

export enum IProductCoffeeMachine_EnergyEfficiencyClass {
    APlusPlusPlus = "APlusPlusPlus",
    APlusPlus = "APlusPlus",
    APlus = "APlus",
    A = "A",
    B = "B",
    C = "C",
    D = "D",
    E = "E",
    F = "F",
    G = "G",
    H = "H",
}

export type IProductConsumerElectronics = {
    __typename: "ProductConsumerElectronics";
    id?: Maybe<Scalars["ID"]>;
    title?: Maybe<Scalars["String"]>;
    text?: Maybe<Scalars["JSONObject"]>;
    images?: Maybe<Array<Maybe<IProductConsumerElectronics_Images>>>;
    services?: Maybe<Array<Maybe<IProductConsumerElectronics_Services>>>;
    normalPrice?: Maybe<Scalars["String"]>;
    ourPrice?: Maybe<Scalars["String"]>;
    mehrwertsteuer?: Maybe<Scalars["Float"]>;
    startDate?: Maybe<Scalars["DateTime"]>;
    endDate?: Maybe<Scalars["DateTime"]>;
    articelNumber?: Maybe<Scalars["Float"]>;
    productTechnicalDetails?: Maybe<Scalars["JSONObject"]>;
    productDescription?: Maybe<Scalars["JSONObject"]>;
    EAN?: Maybe<Scalars["Float"]>;
    hight?: Maybe<Scalars["Float"]>;
    productDownloads?: Maybe<Array<Maybe<IProductConsumerElectronics_ProductDownloads>>>;
    EnergyConsumption?: Maybe<Scalars["String"]>;
    depth?: Maybe<Scalars["Float"]>;
    EnergyEfficiencyClass?: Maybe<IProductConsumerElectronics_EnergyEfficiencyClass>;
    width?: Maybe<Scalars["Float"]>;
    acceptAGB?: Maybe<Scalars["Boolean"]>;
    weight?: Maybe<Scalars["Float"]>;
    _meta?: Maybe<ICaisyDocument_Meta>;
};

export type IProductConsumerElectronicsImagesArgs = {
    locale?: Maybe<Scalars["String"]>;
};

export type IProductConsumerElectronicsServicesArgs = {
    locale?: Maybe<Scalars["String"]>;
};

export type IProductConsumerElectronicsProductDownloadsArgs = {
    locale?: Maybe<Scalars["String"]>;
};

export type IProductConsumerElectronics_Images = IAsset;

export type IProductConsumerElectronics_Services = IProductService;

export type IProductConsumerElectronics_ProductDownloads = IAsset;

export enum IProductConsumerElectronics_EnergyEfficiencyClass {
    APlusPlusPlus = "APlusPlusPlus",
    APlusPlus = "APlusPlus",
    APlus = "APlus",
    A = "A",
    B = "B",
    C = "C",
    D = "D",
    E = "E",
    F = "F",
    G = "G",
    H = "H",
}

export type IProductCooktop = {
    __typename: "ProductCooktop";
    id?: Maybe<Scalars["ID"]>;
    services?: Maybe<Array<Maybe<IProductCooktop_Services>>>;
    text?: Maybe<Scalars["JSONObject"]>;
    title?: Maybe<Scalars["String"]>;
    images?: Maybe<Array<Maybe<IProductCooktop_Images>>>;
    articelNumber?: Maybe<Scalars["Float"]>;
    normalPrice?: Maybe<Scalars["String"]>;
    ourPrice?: Maybe<Scalars["String"]>;
    mehrwertsteuer?: Maybe<Scalars["Float"]>;
    startDate?: Maybe<Scalars["DateTime"]>;
    endDate?: Maybe<Scalars["DateTime"]>;
    productTechnicalDetails?: Maybe<Scalars["JSONObject"]>;
    productDescription?: Maybe<Scalars["JSONObject"]>;
    EAN?: Maybe<Scalars["Float"]>;
    productDownloads?: Maybe<Array<Maybe<IProductCooktop_ProductDownloads>>>;
    hight?: Maybe<Scalars["Float"]>;
    width?: Maybe<Scalars["Float"]>;
    EnergyEfficiencyClass?: Maybe<IProductCooktop_EnergyEfficiencyClass>;
    depth?: Maybe<Scalars["Float"]>;
    EnergyConsumption?: Maybe<Scalars["String"]>;
    Connectivity?: Maybe<Scalars["String"]>;
    InductionType?: Maybe<Scalars["String"]>;
    weight?: Maybe<Scalars["Float"]>;
    acceptAGB?: Maybe<Scalars["Boolean"]>;
    _meta?: Maybe<ICaisyDocument_Meta>;
};

export type IProductCooktopServicesArgs = {
    locale?: Maybe<Scalars["String"]>;
};

export type IProductCooktopImagesArgs = {
    locale?: Maybe<Scalars["String"]>;
};

export type IProductCooktopProductDownloadsArgs = {
    locale?: Maybe<Scalars["String"]>;
};

export type IProductCooktop_Services = IProductService;

export type IProductCooktop_Images = IAsset;

export type IProductCooktop_ProductDownloads = IAsset;

export enum IProductCooktop_EnergyEfficiencyClass {
    APlusPlusPlus = "APlusPlusPlus",
    APlusPlus = "APlusPlus",
    APlus = "APlus",
    A = "A",
    B = "B",
    C = "C",
    D = "D",
    E = "E",
    F = "F",
    G = "G",
    H = "H",
}

export type IProductDishwasher = {
    __typename: "ProductDishwasher";
    id?: Maybe<Scalars["ID"]>;
    title?: Maybe<Scalars["String"]>;
    images?: Maybe<Array<Maybe<IProductDishwasher_Images>>>;
    normalPrice?: Maybe<Scalars["String"]>;
    ourPrice?: Maybe<Scalars["String"]>;
    services?: Maybe<Array<Maybe<IProductDishwasher_Services>>>;
    text?: Maybe<Scalars["JSONObject"]>;
    mehrwertsteuer?: Maybe<Scalars["Float"]>;
    startDate?: Maybe<Scalars["DateTime"]>;
    endDate?: Maybe<Scalars["DateTime"]>;
    articelNumber?: Maybe<Scalars["Float"]>;
    productDescription?: Maybe<Scalars["JSONObject"]>;
    productTechnicalDetails?: Maybe<Scalars["JSONObject"]>;
    productDownloads?: Maybe<Array<Maybe<IProductDishwasher_ProductDownloads>>>;
    width?: Maybe<Scalars["Float"]>;
    depth?: Maybe<Scalars["Float"]>;
    hight?: Maybe<Scalars["Float"]>;
    EAN?: Maybe<Scalars["Float"]>;
    EnergyEfficiencyClass?: Maybe<IProductDishwasher_EnergyEfficiencyClass>;
    WaterConsumption?: Maybe<Scalars["String"]>;
    EnergyConsumption?: Maybe<Scalars["String"]>;
    NominalCapacity?: Maybe<Scalars["String"]>;
    EN50242?: Maybe<Scalars["String"]>;
    Connectivity?: Maybe<Scalars["String"]>;
    weight?: Maybe<Scalars["Float"]>;
    acceptAGB?: Maybe<Scalars["Boolean"]>;
    BuiltInDevice?: Maybe<Scalars["Boolean"]>;
    _meta?: Maybe<ICaisyDocument_Meta>;
};

export type IProductDishwasherImagesArgs = {
    locale?: Maybe<Scalars["String"]>;
};

export type IProductDishwasherServicesArgs = {
    locale?: Maybe<Scalars["String"]>;
};

export type IProductDishwasherProductDownloadsArgs = {
    locale?: Maybe<Scalars["String"]>;
};

export type IProductDishwasher_Images = IAsset;

export type IProductDishwasher_Services = IProductService;

export type IProductDishwasher_ProductDownloads = IAsset;

export enum IProductDishwasher_EnergyEfficiencyClass {
    APlusPlusPlus = "APlusPlusPlus",
    APlusPlus = "APlusPlus",
    APlus = "APlus",
    A = "A",
    B = "B",
    C = "C",
    D = "D",
    E = "E",
    F = "F",
    G = "G",
    H = "H",
}

export type IProductDryer = {
    __typename: "ProductDryer";
    id?: Maybe<Scalars["ID"]>;
    title?: Maybe<Scalars["String"]>;
    services?: Maybe<Array<Maybe<IProductDryer_Services>>>;
    text?: Maybe<Scalars["JSONObject"]>;
    ourPrice?: Maybe<Scalars["String"]>;
    images?: Maybe<Array<Maybe<IProductDryer_Images>>>;
    normalPrice?: Maybe<Scalars["String"]>;
    startDate?: Maybe<Scalars["DateTime"]>;
    mehrwertsteuer?: Maybe<Scalars["Float"]>;
    endDate?: Maybe<Scalars["DateTime"]>;
    articelNumber?: Maybe<Scalars["Float"]>;
    productTechnicalDetails?: Maybe<Scalars["JSONObject"]>;
    productDescription?: Maybe<Scalars["JSONObject"]>;
    productDownloads?: Maybe<Array<Maybe<IProductDryer_ProductDownloads>>>;
    EnergyEfficiencyClass?: Maybe<IProductDryer_EnergyEfficiencyClass>;
    EAN?: Maybe<Scalars["Float"]>;
    hight?: Maybe<Scalars["Float"]>;
    width?: Maybe<Scalars["Float"]>;
    EnergyConsumption?: Maybe<Scalars["String"]>;
    depth?: Maybe<Scalars["Float"]>;
    DryingEfficiencyClass?: Maybe<IProductDryer_DryingEfficiencyClass>;
    CondensationOrHeatPumpDryer?: Maybe<Scalars["String"]>;
    BuiltInDevice?: Maybe<Scalars["Boolean"]>;
    Capacity?: Maybe<Scalars["String"]>;
    weight?: Maybe<Scalars["Float"]>;
    acceptAGB?: Maybe<Scalars["Boolean"]>;
    _meta?: Maybe<ICaisyDocument_Meta>;
};

export type IProductDryerServicesArgs = {
    locale?: Maybe<Scalars["String"]>;
};

export type IProductDryerImagesArgs = {
    locale?: Maybe<Scalars["String"]>;
};

export type IProductDryerProductDownloadsArgs = {
    locale?: Maybe<Scalars["String"]>;
};

export type IProductDryer_Services = IProductService;

export type IProductDryer_Images = IAsset;

export type IProductDryer_ProductDownloads = IAsset;

export enum IProductDryer_EnergyEfficiencyClass {
    APlusPlusPlus = "APlusPlusPlus",
    APlusPlus = "APlusPlus",
    APlus = "APlus",
    A = "A",
    B = "B",
    C = "C",
    D = "D",
    E = "E",
    F = "F",
    G = "G",
    H = "H",
}

export enum IProductDryer_DryingEfficiencyClass {
    A = "A",
    B = "B",
    C = "C",
    D = "D",
    E = "E",
    F = "F",
    G = "G",
}

export type IProductExtractorHood = {
    __typename: "ProductExtractorHood";
    id?: Maybe<Scalars["ID"]>;
    images?: Maybe<Array<Maybe<IProductExtractorHood_Images>>>;
    title?: Maybe<Scalars["String"]>;
    text?: Maybe<Scalars["JSONObject"]>;
    services?: Maybe<Array<Maybe<IProductExtractorHood_Services>>>;
    normalPrice?: Maybe<Scalars["String"]>;
    ourPrice?: Maybe<Scalars["String"]>;
    mehrwertsteuer?: Maybe<Scalars["Float"]>;
    startDate?: Maybe<Scalars["DateTime"]>;
    endDate?: Maybe<Scalars["DateTime"]>;
    articelNumber?: Maybe<Scalars["Float"]>;
    productDownloads?: Maybe<Array<Maybe<IProductExtractorHood_ProductDownloads>>>;
    productTechnicalDetails?: Maybe<Scalars["JSONObject"]>;
    hight?: Maybe<Scalars["Float"]>;
    depth?: Maybe<Scalars["Float"]>;
    width?: Maybe<Scalars["Float"]>;
    EAN?: Maybe<Scalars["Float"]>;
    EnergyConsumption?: Maybe<Scalars["String"]>;
    EnergyEfficiencyClass?: Maybe<IProductExtractorHood_EnergyEfficiencyClass>;
    productDescription?: Maybe<Scalars["JSONObject"]>;
    BuiltInDevice?: Maybe<Scalars["Boolean"]>;
    FilterPerformance?: Maybe<Scalars["String"]>;
    ExhaustAirOrCirculatingAir?: Maybe<Scalars["String"]>;
    Connectivity?: Maybe<Scalars["String"]>;
    weight?: Maybe<Scalars["Float"]>;
    acceptAGB?: Maybe<Scalars["Boolean"]>;
    HasTroughfanOrDowndraft?: Maybe<Scalars["Boolean"]>;
    _meta?: Maybe<ICaisyDocument_Meta>;
};

export type IProductExtractorHoodImagesArgs = {
    locale?: Maybe<Scalars["String"]>;
};

export type IProductExtractorHoodServicesArgs = {
    locale?: Maybe<Scalars["String"]>;
};

export type IProductExtractorHoodProductDownloadsArgs = {
    locale?: Maybe<Scalars["String"]>;
};

export type IProductExtractorHood_Images = IAsset;

export type IProductExtractorHood_Services = IProductService;

export type IProductExtractorHood_ProductDownloads = IAsset;

export enum IProductExtractorHood_EnergyEfficiencyClass {
    APlusPlusPlus = "APlusPlusPlus",
    APlusPlus = "APlusPlus",
    APlus = "APlus",
    A = "A",
    B = "B",
    C = "C",
    D = "D",
    E = "E",
    F = "F",
    G = "G",
    H = "H",
}

export type IProductKitchen = {
    __typename: "ProductKitchen";
    id?: Maybe<Scalars["ID"]>;
    title?: Maybe<Scalars["String"]>;
    images?: Maybe<Array<Maybe<IProductKitchen_Images>>>;
    text?: Maybe<Scalars["JSONObject"]>;
    services?: Maybe<Array<Maybe<IProductKitchen_Services>>>;
    normalPrice?: Maybe<Scalars["String"]>;
    ourPrice?: Maybe<Scalars["String"]>;
    mehrwertsteuer?: Maybe<Scalars["Float"]>;
    startDate?: Maybe<Scalars["DateTime"]>;
    endDate?: Maybe<Scalars["DateTime"]>;
    productDownloads?: Maybe<Array<Maybe<IProductKitchen_ProductDownloads>>>;
    articelNumber?: Maybe<Scalars["Float"]>;
    productDescription?: Maybe<Scalars["JSONObject"]>;
    productTechnicalDetails?: Maybe<Scalars["JSONObject"]>;
    acceptAGB?: Maybe<Scalars["Boolean"]>;
    _meta?: Maybe<ICaisyDocument_Meta>;
};

export type IProductKitchenImagesArgs = {
    locale?: Maybe<Scalars["String"]>;
};

export type IProductKitchenServicesArgs = {
    locale?: Maybe<Scalars["String"]>;
};

export type IProductKitchenProductDownloadsArgs = {
    locale?: Maybe<Scalars["String"]>;
};

export type IProductKitchen_Images = IAsset;

export type IProductKitchen_Services = IProductService;

export type IProductKitchen_ProductDownloads = IAsset;

export type IProductKitchenFurniture = {
    __typename: "ProductKitchenFurniture";
    id?: Maybe<Scalars["ID"]>;
    title?: Maybe<Scalars["String"]>;
    images?: Maybe<Array<Maybe<IProductKitchenFurniture_Images>>>;
    ourPrice?: Maybe<Scalars["String"]>;
    normalPrice?: Maybe<Scalars["String"]>;
    services?: Maybe<Array<Maybe<IProductKitchenFurniture_Services>>>;
    text?: Maybe<Scalars["JSONObject"]>;
    mehrwertsteuer?: Maybe<Scalars["Float"]>;
    startDate?: Maybe<Scalars["DateTime"]>;
    endDate?: Maybe<Scalars["DateTime"]>;
    acceptAGB?: Maybe<Scalars["Boolean"]>;
    articelNumber?: Maybe<Scalars["Float"]>;
    productDescription?: Maybe<Scalars["JSONObject"]>;
    productTechnicalDetails?: Maybe<Scalars["JSONObject"]>;
    productDownloads?: Maybe<Array<Maybe<IProductKitchenFurniture_ProductDownloads>>>;
    _meta?: Maybe<ICaisyDocument_Meta>;
};

export type IProductKitchenFurnitureImagesArgs = {
    locale?: Maybe<Scalars["String"]>;
};

export type IProductKitchenFurnitureServicesArgs = {
    locale?: Maybe<Scalars["String"]>;
};

export type IProductKitchenFurnitureProductDownloadsArgs = {
    locale?: Maybe<Scalars["String"]>;
};

export type IProductKitchenFurniture_Images = IAsset;

export type IProductKitchenFurniture_Services = IProductService;

export type IProductKitchenFurniture_ProductDownloads = IAsset;

export type IProductLighting = {
    __typename: "ProductLighting";
    id?: Maybe<Scalars["ID"]>;
    title?: Maybe<Scalars["String"]>;
    images?: Maybe<Array<Maybe<IProductLighting_Images>>>;
    text?: Maybe<Scalars["JSONObject"]>;
    services?: Maybe<Array<Maybe<IProductLighting_Services>>>;
    normalPrice?: Maybe<Scalars["String"]>;
    ourPrice?: Maybe<Scalars["String"]>;
    mehrwertsteuer?: Maybe<Scalars["Float"]>;
    startDate?: Maybe<Scalars["DateTime"]>;
    endDate?: Maybe<Scalars["DateTime"]>;
    Power?: Maybe<Scalars["String"]>;
    Connectivity?: Maybe<Scalars["String"]>;
    articelNumber?: Maybe<Scalars["Float"]>;
    productTechnicalDetails?: Maybe<Scalars["JSONObject"]>;
    productDownloads?: Maybe<Array<Maybe<IProductLighting_ProductDownloads>>>;
    EAN?: Maybe<Scalars["Float"]>;
    productDescription?: Maybe<Scalars["JSONObject"]>;
    hight?: Maybe<Scalars["Float"]>;
    width?: Maybe<Scalars["Float"]>;
    depth?: Maybe<Scalars["Float"]>;
    EnergyEfficiencyClass?: Maybe<IProductLighting_EnergyEfficiencyClass>;
    EnergyConsumption?: Maybe<Scalars["String"]>;
    acceptAGB?: Maybe<Scalars["Boolean"]>;
    _meta?: Maybe<ICaisyDocument_Meta>;
};

export type IProductLightingImagesArgs = {
    locale?: Maybe<Scalars["String"]>;
};

export type IProductLightingServicesArgs = {
    locale?: Maybe<Scalars["String"]>;
};

export type IProductLightingProductDownloadsArgs = {
    locale?: Maybe<Scalars["String"]>;
};

export type IProductLighting_Images = IAsset;

export type IProductLighting_Services = IProductService;

export type IProductLighting_ProductDownloads = IAsset;

export enum IProductLighting_EnergyEfficiencyClass {
    APlusPlusPlus = "APlusPlusPlus",
    APlusPlus = "APlusPlus",
    APlus = "APlus",
    A = "A",
    B = "B",
    C = "C",
    D = "D",
    E = "E",
    F = "F",
    G = "G",
    H = "H",
}

export type IProductMicrowaveAndCooker = {
    __typename: "ProductMicrowaveAndCooker";
    id?: Maybe<Scalars["ID"]>;
    text?: Maybe<Scalars["JSONObject"]>;
    services?: Maybe<Array<Maybe<IProductMicrowaveAndCooker_Services>>>;
    images?: Maybe<Array<Maybe<IProductMicrowaveAndCooker_Images>>>;
    normalPrice?: Maybe<Scalars["String"]>;
    ourPrice?: Maybe<Scalars["String"]>;
    title?: Maybe<Scalars["String"]>;
    mehrwertsteuer?: Maybe<Scalars["Float"]>;
    endDate?: Maybe<Scalars["DateTime"]>;
    startDate?: Maybe<Scalars["DateTime"]>;
    articelNumber?: Maybe<Scalars["Float"]>;
    productTechnicalDetails?: Maybe<Scalars["JSONObject"]>;
    productDescription?: Maybe<Scalars["JSONObject"]>;
    hight?: Maybe<Scalars["Float"]>;
    productDownloads?: Maybe<Array<Maybe<IProductMicrowaveAndCooker_ProductDownloads>>>;
    EAN?: Maybe<Scalars["Float"]>;
    width?: Maybe<Scalars["Float"]>;
    EnergyConsumption?: Maybe<Scalars["String"]>;
    steamCooker?: Maybe<Scalars["Boolean"]>;
    depth?: Maybe<Scalars["Float"]>;
    EnergyEfficiencyClass?: Maybe<IProductMicrowaveAndCooker_EnergyEfficiencyClass>;
    Connectivity?: Maybe<Scalars["String"]>;
    CookingChamberUsableVolume?: Maybe<Scalars["String"]>;
    weight?: Maybe<Scalars["Float"]>;
    acceptAGB?: Maybe<Scalars["Boolean"]>;
    BuiltInDevice?: Maybe<Scalars["Boolean"]>;
    _meta?: Maybe<ICaisyDocument_Meta>;
};

export type IProductMicrowaveAndCookerServicesArgs = {
    locale?: Maybe<Scalars["String"]>;
};

export type IProductMicrowaveAndCookerImagesArgs = {
    locale?: Maybe<Scalars["String"]>;
};

export type IProductMicrowaveAndCookerProductDownloadsArgs = {
    locale?: Maybe<Scalars["String"]>;
};

export type IProductMicrowaveAndCooker_Services = IProductService;

export type IProductMicrowaveAndCooker_Images = IAsset;

export type IProductMicrowaveAndCooker_ProductDownloads = IAsset;

export enum IProductMicrowaveAndCooker_EnergyEfficiencyClass {
    APlusPlusPlus = "APlusPlusPlus",
    APlusPlus = "APlusPlus",
    APlus = "APlus",
    A = "A",
    B = "B",
    C = "C",
    D = "D",
    E = "E",
    F = "F",
    G = "G",
    H = "H",
}

export type IProductRefrigeratorAndFreezer = {
    __typename: "ProductRefrigeratorAndFreezer";
    id?: Maybe<Scalars["ID"]>;
    text?: Maybe<Scalars["JSONObject"]>;
    normalPrice?: Maybe<Scalars["String"]>;
    ourPrice?: Maybe<Scalars["String"]>;
    images?: Maybe<Array<Maybe<IProductRefrigeratorAndFreezer_Images>>>;
    services?: Maybe<Array<Maybe<IProductRefrigeratorAndFreezer_Services>>>;
    title?: Maybe<Scalars["String"]>;
    mehrwertsteuer?: Maybe<Scalars["Float"]>;
    startDate?: Maybe<Scalars["DateTime"]>;
    endDate?: Maybe<Scalars["DateTime"]>;
    articelNumber?: Maybe<Scalars["Float"]>;
    productDescription?: Maybe<Scalars["JSONObject"]>;
    productTechnicalDetails?: Maybe<Scalars["JSONObject"]>;
    hight?: Maybe<Scalars["Float"]>;
    EAN?: Maybe<Scalars["Float"]>;
    width?: Maybe<Scalars["Float"]>;
    productDownloads?: Maybe<Array<Maybe<IProductRefrigeratorAndFreezer_ProductDownloads>>>;
    depth?: Maybe<Scalars["Float"]>;
    EnergyEfficiencyClass?: Maybe<IProductRefrigeratorAndFreezer_EnergyEfficiencyClass>;
    EnergyConsumption?: Maybe<Scalars["String"]>;
    UsableCapacityCoolerCompartment?: Maybe<Scalars["String"]>;
    BuiltInDevice?: Maybe<Scalars["Boolean"]>;
    NoiseEmission?: Maybe<Scalars["String"]>;
    Connectivity?: Maybe<Scalars["String"]>;
    UsableCapacityFreezerCompartment?: Maybe<Scalars["String"]>;
    StarRating?: Maybe<Scalars["String"]>;
    weight?: Maybe<Scalars["Float"]>;
    acceptAGB?: Maybe<Scalars["Boolean"]>;
    ZeroDegreeZone?: Maybe<Scalars["Boolean"]>;
    _meta?: Maybe<ICaisyDocument_Meta>;
};

export type IProductRefrigeratorAndFreezerImagesArgs = {
    locale?: Maybe<Scalars["String"]>;
};

export type IProductRefrigeratorAndFreezerServicesArgs = {
    locale?: Maybe<Scalars["String"]>;
};

export type IProductRefrigeratorAndFreezerProductDownloadsArgs = {
    locale?: Maybe<Scalars["String"]>;
};

export type IProductRefrigeratorAndFreezer_Images = IAsset;

export type IProductRefrigeratorAndFreezer_Services = IProductService;

export type IProductRefrigeratorAndFreezer_ProductDownloads = IAsset;

export enum IProductRefrigeratorAndFreezer_EnergyEfficiencyClass {
    APlusPlusPlus = "APlusPlusPlus",
    APlusPlus = "APlusPlus",
    APlus = "APlus",
    A = "A",
    B = "B",
    C = "C",
    D = "D",
    E = "E",
    F = "F",
    G = "G",
    H = "H",
}

export type IProductSinkAndTap = {
    __typename: "ProductSinkAndTap";
    id?: Maybe<Scalars["ID"]>;
    title?: Maybe<Scalars["String"]>;
    text?: Maybe<Scalars["JSONObject"]>;
    images?: Maybe<Array<Maybe<IProductSinkAndTap_Images>>>;
    services?: Maybe<Array<Maybe<IProductSinkAndTap_Services>>>;
    normalPrice?: Maybe<Scalars["String"]>;
    ourPrice?: Maybe<Scalars["String"]>;
    color?: Maybe<Scalars["String"]>;
    startDate?: Maybe<Scalars["DateTime"]>;
    endDate?: Maybe<Scalars["DateTime"]>;
    acceptAGB?: Maybe<Scalars["Boolean"]>;
    mehrwertsteuer?: Maybe<Scalars["Float"]>;
    productDownloads?: Maybe<Array<Maybe<IProductSinkAndTap_ProductDownloads>>>;
    articelNumber?: Maybe<Scalars["Float"]>;
    Type?: Maybe<Scalars["String"]>;
    productDescription?: Maybe<Scalars["JSONObject"]>;
    Martial?: Maybe<Scalars["String"]>;
    productTechnicalDetails?: Maybe<Scalars["JSONObject"]>;
    _meta?: Maybe<ICaisyDocument_Meta>;
};

export type IProductSinkAndTapImagesArgs = {
    locale?: Maybe<Scalars["String"]>;
};

export type IProductSinkAndTapServicesArgs = {
    locale?: Maybe<Scalars["String"]>;
};

export type IProductSinkAndTapProductDownloadsArgs = {
    locale?: Maybe<Scalars["String"]>;
};

export type IProductSinkAndTap_Images = IAsset;

export type IProductSinkAndTap_Services = IProductService;

export type IProductSinkAndTap_ProductDownloads = IAsset;

export type IProductSmallDevice = {
    __typename: "ProductSmallDevice";
    id?: Maybe<Scalars["ID"]>;
    ourPrice?: Maybe<Scalars["String"]>;
    normalPrice?: Maybe<Scalars["String"]>;
    services?: Maybe<Array<Maybe<IProductSmallDevice_Services>>>;
    images?: Maybe<Array<Maybe<IProductSmallDevice_Images>>>;
    text?: Maybe<Scalars["JSONObject"]>;
    title?: Maybe<Scalars["String"]>;
    mehrwertsteuer?: Maybe<Scalars["Float"]>;
    endDate?: Maybe<Scalars["DateTime"]>;
    startDate?: Maybe<Scalars["DateTime"]>;
    articelNumber?: Maybe<Scalars["Float"]>;
    productDescription?: Maybe<Scalars["JSONObject"]>;
    productDownloads?: Maybe<Array<Maybe<IProductSmallDevice_ProductDownloads>>>;
    productTechnicalDetails?: Maybe<Scalars["JSONObject"]>;
    hight?: Maybe<Scalars["Float"]>;
    EAN?: Maybe<Scalars["Float"]>;
    width?: Maybe<Scalars["Float"]>;
    EnergyEfficiencyClass?: Maybe<IProductSmallDevice_EnergyEfficiencyClass>;
    depth?: Maybe<Scalars["Float"]>;
    Connectivity?: Maybe<Scalars["String"]>;
    Power?: Maybe<Scalars["String"]>;
    Category?: Maybe<Scalars["String"]>;
    acceptAGB?: Maybe<Scalars["Boolean"]>;
    _meta?: Maybe<ICaisyDocument_Meta>;
};

export type IProductSmallDeviceServicesArgs = {
    locale?: Maybe<Scalars["String"]>;
};

export type IProductSmallDeviceImagesArgs = {
    locale?: Maybe<Scalars["String"]>;
};

export type IProductSmallDeviceProductDownloadsArgs = {
    locale?: Maybe<Scalars["String"]>;
};

export type IProductSmallDevice_Services = IProductService;

export type IProductSmallDevice_Images = IAsset;

export type IProductSmallDevice_ProductDownloads = IAsset;

export enum IProductSmallDevice_EnergyEfficiencyClass {
    APlusPlusPlus = "APlusPlusPlus",
    APlusPlus = "APlusPlus",
    APlus = "APlus",
    A = "A",
    B = "B",
    C = "C",
    D = "D",
    E = "E",
    F = "F",
    G = "G",
    H = "H",
}

export type IProductSparePart = {
    __typename: "ProductSparePart";
    id?: Maybe<Scalars["ID"]>;
    title?: Maybe<Scalars["String"]>;
    text?: Maybe<Scalars["JSONObject"]>;
    images?: Maybe<Array<Maybe<IProductSparePart_Images>>>;
    services?: Maybe<Array<Maybe<IProductSparePart_Services>>>;
    ourPrice?: Maybe<Scalars["String"]>;
    normalPrice?: Maybe<Scalars["String"]>;
    mehrwertsteuer?: Maybe<Scalars["Float"]>;
    startDate?: Maybe<Scalars["DateTime"]>;
    endDate?: Maybe<Scalars["DateTime"]>;
    acceptAGB?: Maybe<Scalars["Boolean"]>;
    productTechnicalDetails?: Maybe<Scalars["JSONObject"]>;
    productDownloads?: Maybe<Array<Maybe<IProductSparePart_ProductDownloads>>>;
    articelNumber?: Maybe<Scalars["Float"]>;
    productDescription?: Maybe<Scalars["JSONObject"]>;
    hight?: Maybe<Scalars["Float"]>;
    width?: Maybe<Scalars["Float"]>;
    EAN?: Maybe<Scalars["Float"]>;
    depth?: Maybe<Scalars["Float"]>;
    weight?: Maybe<Scalars["Float"]>;
    _meta?: Maybe<ICaisyDocument_Meta>;
};

export type IProductSparePartImagesArgs = {
    locale?: Maybe<Scalars["String"]>;
};

export type IProductSparePartServicesArgs = {
    locale?: Maybe<Scalars["String"]>;
};

export type IProductSparePartProductDownloadsArgs = {
    locale?: Maybe<Scalars["String"]>;
};

export type IProductSparePart_Images = IAsset;

export type IProductSparePart_Services = IProductService;

export type IProductSparePart_ProductDownloads = IAsset;

export type IProductStoveAndOven = {
    __typename: "ProductStoveAndOven";
    id?: Maybe<Scalars["ID"]>;
    title?: Maybe<Scalars["String"]>;
    services?: Maybe<Array<Maybe<IProductStoveAndOven_Services>>>;
    text?: Maybe<Scalars["JSONObject"]>;
    images?: Maybe<Array<Maybe<IProductStoveAndOven_Images>>>;
    normalPrice?: Maybe<Scalars["String"]>;
    ourPrice?: Maybe<Scalars["String"]>;
    mehrwertsteuer?: Maybe<Scalars["Float"]>;
    startDate?: Maybe<Scalars["DateTime"]>;
    endDate?: Maybe<Scalars["DateTime"]>;
    energyConsumptionTopBottomHeat?: Maybe<Scalars["String"]>;
    articelNumber?: Maybe<Scalars["Float"]>;
    productDownloads?: Maybe<Array<Maybe<IProductStoveAndOven_ProductDownloads>>>;
    productDescription?: Maybe<Scalars["JSONObject"]>;
    EAN?: Maybe<Scalars["Float"]>;
    productTechnicalDetails?: Maybe<Scalars["JSONObject"]>;
    width?: Maybe<Scalars["Float"]>;
    hight?: Maybe<Scalars["Float"]>;
    EnergyEfficiencyClass?: Maybe<IProductStoveAndOven_EnergyEfficiencyClass>;
    depth?: Maybe<Scalars["Float"]>;
    EnergyConsumption?: Maybe<Scalars["String"]>;
    BuiltInDevice?: Maybe<Scalars["Boolean"]>;
    Connectivity?: Maybe<Scalars["String"]>;
    UsableVolume?: Maybe<Scalars["String"]>;
    Pyrolysis?: Maybe<Scalars["Boolean"]>;
    weight?: Maybe<Scalars["Float"]>;
    acceptAGB?: Maybe<Scalars["Boolean"]>;
    steamCooker?: Maybe<Scalars["Boolean"]>;
    energyConsumptionHotAir?: Maybe<Scalars["String"]>;
    _meta?: Maybe<ICaisyDocument_Meta>;
};

export type IProductStoveAndOvenServicesArgs = {
    locale?: Maybe<Scalars["String"]>;
};

export type IProductStoveAndOvenImagesArgs = {
    locale?: Maybe<Scalars["String"]>;
};

export type IProductStoveAndOvenProductDownloadsArgs = {
    locale?: Maybe<Scalars["String"]>;
};

export type IProductStoveAndOven_Services = IProductService;

export type IProductStoveAndOven_Images = IAsset;

export type IProductStoveAndOven_ProductDownloads = IAsset;

export enum IProductStoveAndOven_EnergyEfficiencyClass {
    APlusPlusPlus = "APlusPlusPlus",
    APlusPlus = "APlusPlus",
    APlus = "APlus",
    A = "A",
    B = "B",
    C = "C",
    D = "D",
    E = "E",
    F = "F",
    G = "G",
    H = "H",
}

export type IProductVacuumDrawer = {
    __typename: "ProductVacuumDrawer";
    id?: Maybe<Scalars["ID"]>;
    title?: Maybe<Scalars["String"]>;
    services?: Maybe<Array<Maybe<IProductVacuumDrawer_Services>>>;
    images?: Maybe<Array<Maybe<IProductVacuumDrawer_Images>>>;
    text?: Maybe<Scalars["JSONObject"]>;
    normalPrice?: Maybe<Scalars["String"]>;
    ourPrice?: Maybe<Scalars["String"]>;
    mehrwertsteuer?: Maybe<Scalars["Float"]>;
    endDate?: Maybe<Scalars["DateTime"]>;
    startDate?: Maybe<Scalars["DateTime"]>;
    articelNumber?: Maybe<Scalars["Float"]>;
    productDownloads?: Maybe<Array<Maybe<IProductVacuumDrawer_ProductDownloads>>>;
    EnergyEfficiencyClass?: Maybe<IProductVacuumDrawer_EnergyEfficiencyClass>;
    EnergyConsumption?: Maybe<Scalars["String"]>;
    Connectivity?: Maybe<Scalars["String"]>;
    productDescription?: Maybe<Scalars["JSONObject"]>;
    productTechnicalDetails?: Maybe<Scalars["JSONObject"]>;
    acceptAGB?: Maybe<Scalars["Boolean"]>;
    _meta?: Maybe<ICaisyDocument_Meta>;
};

export type IProductVacuumDrawerServicesArgs = {
    locale?: Maybe<Scalars["String"]>;
};

export type IProductVacuumDrawerImagesArgs = {
    locale?: Maybe<Scalars["String"]>;
};

export type IProductVacuumDrawerProductDownloadsArgs = {
    locale?: Maybe<Scalars["String"]>;
};

export type IProductVacuumDrawer_Services = IProductService;

export type IProductVacuumDrawer_Images = IAsset;

export type IProductVacuumDrawer_ProductDownloads = IAsset;

export enum IProductVacuumDrawer_EnergyEfficiencyClass {
    APlusPlusPlus = "APlusPlusPlus",
    APlusPlus = "APlusPlus",
    APlus = "APlus",
    A = "A",
    B = "B",
    C = "C",
    D = "D",
    E = "E",
    F = "F",
    G = "G",
    H = "H",
}

export type IProductWarmingDrawer = {
    __typename: "ProductWarmingDrawer";
    id?: Maybe<Scalars["ID"]>;
    title?: Maybe<Scalars["String"]>;
    images?: Maybe<Array<Maybe<IProductWarmingDrawer_Images>>>;
    text?: Maybe<Scalars["JSONObject"]>;
    services?: Maybe<Array<Maybe<IProductWarmingDrawer_Services>>>;
    ourPrice?: Maybe<Scalars["String"]>;
    normalPrice?: Maybe<Scalars["String"]>;
    mehrwertsteuer?: Maybe<Scalars["Float"]>;
    startDate?: Maybe<Scalars["DateTime"]>;
    endDate?: Maybe<Scalars["DateTime"]>;
    acceptAGB?: Maybe<Scalars["Boolean"]>;
    articelNumber?: Maybe<Scalars["Float"]>;
    productDescription?: Maybe<Scalars["JSONObject"]>;
    productDownloads?: Maybe<Array<Maybe<IProductWarmingDrawer_ProductDownloads>>>;
    EnergyEfficiencyClass?: Maybe<IProductWarmingDrawer_EnergyEfficiencyClass>;
    Connectivity?: Maybe<Scalars["String"]>;
    productTechnicalDetails?: Maybe<Scalars["JSONObject"]>;
    EnergyConsumption?: Maybe<Scalars["String"]>;
    _meta?: Maybe<ICaisyDocument_Meta>;
};

export type IProductWarmingDrawerImagesArgs = {
    locale?: Maybe<Scalars["String"]>;
};

export type IProductWarmingDrawerServicesArgs = {
    locale?: Maybe<Scalars["String"]>;
};

export type IProductWarmingDrawerProductDownloadsArgs = {
    locale?: Maybe<Scalars["String"]>;
};

export type IProductWarmingDrawer_Images = IAsset;

export type IProductWarmingDrawer_Services = IProductService;

export type IProductWarmingDrawer_ProductDownloads = IAsset;

export enum IProductWarmingDrawer_EnergyEfficiencyClass {
    APlusPlusPlus = "APlusPlusPlus",
    APlusPlus = "APlusPlus",
    APlus = "APlus",
    A = "A",
    B = "B",
    C = "C",
    D = "D",
    E = "E",
    F = "F",
    G = "G",
    H = "H",
}

export type IProductWashingMachine = {
    __typename: "ProductWashingMachine";
    id?: Maybe<Scalars["ID"]>;
    title?: Maybe<Scalars["String"]>;
    text?: Maybe<Scalars["JSONObject"]>;
    services?: Maybe<Array<Maybe<IProductWashingMachine_Services>>>;
    images?: Maybe<Array<Maybe<IProductWashingMachine_Images>>>;
    normalPrice?: Maybe<Scalars["String"]>;
    ourPrice?: Maybe<Scalars["String"]>;
    startDate?: Maybe<Scalars["DateTime"]>;
    mehrwertsteuer?: Maybe<Scalars["Float"]>;
    Connectivity?: Maybe<Scalars["String"]>;
    endDate?: Maybe<Scalars["DateTime"]>;
    articelNumber?: Maybe<Scalars["Float"]>;
    productDownloads?: Maybe<Array<Maybe<IProductWashingMachine_ProductDownloads>>>;
    productTechnicalDetails?: Maybe<Scalars["JSONObject"]>;
    hight?: Maybe<Scalars["Float"]>;
    productDescription?: Maybe<Scalars["JSONObject"]>;
    EAN?: Maybe<Scalars["Float"]>;
    depth?: Maybe<Scalars["Float"]>;
    width?: Maybe<Scalars["Float"]>;
    EnergyEfficiencyClass?: Maybe<IProductWashingMachine_EnergyEfficiencyClass>;
    EnergyConsumption?: Maybe<Scalars["String"]>;
    Capacity?: Maybe<Scalars["String"]>;
    ProtectiveDrum?: Maybe<Scalars["String"]>;
    SpinningRate?: Maybe<Scalars["String"]>;
    WaterConsumption?: Maybe<Scalars["String"]>;
    weight?: Maybe<Scalars["Float"]>;
    acceptAGB?: Maybe<Scalars["Boolean"]>;
    BuiltInDevice?: Maybe<Scalars["Boolean"]>;
    _meta?: Maybe<ICaisyDocument_Meta>;
};

export type IProductWashingMachineServicesArgs = {
    locale?: Maybe<Scalars["String"]>;
};

export type IProductWashingMachineImagesArgs = {
    locale?: Maybe<Scalars["String"]>;
};

export type IProductWashingMachineProductDownloadsArgs = {
    locale?: Maybe<Scalars["String"]>;
};

export type IProductWashingMachine_Services = IProductService;

export type IProductWashingMachine_Images = IAsset;

export type IProductWashingMachine_ProductDownloads = IAsset;

export enum IProductWashingMachine_EnergyEfficiencyClass {
    APlusPlusPlus = "APlusPlusPlus",
    APlusPlus = "APlusPlus",
    APlus = "APlus",
    A = "A",
    B = "B",
    C = "C",
    D = "D",
    E = "E",
    F = "F",
    G = "G",
    H = "H",
}

export type IProductWorktop = {
    __typename: "ProductWorktop";
    id?: Maybe<Scalars["ID"]>;
    title?: Maybe<Scalars["String"]>;
    services?: Maybe<Array<Maybe<IProductWorktop_Services>>>;
    normalPrice?: Maybe<Scalars["String"]>;
    text?: Maybe<Scalars["JSONObject"]>;
    images?: Maybe<Array<Maybe<IProductWorktop_Images>>>;
    ourPrice?: Maybe<Scalars["String"]>;
    mehrwertsteuer?: Maybe<Scalars["Float"]>;
    startDate?: Maybe<Scalars["DateTime"]>;
    endDate?: Maybe<Scalars["DateTime"]>;
    articelNumber?: Maybe<Scalars["Float"]>;
    productTechnicalDetails?: Maybe<Scalars["JSONObject"]>;
    hight?: Maybe<Scalars["Float"]>;
    productDownloads?: Maybe<Array<Maybe<IProductWorktop_ProductDownloads>>>;
    productDescription?: Maybe<Scalars["JSONObject"]>;
    width?: Maybe<Scalars["Float"]>;
    depth?: Maybe<Scalars["Float"]>;
    EAN?: Maybe<Scalars["Float"]>;
    weight?: Maybe<Scalars["Float"]>;
    acceptAGB?: Maybe<Scalars["Boolean"]>;
    _meta?: Maybe<ICaisyDocument_Meta>;
};

export type IProductWorktopServicesArgs = {
    locale?: Maybe<Scalars["String"]>;
};

export type IProductWorktopImagesArgs = {
    locale?: Maybe<Scalars["String"]>;
};

export type IProductWorktopProductDownloadsArgs = {
    locale?: Maybe<Scalars["String"]>;
};

export type IProductWorktop_Services = IProductService;

export type IProductWorktop_Images = IAsset;

export type IProductWorktop_ProductDownloads = IAsset;

export type IPageBrandOverview = {
    __typename: "PageBrandOverview";
    id?: Maybe<Scalars["ID"]>;
    elements?: Maybe<Array<Maybe<IPageBrandOverview_Elements>>>;
    twoColumnLayout?: Maybe<Scalars["Boolean"]>;
    imageFixedBackground?: Maybe<Scalars["Boolean"]>;
    nameInNavigation?: Maybe<Scalars["String"]>;
    footnote?: Maybe<Scalars["JSONObject"]>;
    extras?: Maybe<Array<Maybe<IPageBrandOverview_Extras>>>;
    slug?: Maybe<Scalars["String"]>;
    _meta?: Maybe<ICaisyDocument_Meta>;
};

export type IPageBrandOverview_Elements =
    | IAsset
    | IAsset
    | IIcon
    | ICallToAction
    | ITextElement
    | ISlider
    | IThemingColor;

export type IPageBrandOverview_Extras = IMetaInformation | ISidebarText;

export type IContinuingPageGallery = {
    __typename: "ContinuingPageGallery";
    maxCountPerRow?: Maybe<Scalars["Float"]>;
    items?: Maybe<Array<Maybe<IContinuingPageGallery_Items>>>;
    itemRenderer?: any;
    children?: any;
};

export type IContinuingPageGalleryItemsArgs = {
    locale?: Maybe<Scalars["String"]>;
};

export type IContinuingPageGallery_Items = IContinuingPageGalleryItem;

export type IContinuingPageGalleryItem = {
    __typename: "ContinuingPageGalleryItem";
    id?: Maybe<Scalars["ID"]>;
    link?: Maybe<Array<Maybe<IContinuingPageGalleryItem_Link>>>;
    thumbnail?: Maybe<Array<Maybe<IContinuingPageGalleryItem_Thumbnail>>>;
    title?: Maybe<Scalars["String"]>;
    _meta?: Maybe<ICaisyDocument_Meta>;
    text?: Maybe<Scalars["JSONObject"]>;
};

export type IContinuingPageGalleryItemLinkArgs = {
    locale?: Maybe<Scalars["String"]>;
};

export type IContinuingPageGalleryItemThumbnailArgs = {
    locale?: Maybe<Scalars["String"]>;
};

export type IContinuingPageGalleryItem_Link =
    | IProduct
    | IProductAccessoriesAndSupplies
    | IProductAService
    | IProductBathroomFurniture
    | IProductCoffeeMachine
    | IProductConsumerElectronics
    | IProductCooktop
    | IProductDishwasher
    | IProductDryer
    | IProductExtractorHood
    | IProductKitchen
    | IProductKitchenFurniture
    | IProductLighting
    | IProductMicrowaveAndCooker
    | IProductRefrigeratorAndFreezer
    | IProductSinkAndTap
    | IProductSmallDevice
    | IProductSparePart
    | IProductStoveAndOven
    | IProductVacuumDrawer
    | IProductWarmingDrawer
    | IProductWashingMachine
    | IProductWorktop
    | IPage
    | IPageBrand;

export type IContinuingPageGalleryItem_Thumbnail = IAsset;

export type IPage_Extras = IMetaInformation | ISidebarText;

export type ISubNavigationItem_Connection = {
    __typename: "SubNavigationItem_Connection";
    edges?: Maybe<Array<Maybe<ISubNavigationItem_ConnectionEdge>>>;
    pageInfo?: Maybe<IPageInfo>;
    totalCount?: Maybe<Scalars["Int"]>;
};

export type ISubNavigationItem_ConnectionEdge = {
    __typename: "SubNavigationItem_ConnectionEdge";
    node?: Maybe<ISubNavigationItem>;
    cursor?: Maybe<Scalars["String"]>;
};

export type ISubNavigationItem_Sort = {
    link?: Maybe<IOrder>;
};

export type ISubNavigationItem_Where = {
    OR?: Maybe<Array<Maybe<ISubNavigationItem_Where>>>;
    AND?: Maybe<Array<Maybe<ISubNavigationItem_Where>>>;
};

export type IPrimaryNavigation = {
    __typename: "PrimaryNavigation";
    id?: Maybe<Scalars["ID"]>;
    items?: Maybe<Array<Maybe<IPrimaryNavigation_Items>>>;
    _meta?: Maybe<ICaisyDocument_Meta>;
};

export type IPrimaryNavigationItemsArgs = {
    locale?: Maybe<Scalars["String"]>;
};

export type IPrimaryNavigation_Items = INavigationItem;

export type INavigationItem = {
    __typename: "NavigationItem";
    id?: Maybe<Scalars["ID"]>;
    link?: Maybe<Array<Maybe<INavigationItem_Link>>>;
    subitems?: Maybe<Array<Maybe<INavigationItem_Subitems>>>;
    icon?: Maybe<Array<Maybe<INavigationItem_Icon>>>;
    label?: Maybe<Scalars["String"]>;
    _meta?: Maybe<ICaisyDocument_Meta>;
};

export type INavigationItemLinkArgs = {
    locale?: Maybe<Scalars["String"]>;
};

export type INavigationItemSubitemsArgs = {
    locale?: Maybe<Scalars["String"]>;
};

export type INavigationItemIconArgs = {
    locale?: Maybe<Scalars["String"]>;
};

export type INavigationItem_Link =
    | IPage
    | IPageRepairInquiry
    | IPageDateInquiry
    | IPageGuestbook
    | IPageNews
    | IPageImprint
    | IPageContact
    | IPageBrand
    | IPageBrandOverview;

export type INavigationItem_Subitems =
    | IPage
    | IPageRepairInquiry
    | IPageDateInquiry
    | IPageGuestbook
    | IPageNews
    | IPageImprint
    | IPageContact
    | IPageBrand
    | IPageBrandOverview;

export type INavigationItem_Icon = IAsset;

export type IProductService_Connection = {
    __typename: "ProductService_Connection";
    edges?: Maybe<Array<Maybe<IProductService_ConnectionEdge>>>;
    pageInfo?: Maybe<IPageInfo>;
    totalCount?: Maybe<Scalars["Int"]>;
};

export type IProductService_ConnectionEdge = {
    __typename: "ProductService_ConnectionEdge";
    node?: Maybe<IProductService>;
    cursor?: Maybe<Scalars["String"]>;
};

export type IProductService_Sort = {
    title?: Maybe<IOrder>;
    icon?: Maybe<IOrder>;
};

export type IProductService_Where = {
    title?: Maybe<ICaisyField_String_Where>;
    OR?: Maybe<Array<Maybe<IProductService_Where>>>;
    AND?: Maybe<Array<Maybe<IProductService_Where>>>;
};

export type IBrand = {
    __typename: "Brand";
    id?: Maybe<Scalars["ID"]>;
    name?: Maybe<Scalars["String"]>;
    logo?: Maybe<Array<Maybe<IBrand_Logo>>>;
    _meta?: Maybe<ICaisyDocument_Meta>;
};

export type IBrandLogoArgs = {
    locale?: Maybe<Scalars["String"]>;
};

export type IBrand_Logo = IAsset;

export type IBrand_Connection = {
    __typename: "Brand_Connection";
    edges?: Maybe<Array<Maybe<IBrand_ConnectionEdge>>>;
    pageInfo?: Maybe<IPageInfo>;
    totalCount?: Maybe<Scalars["Int"]>;
};

export type IBrand_ConnectionEdge = {
    __typename: "Brand_ConnectionEdge";
    node?: Maybe<IBrand>;
    cursor?: Maybe<Scalars["String"]>;
};

export type IBrand_Sort = {
    name?: Maybe<IOrder>;
    logo?: Maybe<IOrder>;
};

export type IBrand_Where = {
    name?: Maybe<ICaisyField_String_Where>;
    OR?: Maybe<Array<Maybe<IBrand_Where>>>;
    AND?: Maybe<Array<Maybe<IBrand_Where>>>;
};

export type IFooter = {
    __typename: "Footer";
    id?: Maybe<Scalars["ID"]>;
    Navigationspunkte?: Maybe<Array<Maybe<IFooter_Navigationspunkte>>>;
    copyright?: Maybe<Scalars["String"]>;
    _meta?: Maybe<ICaisyDocument_Meta>;
};

export type IFooterNavigationspunkteArgs = {
    locale?: Maybe<Scalars["String"]>;
};

export type IFooter_Navigationspunkte =
    | IPage
    | IPageRepairInquiry
    | IPageDateInquiry
    | IPageGuestbook
    | IPageNews
    | IPageImprint
    | IPageContact
    | IPageBrand
    | IPageBrandOverview;

export type IArticle_Connection = {
    __typename: "Article_Connection";
    edges?: Maybe<Array<Maybe<IArticle_ConnectionEdge>>>;
    pageInfo?: Maybe<IPageInfo>;
    totalCount?: Maybe<Scalars["Int"]>;
};

export type IArticle_ConnectionEdge = {
    __typename: "Article_ConnectionEdge";
    node?: Maybe<IArticle>;
    cursor?: Maybe<Scalars["String"]>;
};

export type IArticle_Sort = {
    headline?: Maybe<IOrder>;
    extras?: Maybe<IOrder>;
    mainText?: Maybe<IOrder>;
};

export type IArticle_Where = {
    headline?: Maybe<ICaisyField_String_Where>;
    mainText?: Maybe<ICaisyField_String_Where>;
    OR?: Maybe<Array<Maybe<IArticle_Where>>>;
    AND?: Maybe<Array<Maybe<IArticle_Where>>>;
};

export type IContinuingPageGalleryItemRoot_Connection = {
    __typename: "ContinuingPageGalleryItemRoot_Connection";
    edges?: Maybe<Array<Maybe<IContinuingPageGalleryItemRoot_ConnectionEdge>>>;
    pageInfo?: Maybe<IPageInfo>;
    totalCount?: Maybe<Scalars["Int"]>;
};

export type IContinuingPageGalleryItemRoot_ConnectionEdge = {
    __typename: "ContinuingPageGalleryItemRoot_ConnectionEdge";
    node?: Maybe<IContinuingPageGalleryItemRoot>;
    cursor?: Maybe<Scalars["String"]>;
};

export type IContinuingPageGalleryItemRoot_Sort = {
    title?: Maybe<IOrder>;
    thumbnail?: Maybe<IOrder>;
    link?: Maybe<IOrder>;
};

export type IContinuingPageGalleryItemRoot_Where = {
    title?: Maybe<ICaisyField_String_Where>;
    OR?: Maybe<Array<Maybe<IContinuingPageGalleryItemRoot_Where>>>;
    AND?: Maybe<Array<Maybe<IContinuingPageGalleryItemRoot_Where>>>;
};

export type IBanner = {
    __typename: "Banner";
    id?: Maybe<Scalars["ID"]>;
    text?: Maybe<Scalars["JSONObject"]>;
    image?: Maybe<Scalars["String"]>;
    linksToPage?: Maybe<Array<Maybe<IBanner_LinksToPage>>>;
    _meta?: Maybe<ICaisyDocument_Meta>;
};

export type IBannerImageArgs = {
    crop?: Maybe<Scalars["String"]>;
};

export type IBannerLinksToPageArgs = {
    locale?: Maybe<Scalars["String"]>;
};

export type IBanner_LinksToPage = IPageBrand;

export type IBanner_Connection = {
    __typename: "Banner_Connection";
    edges?: Maybe<Array<Maybe<IBanner_ConnectionEdge>>>;
    pageInfo?: Maybe<IPageInfo>;
    totalCount?: Maybe<Scalars["Int"]>;
};

export type IBanner_ConnectionEdge = {
    __typename: "Banner_ConnectionEdge";
    node?: Maybe<IBanner>;
    cursor?: Maybe<Scalars["String"]>;
};

export type IBanner_Sort = {
    text?: Maybe<IOrder>;
    image?: Maybe<IOrder>;
    linksToPage?: Maybe<IOrder>;
};

export type IBanner_Where = {
    text?: Maybe<ICaisyField_String_Where>;
    OR?: Maybe<Array<Maybe<IBanner_Where>>>;
    AND?: Maybe<Array<Maybe<IBanner_Where>>>;
};

export type IContinuingPageGalleryItem_Connection = {
    __typename: "ContinuingPageGalleryItem_Connection";
    edges?: Maybe<Array<Maybe<IContinuingPageGalleryItem_ConnectionEdge>>>;
    pageInfo?: Maybe<IPageInfo>;
    totalCount?: Maybe<Scalars["Int"]>;
};

export type IContinuingPageGalleryItem_ConnectionEdge = {
    __typename: "ContinuingPageGalleryItem_ConnectionEdge";
    node?: Maybe<IContinuingPageGalleryItem>;
    cursor?: Maybe<Scalars["String"]>;
};

export type IContinuingPageGalleryItem_Sort = {
    link?: Maybe<IOrder>;
    thumbnail?: Maybe<IOrder>;
    title?: Maybe<IOrder>;
};

export type IContinuingPageGalleryItem_Where = {
    title?: Maybe<ICaisyField_String_Where>;
    OR?: Maybe<Array<Maybe<IContinuingPageGalleryItem_Where>>>;
    AND?: Maybe<Array<Maybe<IContinuingPageGalleryItem_Where>>>;
};

export type IPageMicro = {
    __typename?: "PageMicro";
    id?: Maybe<Scalars["ID"]>;
    nameInNavigation?: Maybe<Scalars["String"]>;
    slug?: Maybe<Scalars["String"]>;
    externalLinkAddress?: Maybe<Scalars["String"]>;
    _meta?: Maybe<ICaisyDocument_Meta>;
};

export type ISettings_Position =
    | IPage
    | IPageRepairInquiry
    | IPageDateInquiry
    | IPageGuestbook
    | IPageNews
    | IPageImprint
    | IPageContact
    | IPageBrand
    | IPageBrandOverview
    | IPageMicro
    | IPageCourse
    | IExternalLink;

export type IPageCourse_Extras = IMetaInformation | ISidebarText;

export type IPageCourse_Elements = IAsset | IAsset | IIcon | ITextElement;

export type ICourse_Extras = IMetaInformation | ISidebarText;

export type ICourse_Elements = IAsset | IAsset | IIcon | ITextElement;

export type ICourse = {
    __typename?: "Course";
    id?: Maybe<Scalars["ID"]>;
    extras?: Maybe<Array<Maybe<ICourse_Extras>>>;
    organizer?: Maybe<Scalars["String"]>;
    maxParticipants?: Maybe<Scalars["Float"]>;
    registrationDeadline?: Maybe<Scalars["DateTime"]>;
    nameInNavigation?: Maybe<Scalars["String"]>;
    startDate?: Maybe<Scalars["DateTime"]>;
    elements?: Maybe<Array<Maybe<ICourse_Elements>>>;
    endDate?: Maybe<Scalars["DateTime"]>;
    pricePerParticipantInEuro?: Maybe<Scalars["Float"]>;
    briefDescription?: any;
    kursNr?: Maybe<Scalars["Float"]>;
    _meta?: Maybe<ICaisyDocument_Meta>;
};

export type IPageCourse_Courses = ICourse;

export type IPageCourse = {
    __typename?: "PageCourse";
    id?: Maybe<Scalars["ID"]>;
    slug?: Maybe<Scalars["String"]>;
    extras?: Maybe<Array<Maybe<IPageCourse_Extras>>>;
    nameInNavigation?: Maybe<Scalars["String"]>;
    elements?: Maybe<Array<Maybe<IPageCourse_Elements>>>;
    courses?: Maybe<Array<Maybe<IPageCourse_Courses>>>;

    imageFixedBackground?: Maybe<Scalars["Boolean"]>;
    footnote?: Maybe<Scalars["JSONObject"]>;
    twoColumnLayout?: Maybe<Scalars["Boolean"]>;
    _meta?: Maybe<ICaisyDocument_Meta>;
};

export type ISettings = {
    __typename?: "Settings";
    id?: Maybe<Scalars["ID"]>;
    themeColorPrimary?: Maybe<Scalars["String"]>;
    themeColorSecondary?: Maybe<Scalars["String"]>;
    themeBackgroundColor?: Maybe<Scalars["String"]>;
    themeTextColor?: Maybe<Scalars["String"]>;
    themeTextColourIsland?: Maybe<Scalars["String"]>;
    themeBackgroundColourIsland?: Maybe<Scalars["String"]>;
    themeContrastColour?: Maybe<Scalars["String"]>;
    template?: Maybe<ISettings_Template>;
    desktopLogoOffsetY?: Maybe<Scalars["Int"]>;
    desktopLogoOffsetX?: Maybe<Scalars["Int"]>;
    desktopLogoSizePercent?: Maybe<Scalars["Int"]>;
    mobileLogoSizePercent?: Maybe<Scalars["Int"]>;
    mobileLogoOffsetX?: Maybe<Scalars["Int"]>;
    mobileLogoOffsetY?: Maybe<Scalars["Int"]>;
    desktopMenuVisualization?: Maybe<Scalars["String"]>;
    mobileMenuVisualization?: Maybe<Scalars["String"]>;
    t14_landing_sorting?: Maybe<Array<Maybe<ISettings_Position>>>;
    t12_positionService?: Maybe<Array<Maybe<ISettings_Position>>>;
    t12_positionInformation?: Maybe<Array<Maybe<ISettings_Position>>>;
    t12_position1?: Maybe<Array<Maybe<ISettings_Position>>>;
    t12_position2?: Maybe<Array<Maybe<ISettings_Position>>>;
    t12_position3?: Maybe<Array<Maybe<ISettings_Position>>>;
    t12_position4?: Maybe<Array<Maybe<ISettings_Position>>>;
    t12_position5?: Maybe<Array<Maybe<ISettings_Position>>>;
    t12_position6?: Maybe<Array<Maybe<ISettings_Position>>>;
    t12_position7?: Maybe<Array<Maybe<ISettings_Position>>>;
    t11_connectionsOnLanding?: Maybe<Array<Maybe<IGenTextLinkItem>>>;
    appointmentRequestDisplay?: string;
    snowOnHomeImage?: boolean;
};

export enum ISettings_Template {
    T0 = "t0",
    T1 = "t1",
    T2 = "t2",
    T3 = "t3",
    T4 = "t4",
    T5 = "t5",
    T6 = "t6",
    T7 = "t7",
    T8 = "t8",
    T9 = "t9",
    T10 = "t10",
    T15 = "t15",
    T16 = "t16",
    T17 = "t17",
    T20 = "t20",
    T22 = "t22",
    T23 = "t23",
    T24 = "t24",
    T26 = "t26",
    T27 = "t27",
}

export type INavigationItem_Connection = {
    __typename: "NavigationItem_Connection";
    edges?: Maybe<Array<Maybe<INavigationItem_ConnectionEdge>>>;
    pageInfo?: Maybe<IPageInfo>;
    totalCount?: Maybe<Scalars["Int"]>;
};

export type INavigationItem_ConnectionEdge = {
    __typename: "NavigationItem_ConnectionEdge";
    node?: Maybe<INavigationItem>;
    cursor?: Maybe<Scalars["String"]>;
};

export type INavigationItem_Sort = {
    link?: Maybe<IOrder>;
    subitems?: Maybe<IOrder>;
    icon?: Maybe<IOrder>;
    label?: Maybe<IOrder>;
};

export type INavigationItem_Where = {
    label?: Maybe<ICaisyField_String_Where>;
    OR?: Maybe<Array<Maybe<INavigationItem_Where>>>;
    AND?: Maybe<Array<Maybe<INavigationItem_Where>>>;
};

export type INews_Connection = {
    __typename: "News_Connection";
    edges?: Maybe<Array<Maybe<INews_ConnectionEdge>>>;
    pageInfo?: Maybe<IPageInfo>;
    totalCount?: Maybe<Scalars["Int"]>;
};

export type INews_ConnectionEdge = {
    __typename: "News_ConnectionEdge";
    node?: Maybe<INews>;
    cursor?: Maybe<Scalars["String"]>;
};

export type INews_Sort = {
    text?: Maybe<IOrder>;
    endDate?: Maybe<IOrder>;
    startDate?: Maybe<IOrder>;
    extras?: Maybe<IOrder>;
    headline?: Maybe<IOrder>;
};

export type INews_Where = {
    text?: Maybe<ICaisyField_String_Where>;
    endDate?: Maybe<ICaisy_Field_DateTime_WhereDate>;
    startDate?: Maybe<ICaisy_Field_DateTime_WhereDateTime>;
    headline?: Maybe<ICaisyField_String_Where>;
    OR?: Maybe<Array<Maybe<INews_Where>>>;
    AND?: Maybe<Array<Maybe<INews_Where>>>;
};

export type ICaisy_Field_DateTime_WhereDate = {
    neq?: Maybe<Scalars["Date"]>;
    eq?: Maybe<Scalars["Date"]>;
    gt?: Maybe<Scalars["Date"]>;
    lt?: Maybe<Scalars["Date"]>;
    gte?: Maybe<Scalars["Date"]>;
    lte?: Maybe<Scalars["Date"]>;
};

export type ICaisy_Field_DateTime_WhereDateTime = {
    neq?: Maybe<Scalars["DateTime"]>;
    eq?: Maybe<Scalars["DateTime"]>;
    gt?: Maybe<Scalars["DateTime"]>;
    lt?: Maybe<Scalars["DateTime"]>;
    gte?: Maybe<Scalars["DateTime"]>;
    lte?: Maybe<Scalars["DateTime"]>;
};

export type ICompanyInformationPrivate = {
    __typename: "CompanyInformationPrivate";
    id?: Maybe<Scalars["ID"]>;
    distributions?: Maybe<Scalars["JSONObject"]>;
    phnummer?: Maybe<Scalars["JSONObject"]>;
    line?: Maybe<ICompanyInformationPrivate_Line>;
    householdApplianceMakerPartner?: Maybe<Scalars["Boolean"]>;
    kitchensSalePartner?: Maybe<Scalars["Boolean"]>;
    showInKitchenCompass?: Maybe<Scalars["Boolean"]>;
    furtherAwards?: Maybe<Array<Maybe<IAsset>>>;
    _meta?: Maybe<ICaisyDocument_Meta>;
};

export enum ICompanyInformationPrivate_Line {
    None = "none",
    Dk = "dk",
    Gelectra = "gelectra",
    Gk = "gk",
    Ihs = "ihs",
    Kg = "kg",
    Kgp = "kgp",
    Kw = "kw",
    Pk = "pk",
}

export type ILandingEvent = {
    __typename: "LandingEvent";
    id?: Maybe<Scalars["ID"]>;
    icon?: Maybe<Array<Maybe<ILandingEvent_Icon>>>;
    linksTo?: Maybe<Array<Maybe<ILandingEvent_LinksTo>>>;
    headline?: Maybe<Scalars["String"]>;
    text?: Maybe<Scalars["JSONObject"]>;
    startDate?: Maybe<Scalars["DateTime"]>;
    endDate?: Maybe<Scalars["DateTime"]>;
    _meta?: Maybe<ICaisyDocument_Meta>;
};

export type ILandingEventIconArgs = {
    locale?: Maybe<Scalars["String"]>;
};

export type ILandingEventLinksToArgs = {
    locale?: Maybe<Scalars["String"]>;
};

export type ILandingEvent_Icon = IAsset;

export type ILandingEvent_LinksTo =
    | IPage
    | IPageRepairInquiry
    | IPageDateInquiry
    | IPageGuestbook
    | IPageNews
    | IPageImprint
    | IPageContact
    | IPageBrand
    | IPageBrandOverview;

export type ILandingEvent_Connection = {
    __typename: "LandingEvent_Connection";
    edges?: Maybe<Array<Maybe<ILandingEvent_ConnectionEdge>>>;
    pageInfo?: Maybe<IPageInfo>;
    totalCount?: Maybe<Scalars["Int"]>;
};

export type ILandingEvent_ConnectionEdge = {
    __typename: "LandingEvent_ConnectionEdge";
    node?: Maybe<ILandingEvent>;
    cursor?: Maybe<Scalars["String"]>;
};

export type ILandingEvent_Sort = {
    icon?: Maybe<IOrder>;
    linksTo?: Maybe<IOrder>;
    headline?: Maybe<IOrder>;
    text?: Maybe<IOrder>;
    startDate?: Maybe<IOrder>;
    endDate?: Maybe<IOrder>;
};

export type ILandingEvent_Where = {
    headline?: Maybe<ICaisyField_String_Where>;
    text?: Maybe<ICaisyField_String_Where>;
    startDate?: Maybe<ICaisy_Field_DateTime_WhereDateTime>;
    endDate?: Maybe<ICaisy_Field_DateTime_WhereDateTime>;
    OR?: Maybe<Array<Maybe<ILandingEvent_Where>>>;
    AND?: Maybe<Array<Maybe<ILandingEvent_Where>>>;
};

export type IGuestbookEntry = {
    __typename: "GuestbookEntry";
    id?: Maybe<Scalars["ID"]>;
    lastname?: Maybe<Scalars["String"]>;
    Message?: Maybe<Scalars["JSONObject"]>;
    title?: Maybe<Scalars["String"]>;
    firstname?: Maybe<Scalars["String"]>;
    date?: Maybe<Scalars["Date"]>;
    EMail?: Maybe<Scalars["String"]>;
    commentOfDealer?: Maybe<Scalars["JSONObject"]>;
    Evaluation?: Maybe<Scalars["Float"]>;
    _meta?: Maybe<ICaisyDocument_Meta>;
};

export type IGuestbookEntry_Connection = {
    __typename: "GuestbookEntry_Connection";
    edges?: Maybe<Array<Maybe<IGuestbookEntry_ConnectionEdge>>>;
    pageInfo?: Maybe<IPageInfo>;
    totalCount?: Maybe<Scalars["Int"]>;
};

export type IGuestbookEntry_ConnectionEdge = {
    __typename: "GuestbookEntry_ConnectionEdge";
    node?: Maybe<IGuestbookEntry>;
    cursor?: Maybe<Scalars["String"]>;
};

export type IGuestbookEntry_Sort = {
    lastname?: Maybe<IOrder>;
    Message?: Maybe<IOrder>;
    title?: Maybe<IOrder>;
    firstname?: Maybe<IOrder>;
    EMail?: Maybe<IOrder>;
    commentOfDealer?: Maybe<IOrder>;
    Evaluation?: Maybe<IOrder>;
};

export type IGuestbookEntry_Where = {
    lastname?: Maybe<ICaisyField_String_Where>;
    Message?: Maybe<ICaisyField_String_Where>;
    title?: Maybe<ICaisyField_String_Where>;
    firstname?: Maybe<ICaisyField_String_Where>;
    EMail?: Maybe<ICaisyField_String_Where>;
    commentOfDealer?: Maybe<ICaisyField_String_Where>;
    Evaluation?: Maybe<ICaisyField_Number_WhereFloat>;
    OR?: Maybe<Array<Maybe<IGuestbookEntry_Where>>>;
    AND?: Maybe<Array<Maybe<IGuestbookEntry_Where>>>;
};

export type ICaisyField_Number_WhereFloat = {
    neq?: Maybe<Scalars["Float"]>;
    eq?: Maybe<Scalars["Float"]>;
    gt?: Maybe<Scalars["Float"]>;
    lt?: Maybe<Scalars["Float"]>;
    gte?: Maybe<Scalars["Float"]>;
    lte?: Maybe<Scalars["Float"]>;
};

export type IPageLanding = {
    __typename: "PageLanding";
    id?: Maybe<Scalars["ID"]>;
    promotionalOffer?: Maybe<Array<Maybe<IPageLanding_PromotionalOffer>>>;
    extras?: Maybe<Array<Maybe<IPageLanding_Extras>>>;
    elements?: Maybe<Array<Maybe<IPageLanding_Elements>>>;
    imageFixedBackground?: Maybe<Scalars["Boolean"]>;
    events?: Maybe<Array<Maybe<IPageLanding_Events>>>;
    footnote?: Maybe<Scalars["JSONObject"]>;
    twoColumnLayout?: Maybe<Scalars["Boolean"]>;
    _meta?: Maybe<ICaisyDocument_Meta>;
};

export type IPageLandingPromotionalOfferArgs = {
    locale?: Maybe<Scalars["String"]>;
};

export type IPageLandingEventsArgs = {
    locale?: Maybe<Scalars["String"]>;
};

export type IPageLanding_PromotionalOffer =
    | IProduct
    | IProductAccessoriesAndSupplies
    | IProductAService
    | IProductBathroomFurniture
    | IProductCoffeeMachine
    | IProductConsumerElectronics
    | IProductCooktop
    | IProductDishwasher
    | IProductDryer
    | IProductExtractorHood
    | IProductKitchen
    | IProductKitchenFurniture
    | IProductLighting
    | IProductMicrowaveAndCooker
    | IProductRefrigeratorAndFreezer
    | IProductSinkAndTap
    | IProductSmallDevice
    | IProductSparePart
    | IProductStoveAndOven
    | IProductVacuumDrawer
    | IProductWarmingDrawer
    | IProductWashingMachine
    | IProductWorktop;

export type IPageLanding_Extras = IMetaInformation | ISidebarText;

export type IPageLanding_Elements =
    | IAsset
    | IIcon
    | ICallToAction
    | ITextElement
    | ISlider
    | IThemingColor
    | IRichLinkItem
    | IAsset;

export type IRichLinkItem = {
    __typename: "RichLinkItem";
    linkTo?: Maybe<Array<Maybe<IRichLinkItem_LinkTo>>>;
    headline?: Maybe<Scalars["String"]>;
    image?: Maybe<Array<Maybe<IRichLinkItem_Image>>>;
    richDesc?: Maybe<Scalars["JSONObject"]>;
};

export type IRichLinkItemLinkToArgs = {
    locale?: Maybe<Scalars["String"]>;
};

export type IRichLinkItemImageArgs = {
    locale?: Maybe<Scalars["String"]>;
};

export type IRichLinkItem_LinkTo =
    | IPage
    | IPageRepairInquiry
    | IPageDateInquiry
    | IPageGuestbook
    | IPageNews
    | IPageImprint
    | IPageContact
    | IPageBrand
    | IPageBrandOverview;

export type IRichLinkItem_Image = IAsset;

export type IPageLanding_Events = ILandingEvent;

export type IPage_Connection = {
    __typename: "Page_Connection";
    edges?: Maybe<Array<Maybe<IPage_ConnectionEdge>>>;
    pageInfo?: Maybe<IPageInfo>;
    totalCount?: Maybe<Scalars["Int"]>;
};

export type IPage_ConnectionEdge = {
    __typename: "Page_ConnectionEdge";
    node?: Maybe<IPage>;
    cursor?: Maybe<Scalars["String"]>;
};

export type IPage_Sort = {
    elements?: Maybe<IOrder>;
    imageFixedBackground?: Maybe<IOrder>;
    footnote?: Maybe<IOrder>;
    extras?: Maybe<IOrder>;
    twoColumnLayout?: Maybe<IOrder>;
    nameInNavigation?: Maybe<IOrder>;
    slug?: Maybe<IOrder>;
};

export type IPage_Where = {
    imageFixedBackground?: Maybe<Scalars["Boolean"]>;
    footnote?: Maybe<ICaisyField_String_Where>;
    twoColumnLayout?: Maybe<Scalars["Boolean"]>;
    nameInNavigation?: Maybe<ICaisyField_String_Where>;
    slug?: Maybe<ICaisyField_String_Where>;
    OR?: Maybe<Array<Maybe<IPage_Where>>>;
    AND?: Maybe<Array<Maybe<IPage_Where>>>;
};

export type IPageBrand_Connection = {
    __typename: "PageBrand_Connection";
    edges?: Maybe<Array<Maybe<IPageBrand_ConnectionEdge>>>;
    pageInfo?: Maybe<IPageInfo>;
    totalCount?: Maybe<Scalars["Int"]>;
};

export type IPageBrand_ConnectionEdge = {
    __typename: "PageBrand_ConnectionEdge";
    node?: Maybe<IPageBrand>;
    cursor?: Maybe<Scalars["String"]>;
};

export type IPageBrand_Sort = {
    imageFixedBackground?: Maybe<IOrder>;
    footnote?: Maybe<IOrder>;
    extras?: Maybe<IOrder>;
    connectedArticles?: Maybe<IOrder>;
    twoColumnLayout?: Maybe<IOrder>;
    slug?: Maybe<IOrder>;
    nameInNavigation?: Maybe<IOrder>;
    elements?: Maybe<IOrder>;
};

export type IPageBrand_Where = {
    imageFixedBackground?: Maybe<Scalars["Boolean"]>;
    footnote?: Maybe<ICaisyField_String_Where>;
    twoColumnLayout?: Maybe<Scalars["Boolean"]>;
    slug?: Maybe<ICaisyField_String_Where>;
    nameInNavigation?: Maybe<ICaisyField_String_Where>;
    OR?: Maybe<Array<Maybe<IPageBrand_Where>>>;
    AND?: Maybe<Array<Maybe<IPageBrand_Where>>>;
};

export type IAsset_Connection = {
    __typename: "Asset_Connection";
    edges?: Maybe<Array<Maybe<IAsset_ConnectionEdge>>>;
    pageInfo?: Maybe<IPageInfo>;
    totalCount?: Maybe<Scalars["Int"]>;
};

export type IAsset_ConnectionEdge = {
    __typename: "Asset_ConnectionEdge";
    node?: Maybe<IAsset>;
    cursor?: Maybe<Scalars["String"]>;
};

export type IAsset_Sort = {
    dominantColor?: Maybe<IOrder>;
    author?: Maybe<IOrder>;
    copyright?: Maybe<IOrder>;
    originType?: Maybe<IOrder>;
    originalName?: Maybe<IOrder>;
    keywords?: Maybe<IOrder>;
    title?: Maybe<IOrder>;
    description?: Maybe<IOrder>;
    src?: Maybe<IOrder>;
};

export type IAsset_Where = {
    dominantColor?: Maybe<ICaisyField_String_Where>;
    author?: Maybe<ICaisyField_String_Where>;
    copyright?: Maybe<ICaisyField_String_Where>;
    originType?: Maybe<ICaisyField_String_Where>;
    originalName?: Maybe<ICaisyField_String_Where>;
    keywords?: Maybe<ICaisyField_String_Where>;
    title?: Maybe<ICaisyField_String_Where>;
    description?: Maybe<ICaisyField_String_Where>;
    OR?: Maybe<Array<Maybe<IAsset_Where>>>;
    AND?: Maybe<Array<Maybe<IAsset_Where>>>;
};

export type IProductAService_Connection = {
    __typename: "ProductAService_Connection";
    edges?: Maybe<Array<Maybe<IProductAService_ConnectionEdge>>>;
    pageInfo?: Maybe<IPageInfo>;
    totalCount?: Maybe<Scalars["Int"]>;
};

export type IProductAService_ConnectionEdge = {
    __typename: "ProductAService_ConnectionEdge";
    node?: Maybe<IProductAService>;
    cursor?: Maybe<Scalars["String"]>;
};

export type IProductAService_Sort = {
    title?: Maybe<IOrder>;
    normalPrice?: Maybe<IOrder>;
    images?: Maybe<IOrder>;
    services?: Maybe<IOrder>;
    text?: Maybe<IOrder>;
    ourPrice?: Maybe<IOrder>;
    mehrwertsteuer?: Maybe<IOrder>;
    endDate?: Maybe<IOrder>;
    startDate?: Maybe<IOrder>;
    articelNumber?: Maybe<IOrder>;
    productDescription?: Maybe<IOrder>;
    productTechnicalDetails?: Maybe<IOrder>;
    productDownloads?: Maybe<IOrder>;
    acceptAGB?: Maybe<IOrder>;
};

export type IProductAService_Where = {
    title?: Maybe<ICaisyField_String_Where>;
    normalPrice?: Maybe<ICaisyField_String_Where>;
    text?: Maybe<ICaisyField_String_Where>;
    ourPrice?: Maybe<ICaisyField_String_Where>;
    mehrwertsteuer?: Maybe<ICaisyField_Number_WhereFloat>;
    endDate?: Maybe<ICaisy_Field_DateTime_WhereDateTime>;
    startDate?: Maybe<ICaisy_Field_DateTime_WhereDateTime>;
    articelNumber?: Maybe<ICaisyField_Number_WhereFloat>;
    productDescription?: Maybe<ICaisyField_String_Where>;
    productTechnicalDetails?: Maybe<ICaisyField_String_Where>;
    acceptAGB?: Maybe<Scalars["Boolean"]>;
    OR?: Maybe<Array<Maybe<IProductAService_Where>>>;
    AND?: Maybe<Array<Maybe<IProductAService_Where>>>;
};

export type IProductKitchenFurniture_Connection = {
    __typename: "ProductKitchenFurniture_Connection";
    edges?: Maybe<Array<Maybe<IProductKitchenFurniture_ConnectionEdge>>>;
    pageInfo?: Maybe<IPageInfo>;
    totalCount?: Maybe<Scalars["Int"]>;
};

export type IProductKitchenFurniture_ConnectionEdge = {
    __typename: "ProductKitchenFurniture_ConnectionEdge";
    node?: Maybe<IProductKitchenFurniture>;
    cursor?: Maybe<Scalars["String"]>;
};

export type IProductKitchenFurniture_Sort = {
    title?: Maybe<IOrder>;
    images?: Maybe<IOrder>;
    ourPrice?: Maybe<IOrder>;
    normalPrice?: Maybe<IOrder>;
    services?: Maybe<IOrder>;
    text?: Maybe<IOrder>;
    mehrwertsteuer?: Maybe<IOrder>;
    startDate?: Maybe<IOrder>;
    endDate?: Maybe<IOrder>;
    acceptAGB?: Maybe<IOrder>;
    articelNumber?: Maybe<IOrder>;
    productDescription?: Maybe<IOrder>;
    productTechnicalDetails?: Maybe<IOrder>;
    productDownloads?: Maybe<IOrder>;
};

export type IProductKitchenFurniture_Where = {
    title?: Maybe<ICaisyField_String_Where>;
    ourPrice?: Maybe<ICaisyField_String_Where>;
    normalPrice?: Maybe<ICaisyField_String_Where>;
    text?: Maybe<ICaisyField_String_Where>;
    mehrwertsteuer?: Maybe<ICaisyField_Number_WhereFloat>;
    startDate?: Maybe<ICaisy_Field_DateTime_WhereDateTime>;
    endDate?: Maybe<ICaisy_Field_DateTime_WhereDateTime>;
    acceptAGB?: Maybe<Scalars["Boolean"]>;
    articelNumber?: Maybe<ICaisyField_Number_WhereFloat>;
    productDescription?: Maybe<ICaisyField_String_Where>;
    productTechnicalDetails?: Maybe<ICaisyField_String_Where>;
    OR?: Maybe<Array<Maybe<IProductKitchenFurniture_Where>>>;
    AND?: Maybe<Array<Maybe<IProductKitchenFurniture_Where>>>;
};

export type IProductKitchen_Connection = {
    __typename: "ProductKitchen_Connection";
    edges?: Maybe<Array<Maybe<IProductKitchen_ConnectionEdge>>>;
    pageInfo?: Maybe<IPageInfo>;
    totalCount?: Maybe<Scalars["Int"]>;
};

export type IProductKitchen_ConnectionEdge = {
    __typename: "ProductKitchen_ConnectionEdge";
    node?: Maybe<IProductKitchen>;
    cursor?: Maybe<Scalars["String"]>;
};

export type IProductKitchen_Sort = {
    title?: Maybe<IOrder>;
    images?: Maybe<IOrder>;
    text?: Maybe<IOrder>;
    services?: Maybe<IOrder>;
    normalPrice?: Maybe<IOrder>;
    ourPrice?: Maybe<IOrder>;
    mehrwertsteuer?: Maybe<IOrder>;
    startDate?: Maybe<IOrder>;
    endDate?: Maybe<IOrder>;
    productDownloads?: Maybe<IOrder>;
    articelNumber?: Maybe<IOrder>;
    productDescription?: Maybe<IOrder>;
    productTechnicalDetails?: Maybe<IOrder>;
    acceptAGB?: Maybe<IOrder>;
};

export type IProductKitchen_Where = {
    title?: Maybe<ICaisyField_String_Where>;
    text?: Maybe<ICaisyField_String_Where>;
    normalPrice?: Maybe<ICaisyField_String_Where>;
    ourPrice?: Maybe<ICaisyField_String_Where>;
    mehrwertsteuer?: Maybe<ICaisyField_Number_WhereFloat>;
    startDate?: Maybe<ICaisy_Field_DateTime_WhereDateTime>;
    endDate?: Maybe<ICaisy_Field_DateTime_WhereDateTime>;
    articelNumber?: Maybe<ICaisyField_Number_WhereFloat>;
    productDescription?: Maybe<ICaisyField_String_Where>;
    productTechnicalDetails?: Maybe<ICaisyField_String_Where>;
    acceptAGB?: Maybe<Scalars["Boolean"]>;
    OR?: Maybe<Array<Maybe<IProductKitchen_Where>>>;
    AND?: Maybe<Array<Maybe<IProductKitchen_Where>>>;
};

export type IProduct_Connection = {
    __typename: "Product_Connection";
    edges?: Maybe<Array<Maybe<IProduct_ConnectionEdge>>>;
    pageInfo?: Maybe<IPageInfo>;
    totalCount?: Maybe<Scalars["Int"]>;
};

export type IProduct_ConnectionEdge = {
    __typename: "Product_ConnectionEdge";
    node?: Maybe<IProduct>;
    cursor?: Maybe<Scalars["String"]>;
};

export type IProduct_Sort = {
    title?: Maybe<IOrder>;
    services?: Maybe<IOrder>;
    images?: Maybe<IOrder>;
    text?: Maybe<IOrder>;
    normalPrice?: Maybe<IOrder>;
    ourPrice?: Maybe<IOrder>;
    mehrwertsteuer?: Maybe<IOrder>;
    startDate?: Maybe<IOrder>;
    endDate?: Maybe<IOrder>;
    articelNumber?: Maybe<IOrder>;
    productDownloads?: Maybe<IOrder>;
    EAN?: Maybe<IOrder>;
    productTechnicalDetails?: Maybe<IOrder>;
    productDescription?: Maybe<IOrder>;
    acceptAGB?: Maybe<IOrder>;
};

export type IProduct_Where = {
    title?: Maybe<ICaisyField_String_Where>;
    text?: Maybe<ICaisyField_String_Where>;
    normalPrice?: Maybe<ICaisyField_String_Where>;
    ourPrice?: Maybe<ICaisyField_String_Where>;
    mehrwertsteuer?: Maybe<ICaisyField_Number_WhereFloat>;
    startDate?: Maybe<ICaisy_Field_DateTime_WhereDateTime>;
    endDate?: Maybe<ICaisy_Field_DateTime_WhereDateTime>;
    articelNumber?: Maybe<ICaisyField_Number_WhereFloat>;
    EAN?: Maybe<ICaisyField_Number_WhereFloat>;
    productTechnicalDetails?: Maybe<ICaisyField_String_Where>;
    productDescription?: Maybe<ICaisyField_String_Where>;
    acceptAGB?: Maybe<Scalars["Boolean"]>;
    OR?: Maybe<Array<Maybe<IProduct_Where>>>;
    AND?: Maybe<Array<Maybe<IProduct_Where>>>;
};

export type IProductVacuumDrawer_Connection = {
    __typename: "ProductVacuumDrawer_Connection";
    edges?: Maybe<Array<Maybe<IProductVacuumDrawer_ConnectionEdge>>>;
    pageInfo?: Maybe<IPageInfo>;
    totalCount?: Maybe<Scalars["Int"]>;
};

export type IProductVacuumDrawer_ConnectionEdge = {
    __typename: "ProductVacuumDrawer_ConnectionEdge";
    node?: Maybe<IProductVacuumDrawer>;
    cursor?: Maybe<Scalars["String"]>;
};

export type IProductVacuumDrawer_Sort = {
    title?: Maybe<IOrder>;
    services?: Maybe<IOrder>;
    images?: Maybe<IOrder>;
    text?: Maybe<IOrder>;
    normalPrice?: Maybe<IOrder>;
    ourPrice?: Maybe<IOrder>;
    mehrwertsteuer?: Maybe<IOrder>;
    endDate?: Maybe<IOrder>;
    startDate?: Maybe<IOrder>;
    articelNumber?: Maybe<IOrder>;
    productDownloads?: Maybe<IOrder>;
    EnergyEfficiencyClass?: Maybe<IOrder>;
    EnergyConsumption?: Maybe<IOrder>;
    Connectivity?: Maybe<IOrder>;
    productDescription?: Maybe<IOrder>;
    productTechnicalDetails?: Maybe<IOrder>;
    acceptAGB?: Maybe<IOrder>;
};

export type IProductVacuumDrawer_Where = {
    title?: Maybe<ICaisyField_String_Where>;
    text?: Maybe<ICaisyField_String_Where>;
    normalPrice?: Maybe<ICaisyField_String_Where>;
    ourPrice?: Maybe<ICaisyField_String_Where>;
    mehrwertsteuer?: Maybe<ICaisyField_Number_WhereFloat>;
    endDate?: Maybe<ICaisy_Field_DateTime_WhereDateTime>;
    startDate?: Maybe<ICaisy_Field_DateTime_WhereDateTime>;
    articelNumber?: Maybe<ICaisyField_Number_WhereFloat>;
    EnergyEfficiencyClass?: Maybe<IProductVacuumDrawer_EnergyEfficiencyClass_Where>;
    EnergyConsumption?: Maybe<ICaisyField_String_Where>;
    Connectivity?: Maybe<ICaisyField_String_Where>;
    productDescription?: Maybe<ICaisyField_String_Where>;
    productTechnicalDetails?: Maybe<ICaisyField_String_Where>;
    acceptAGB?: Maybe<Scalars["Boolean"]>;
    OR?: Maybe<Array<Maybe<IProductVacuumDrawer_Where>>>;
    AND?: Maybe<Array<Maybe<IProductVacuumDrawer_Where>>>;
};

export type IProductVacuumDrawer_EnergyEfficiencyClass_Where = {
    eq?: Maybe<IProductVacuumDrawer_EnergyEfficiencyClass>;
};

export type IProductWarmingDrawer_Connection = {
    __typename: "ProductWarmingDrawer_Connection";
    edges?: Maybe<Array<Maybe<IProductWarmingDrawer_ConnectionEdge>>>;
    pageInfo?: Maybe<IPageInfo>;
    totalCount?: Maybe<Scalars["Int"]>;
};

export type IProductWarmingDrawer_ConnectionEdge = {
    __typename: "ProductWarmingDrawer_ConnectionEdge";
    node?: Maybe<IProductWarmingDrawer>;
    cursor?: Maybe<Scalars["String"]>;
};

export type IProductWarmingDrawer_Sort = {
    title?: Maybe<IOrder>;
    images?: Maybe<IOrder>;
    text?: Maybe<IOrder>;
    services?: Maybe<IOrder>;
    ourPrice?: Maybe<IOrder>;
    normalPrice?: Maybe<IOrder>;
    mehrwertsteuer?: Maybe<IOrder>;
    startDate?: Maybe<IOrder>;
    endDate?: Maybe<IOrder>;
    acceptAGB?: Maybe<IOrder>;
    articelNumber?: Maybe<IOrder>;
    productDescription?: Maybe<IOrder>;
    productDownloads?: Maybe<IOrder>;
    EnergyEfficiencyClass?: Maybe<IOrder>;
    Connectivity?: Maybe<IOrder>;
    productTechnicalDetails?: Maybe<IOrder>;
    EnergyConsumption?: Maybe<IOrder>;
};

export type IProductWarmingDrawer_Where = {
    title?: Maybe<ICaisyField_String_Where>;
    text?: Maybe<ICaisyField_String_Where>;
    ourPrice?: Maybe<ICaisyField_String_Where>;
    normalPrice?: Maybe<ICaisyField_String_Where>;
    mehrwertsteuer?: Maybe<ICaisyField_Number_WhereFloat>;
    startDate?: Maybe<ICaisy_Field_DateTime_WhereDateTime>;
    endDate?: Maybe<ICaisy_Field_DateTime_WhereDateTime>;
    acceptAGB?: Maybe<Scalars["Boolean"]>;
    articelNumber?: Maybe<ICaisyField_Number_WhereFloat>;
    productDescription?: Maybe<ICaisyField_String_Where>;
    EnergyEfficiencyClass?: Maybe<IProductWarmingDrawer_EnergyEfficiencyClass_Where>;
    Connectivity?: Maybe<ICaisyField_String_Where>;
    productTechnicalDetails?: Maybe<ICaisyField_String_Where>;
    EnergyConsumption?: Maybe<ICaisyField_String_Where>;
    OR?: Maybe<Array<Maybe<IProductWarmingDrawer_Where>>>;
    AND?: Maybe<Array<Maybe<IProductWarmingDrawer_Where>>>;
};

export type IProductWarmingDrawer_EnergyEfficiencyClass_Where = {
    eq?: Maybe<IProductWarmingDrawer_EnergyEfficiencyClass>;
};

export type IProductSinkAndTap_Connection = {
    __typename: "ProductSinkAndTap_Connection";
    edges?: Maybe<Array<Maybe<IProductSinkAndTap_ConnectionEdge>>>;
    pageInfo?: Maybe<IPageInfo>;
    totalCount?: Maybe<Scalars["Int"]>;
};

export type IProductSinkAndTap_ConnectionEdge = {
    __typename: "ProductSinkAndTap_ConnectionEdge";
    node?: Maybe<IProductSinkAndTap>;
    cursor?: Maybe<Scalars["String"]>;
};

export type IProductSinkAndTap_Sort = {
    title?: Maybe<IOrder>;
    text?: Maybe<IOrder>;
    images?: Maybe<IOrder>;
    services?: Maybe<IOrder>;
    normalPrice?: Maybe<IOrder>;
    ourPrice?: Maybe<IOrder>;
    color?: Maybe<IOrder>;
    startDate?: Maybe<IOrder>;
    endDate?: Maybe<IOrder>;
    acceptAGB?: Maybe<IOrder>;
    mehrwertsteuer?: Maybe<IOrder>;
    productDownloads?: Maybe<IOrder>;
    articelNumber?: Maybe<IOrder>;
    Type?: Maybe<IOrder>;
    productDescription?: Maybe<IOrder>;
    Martial?: Maybe<IOrder>;
    productTechnicalDetails?: Maybe<IOrder>;
};

export type IProductSinkAndTap_Where = {
    title?: Maybe<ICaisyField_String_Where>;
    text?: Maybe<ICaisyField_String_Where>;
    normalPrice?: Maybe<ICaisyField_String_Where>;
    ourPrice?: Maybe<ICaisyField_String_Where>;
    color?: Maybe<ICaisyField_String_Where>;
    startDate?: Maybe<ICaisy_Field_DateTime_WhereDateTime>;
    endDate?: Maybe<ICaisy_Field_DateTime_WhereDateTime>;
    acceptAGB?: Maybe<Scalars["Boolean"]>;
    mehrwertsteuer?: Maybe<ICaisyField_Number_WhereFloat>;
    articelNumber?: Maybe<ICaisyField_Number_WhereFloat>;
    Type?: Maybe<ICaisyField_String_Where>;
    productDescription?: Maybe<ICaisyField_String_Where>;
    Martial?: Maybe<ICaisyField_String_Where>;
    productTechnicalDetails?: Maybe<ICaisyField_String_Where>;
    OR?: Maybe<Array<Maybe<IProductSinkAndTap_Where>>>;
    AND?: Maybe<Array<Maybe<IProductSinkAndTap_Where>>>;
};

export type IProductBathroomFurniture_Connection = {
    __typename: "ProductBathroomFurniture_Connection";
    edges?: Maybe<Array<Maybe<IProductBathroomFurniture_ConnectionEdge>>>;
    pageInfo?: Maybe<IPageInfo>;
    totalCount?: Maybe<Scalars["Int"]>;
};

export type IProductBathroomFurniture_ConnectionEdge = {
    __typename: "ProductBathroomFurniture_ConnectionEdge";
    node?: Maybe<IProductBathroomFurniture>;
    cursor?: Maybe<Scalars["String"]>;
};

export type IProductBathroomFurniture_Sort = {
    title?: Maybe<IOrder>;
    images?: Maybe<IOrder>;
    text?: Maybe<IOrder>;
    services?: Maybe<IOrder>;
    ourPrice?: Maybe<IOrder>;
    normalPrice?: Maybe<IOrder>;
    mehrwertsteuer?: Maybe<IOrder>;
    startDate?: Maybe<IOrder>;
    color?: Maybe<IOrder>;
    endDate?: Maybe<IOrder>;
    articelNumber?: Maybe<IOrder>;
    productDescription?: Maybe<IOrder>;
    productTechnicalDetails?: Maybe<IOrder>;
    width?: Maybe<IOrder>;
    productDownloads?: Maybe<IOrder>;
    depth?: Maybe<IOrder>;
    hight?: Maybe<IOrder>;
    acceptAGB?: Maybe<IOrder>;
};

export type IProductBathroomFurniture_Where = {
    title?: Maybe<ICaisyField_String_Where>;
    text?: Maybe<ICaisyField_String_Where>;
    ourPrice?: Maybe<ICaisyField_String_Where>;
    normalPrice?: Maybe<ICaisyField_String_Where>;
    mehrwertsteuer?: Maybe<ICaisyField_Number_WhereFloat>;
    startDate?: Maybe<ICaisy_Field_DateTime_WhereDateTime>;
    color?: Maybe<ICaisyField_String_Where>;
    endDate?: Maybe<ICaisy_Field_DateTime_WhereDateTime>;
    articelNumber?: Maybe<ICaisyField_Number_WhereFloat>;
    productDescription?: Maybe<ICaisyField_String_Where>;
    productTechnicalDetails?: Maybe<ICaisyField_String_Where>;
    width?: Maybe<ICaisyField_Number_WhereFloat>;
    depth?: Maybe<ICaisyField_Number_WhereFloat>;
    hight?: Maybe<ICaisyField_Number_WhereFloat>;
    acceptAGB?: Maybe<Scalars["Boolean"]>;
    OR?: Maybe<Array<Maybe<IProductBathroomFurniture_Where>>>;
    AND?: Maybe<Array<Maybe<IProductBathroomFurniture_Where>>>;
};

export type IProductAccessoriesAndSupplies_Connection = {
    __typename: "ProductAccessoriesAndSupplies_Connection";
    edges?: Maybe<Array<Maybe<IProductAccessoriesAndSupplies_ConnectionEdge>>>;
    pageInfo?: Maybe<IPageInfo>;
    totalCount?: Maybe<Scalars["Int"]>;
};

export type IProductAccessoriesAndSupplies_ConnectionEdge = {
    __typename: "ProductAccessoriesAndSupplies_ConnectionEdge";
    node?: Maybe<IProductAccessoriesAndSupplies>;
    cursor?: Maybe<Scalars["String"]>;
};

export type IProductAccessoriesAndSupplies_Sort = {
    title?: Maybe<IOrder>;
    services?: Maybe<IOrder>;
    images?: Maybe<IOrder>;
    ourPrice?: Maybe<IOrder>;
    normalPrice?: Maybe<IOrder>;
    text?: Maybe<IOrder>;
    mehrwertsteuer?: Maybe<IOrder>;
    startDate?: Maybe<IOrder>;
    endDate?: Maybe<IOrder>;
    productDescription?: Maybe<IOrder>;
    articelNumber?: Maybe<IOrder>;
    productTechnicalDetails?: Maybe<IOrder>;
    productDownloads?: Maybe<IOrder>;
    hight?: Maybe<IOrder>;
    width?: Maybe<IOrder>;
    EAN?: Maybe<IOrder>;
    depth?: Maybe<IOrder>;
    acceptAGB?: Maybe<IOrder>;
    weight?: Maybe<IOrder>;
};

export type IProductAccessoriesAndSupplies_Where = {
    title?: Maybe<ICaisyField_String_Where>;
    ourPrice?: Maybe<ICaisyField_String_Where>;
    normalPrice?: Maybe<ICaisyField_String_Where>;
    text?: Maybe<ICaisyField_String_Where>;
    mehrwertsteuer?: Maybe<ICaisyField_Number_WhereFloat>;
    startDate?: Maybe<ICaisy_Field_DateTime_WhereDateTime>;
    endDate?: Maybe<ICaisy_Field_DateTime_WhereDateTime>;
    productDescription?: Maybe<ICaisyField_String_Where>;
    articelNumber?: Maybe<ICaisyField_Number_WhereFloat>;
    productTechnicalDetails?: Maybe<ICaisyField_String_Where>;
    hight?: Maybe<ICaisyField_Number_WhereFloat>;
    width?: Maybe<ICaisyField_Number_WhereFloat>;
    EAN?: Maybe<ICaisyField_Number_WhereFloat>;
    depth?: Maybe<ICaisyField_Number_WhereFloat>;
    acceptAGB?: Maybe<Scalars["Boolean"]>;
    weight?: Maybe<ICaisyField_Number_WhereFloat>;
    OR?: Maybe<Array<Maybe<IProductAccessoriesAndSupplies_Where>>>;
    AND?: Maybe<Array<Maybe<IProductAccessoriesAndSupplies_Where>>>;
};

export type IProductSparePart_Connection = {
    __typename: "ProductSparePart_Connection";
    edges?: Maybe<Array<Maybe<IProductSparePart_ConnectionEdge>>>;
    pageInfo?: Maybe<IPageInfo>;
    totalCount?: Maybe<Scalars["Int"]>;
};

export type IProductSparePart_ConnectionEdge = {
    __typename: "ProductSparePart_ConnectionEdge";
    node?: Maybe<IProductSparePart>;
    cursor?: Maybe<Scalars["String"]>;
};

export type IProductSparePart_Sort = {
    title?: Maybe<IOrder>;
    text?: Maybe<IOrder>;
    images?: Maybe<IOrder>;
    services?: Maybe<IOrder>;
    ourPrice?: Maybe<IOrder>;
    normalPrice?: Maybe<IOrder>;
    mehrwertsteuer?: Maybe<IOrder>;
    startDate?: Maybe<IOrder>;
    endDate?: Maybe<IOrder>;
    acceptAGB?: Maybe<IOrder>;
    productTechnicalDetails?: Maybe<IOrder>;
    productDownloads?: Maybe<IOrder>;
    articelNumber?: Maybe<IOrder>;
    productDescription?: Maybe<IOrder>;
    hight?: Maybe<IOrder>;
    width?: Maybe<IOrder>;
    EAN?: Maybe<IOrder>;
    depth?: Maybe<IOrder>;
    weight?: Maybe<IOrder>;
};

export type IProductSparePart_Where = {
    title?: Maybe<ICaisyField_String_Where>;
    text?: Maybe<ICaisyField_String_Where>;
    ourPrice?: Maybe<ICaisyField_String_Where>;
    normalPrice?: Maybe<ICaisyField_String_Where>;
    mehrwertsteuer?: Maybe<ICaisyField_Number_WhereFloat>;
    startDate?: Maybe<ICaisy_Field_DateTime_WhereDateTime>;
    endDate?: Maybe<ICaisy_Field_DateTime_WhereDateTime>;
    acceptAGB?: Maybe<Scalars["Boolean"]>;
    productTechnicalDetails?: Maybe<ICaisyField_String_Where>;
    articelNumber?: Maybe<ICaisyField_Number_WhereFloat>;
    productDescription?: Maybe<ICaisyField_String_Where>;
    hight?: Maybe<ICaisyField_Number_WhereFloat>;
    width?: Maybe<ICaisyField_Number_WhereFloat>;
    EAN?: Maybe<ICaisyField_Number_WhereFloat>;
    depth?: Maybe<ICaisyField_Number_WhereFloat>;
    weight?: Maybe<ICaisyField_Number_WhereFloat>;
    OR?: Maybe<Array<Maybe<IProductSparePart_Where>>>;
    AND?: Maybe<Array<Maybe<IProductSparePart_Where>>>;
};

export type IProductWorktop_Connection = {
    __typename: "ProductWorktop_Connection";
    edges?: Maybe<Array<Maybe<IProductWorktop_ConnectionEdge>>>;
    pageInfo?: Maybe<IPageInfo>;
    totalCount?: Maybe<Scalars["Int"]>;
};

export type IProductWorktop_ConnectionEdge = {
    __typename: "ProductWorktop_ConnectionEdge";
    node?: Maybe<IProductWorktop>;
    cursor?: Maybe<Scalars["String"]>;
};

export type IProductWorktop_Sort = {
    title?: Maybe<IOrder>;
    services?: Maybe<IOrder>;
    normalPrice?: Maybe<IOrder>;
    text?: Maybe<IOrder>;
    images?: Maybe<IOrder>;
    ourPrice?: Maybe<IOrder>;
    mehrwertsteuer?: Maybe<IOrder>;
    startDate?: Maybe<IOrder>;
    endDate?: Maybe<IOrder>;
    articelNumber?: Maybe<IOrder>;
    productTechnicalDetails?: Maybe<IOrder>;
    hight?: Maybe<IOrder>;
    productDownloads?: Maybe<IOrder>;
    productDescription?: Maybe<IOrder>;
    width?: Maybe<IOrder>;
    depth?: Maybe<IOrder>;
    EAN?: Maybe<IOrder>;
    weight?: Maybe<IOrder>;
    acceptAGB?: Maybe<IOrder>;
};

export type IProductWorktop_Where = {
    title?: Maybe<ICaisyField_String_Where>;
    normalPrice?: Maybe<ICaisyField_String_Where>;
    text?: Maybe<ICaisyField_String_Where>;
    ourPrice?: Maybe<ICaisyField_String_Where>;
    mehrwertsteuer?: Maybe<ICaisyField_Number_WhereFloat>;
    startDate?: Maybe<ICaisy_Field_DateTime_WhereDateTime>;
    endDate?: Maybe<ICaisy_Field_DateTime_WhereDateTime>;
    articelNumber?: Maybe<ICaisyField_Number_WhereFloat>;
    productTechnicalDetails?: Maybe<ICaisyField_String_Where>;
    hight?: Maybe<ICaisyField_Number_WhereFloat>;
    productDescription?: Maybe<ICaisyField_String_Where>;
    width?: Maybe<ICaisyField_Number_WhereFloat>;
    depth?: Maybe<ICaisyField_Number_WhereFloat>;
    EAN?: Maybe<ICaisyField_Number_WhereFloat>;
    weight?: Maybe<ICaisyField_Number_WhereFloat>;
    acceptAGB?: Maybe<Scalars["Boolean"]>;
    OR?: Maybe<Array<Maybe<IProductWorktop_Where>>>;
    AND?: Maybe<Array<Maybe<IProductWorktop_Where>>>;
};

export type ICompanyInformationPublic = {
    __typename: "CompanyInformationPublic";
    id?: Maybe<Scalars["ID"]>;
    commercialRegister?: Maybe<Scalars["String"]>;
    companyFormOfLaw?: Maybe<ICompanyInformationPublic_CompanyFormOfLaw>;
    companyName?: Maybe<Scalars["String"]>;
    companyNameOverwriteContact?: Maybe<Scalars["String"]>;
    companyNameOverwriteImprint?: Maybe<Scalars["String"]>;
    postcode?: Maybe<Scalars["String"]>;
    logo?: Maybe<Array<Maybe<ICompanyInformationPublic_Logo>>>;
    favicon?: Maybe<ICompanyInformationPublic_Logo>;
    country?: Maybe<Scalars["String"]>;
    managingDirector?: Maybe<Scalars["String"]>;
    street?: Maybe<Scalars["String"]>;
    openingHours?: Maybe<Scalars["JSONObject"]>;
    gmbOpeningHours?: Maybe<Scalars["JSONObject"]>;
    imprintAddendum?: Maybe<Scalars["JSONObject"]>;
    persHaftendeGesName?: Maybe<Scalars["String"]>;
    persHaftendeGesSitz?: Maybe<Scalars["String"]>;
    persHaftendeGesHandelsregister?: Maybe<Scalars["String"]>;
    place?: Maybe<Scalars["String"]>;
    taxID?: Maybe<Scalars["String"]>;
    phone?: Maybe<Scalars["String"]>;
    companyForm?: Maybe<Scalars["String"]>;
    telephoneHours?: Maybe<Scalars["String"]>;
    email?: Maybe<Scalars["String"]>;
    fax?: Maybe<Scalars["String"]>;
    facebook?: Maybe<Scalars["String"]>;
    twitter?: Maybe<Scalars["String"]>;
    pintrest?: Maybe<Scalars["String"]>;
    youtube?: Maybe<Scalars["String"]>;
    instagram?: Maybe<Scalars["String"]>;
    tiktok?: Maybe<Scalars["String"]>;
    whatsapp?: Maybe<Scalars["String"]>;
    serviceNumber?: Maybe<Scalars["String"]>;
    _meta?: Maybe<ICaisyDocument_Meta>;
};

export type ICompanyInformationPublicLogoArgs = {
    locale?: Maybe<Scalars["String"]>;
};

export enum ICompanyInformationPublic_CompanyFormOfLaw {
    Einzelunternehmer = "EINZELUNTERNEHMER",
    Gbr = "GBR",
    Gmbh = "GMBH",
    Einzelkaufmann = "EINZELKAUFMANN",
    Ohg = "OHG",
    Kg = "KG",
    Ug = "UG",
    Ag = "AG",
    MbH = "mbH",
    Gmbhcokg = "GMBHCOKG",
    Ltd = "LTD",
    Og = "OG",
    Verein = "VEREIN",
    Sl = "SL",
}

export type ICompanyInformationPublic_Logo = IAsset;

export type IProductConsumerElectronics_Connection = {
    __typename: "ProductConsumerElectronics_Connection";
    edges?: Maybe<Array<Maybe<IProductConsumerElectronics_ConnectionEdge>>>;
    pageInfo?: Maybe<IPageInfo>;
    totalCount?: Maybe<Scalars["Int"]>;
};

export type IProductConsumerElectronics_ConnectionEdge = {
    __typename: "ProductConsumerElectronics_ConnectionEdge";
    node?: Maybe<IProductConsumerElectronics>;
    cursor?: Maybe<Scalars["String"]>;
};

export type IProductConsumerElectronics_Sort = {
    title?: Maybe<IOrder>;
    text?: Maybe<IOrder>;
    images?: Maybe<IOrder>;
    services?: Maybe<IOrder>;
    normalPrice?: Maybe<IOrder>;
    ourPrice?: Maybe<IOrder>;
    mehrwertsteuer?: Maybe<IOrder>;
    startDate?: Maybe<IOrder>;
    endDate?: Maybe<IOrder>;
    articelNumber?: Maybe<IOrder>;
    productTechnicalDetails?: Maybe<IOrder>;
    productDescription?: Maybe<IOrder>;
    EAN?: Maybe<IOrder>;
    hight?: Maybe<IOrder>;
    productDownloads?: Maybe<IOrder>;
    EnergyConsumption?: Maybe<IOrder>;
    depth?: Maybe<IOrder>;
    EnergyEfficiencyClass?: Maybe<IOrder>;
    width?: Maybe<IOrder>;
    acceptAGB?: Maybe<IOrder>;
    weight?: Maybe<IOrder>;
};

export type IProductConsumerElectronics_Where = {
    title?: Maybe<ICaisyField_String_Where>;
    text?: Maybe<ICaisyField_String_Where>;
    normalPrice?: Maybe<ICaisyField_String_Where>;
    ourPrice?: Maybe<ICaisyField_String_Where>;
    mehrwertsteuer?: Maybe<ICaisyField_Number_WhereFloat>;
    startDate?: Maybe<ICaisy_Field_DateTime_WhereDateTime>;
    endDate?: Maybe<ICaisy_Field_DateTime_WhereDateTime>;
    articelNumber?: Maybe<ICaisyField_Number_WhereFloat>;
    productTechnicalDetails?: Maybe<ICaisyField_String_Where>;
    productDescription?: Maybe<ICaisyField_String_Where>;
    EAN?: Maybe<ICaisyField_Number_WhereFloat>;
    hight?: Maybe<ICaisyField_Number_WhereFloat>;
    EnergyConsumption?: Maybe<ICaisyField_String_Where>;
    depth?: Maybe<ICaisyField_Number_WhereFloat>;
    EnergyEfficiencyClass?: Maybe<IProductConsumerElectronics_EnergyEfficiencyClass_Where>;
    width?: Maybe<ICaisyField_Number_WhereFloat>;
    acceptAGB?: Maybe<Scalars["Boolean"]>;
    weight?: Maybe<ICaisyField_Number_WhereFloat>;
    OR?: Maybe<Array<Maybe<IProductConsumerElectronics_Where>>>;
    AND?: Maybe<Array<Maybe<IProductConsumerElectronics_Where>>>;
};

export type IProductConsumerElectronics_EnergyEfficiencyClass_Where = {
    eq?: Maybe<IProductConsumerElectronics_EnergyEfficiencyClass>;
};

export type IUniversalTexts = {
    __typename: "UniversalTexts";
    id?: Maybe<Scalars["ID"]>;
    StartAddress?: Maybe<Scalars["String"]>;
    CalculateRoute?: Maybe<Scalars["String"]>;
    message?: Maybe<Scalars["String"]>;
    eMail?: Maybe<Scalars["String"]>;
    IAcceptTheDataPrivacyStatement?: Maybe<Scalars["String"]>;
    yourLocation?: Maybe<Scalars["String"]>;
    planningDateHeadline?: Maybe<Scalars["String"]>;
    Submit?: Maybe<Scalars["String"]>;
    captchaSpamProtection?: Maybe<Scalars["String"]>;
    name?: Maybe<Scalars["String"]>;
    contactForm?: Maybe<Scalars["String"]>;
    guestbook__firstname?: Maybe<Scalars["JSONObject"]>;
    guestbook_writeContribution?: Maybe<Scalars["JSONObject"]>;
    guestbook__cancel?: Maybe<Scalars["JSONObject"]>;
    guestbook__send?: Maybe<Scalars["JSONObject"]>;
    gestbook__evaluation?: Maybe<Scalars["JSONObject"]>;
    guestbook__email?: Maybe<Scalars["JSONObject"]>;
    guestbook__preview?: Maybe<Scalars["JSONObject"]>;
    guestbook__lastname?: Maybe<Scalars["JSONObject"]>;
    guestbook__title?: Maybe<Scalars["JSONObject"]>;
    guestbook__message?: Maybe<Scalars["JSONObject"]>;
    gestbook__back?: Maybe<Scalars["JSONObject"]>;
    guestbook__thanks?: Maybe<Scalars["JSONObject"]>;
    brandOverviewFormIntro?: Maybe<Scalars["String"]>;
    _meta?: Maybe<ICaisyDocument_Meta>;
};

export type IProductSmallDevice_Connection = {
    __typename: "ProductSmallDevice_Connection";
    edges?: Maybe<Array<Maybe<IProductSmallDevice_ConnectionEdge>>>;
    pageInfo?: Maybe<IPageInfo>;
    totalCount?: Maybe<Scalars["Int"]>;
};

export type IProductSmallDevice_ConnectionEdge = {
    __typename: "ProductSmallDevice_ConnectionEdge";
    node?: Maybe<IProductSmallDevice>;
    cursor?: Maybe<Scalars["String"]>;
};

export type IProductSmallDevice_Sort = {
    ourPrice?: Maybe<IOrder>;
    normalPrice?: Maybe<IOrder>;
    services?: Maybe<IOrder>;
    images?: Maybe<IOrder>;
    text?: Maybe<IOrder>;
    title?: Maybe<IOrder>;
    mehrwertsteuer?: Maybe<IOrder>;
    endDate?: Maybe<IOrder>;
    startDate?: Maybe<IOrder>;
    articelNumber?: Maybe<IOrder>;
    productDescription?: Maybe<IOrder>;
    productDownloads?: Maybe<IOrder>;
    productTechnicalDetails?: Maybe<IOrder>;
    hight?: Maybe<IOrder>;
    EAN?: Maybe<IOrder>;
    width?: Maybe<IOrder>;
    EnergyEfficiencyClass?: Maybe<IOrder>;
    depth?: Maybe<IOrder>;
    Connectivity?: Maybe<IOrder>;
    Power?: Maybe<IOrder>;
    Category?: Maybe<IOrder>;
    acceptAGB?: Maybe<IOrder>;
};

export type IProductSmallDevice_Where = {
    ourPrice?: Maybe<ICaisyField_String_Where>;
    normalPrice?: Maybe<ICaisyField_String_Where>;
    text?: Maybe<ICaisyField_String_Where>;
    title?: Maybe<ICaisyField_String_Where>;
    mehrwertsteuer?: Maybe<ICaisyField_Number_WhereFloat>;
    endDate?: Maybe<ICaisy_Field_DateTime_WhereDateTime>;
    startDate?: Maybe<ICaisy_Field_DateTime_WhereDateTime>;
    articelNumber?: Maybe<ICaisyField_Number_WhereFloat>;
    productDescription?: Maybe<ICaisyField_String_Where>;
    productTechnicalDetails?: Maybe<ICaisyField_String_Where>;
    hight?: Maybe<ICaisyField_Number_WhereFloat>;
    EAN?: Maybe<ICaisyField_Number_WhereFloat>;
    width?: Maybe<ICaisyField_Number_WhereFloat>;
    EnergyEfficiencyClass?: Maybe<IProductSmallDevice_EnergyEfficiencyClass_Where>;
    depth?: Maybe<ICaisyField_Number_WhereFloat>;
    Connectivity?: Maybe<ICaisyField_String_Where>;
    Power?: Maybe<ICaisyField_String_Where>;
    Category?: Maybe<ICaisyField_String_Where>;
    acceptAGB?: Maybe<Scalars["Boolean"]>;
    OR?: Maybe<Array<Maybe<IProductSmallDevice_Where>>>;
    AND?: Maybe<Array<Maybe<IProductSmallDevice_Where>>>;
};

export type IProductSmallDevice_EnergyEfficiencyClass_Where = {
    eq?: Maybe<IProductSmallDevice_EnergyEfficiencyClass>;
};

export type IProductLighting_Connection = {
    __typename: "ProductLighting_Connection";
    edges?: Maybe<Array<Maybe<IProductLighting_ConnectionEdge>>>;
    pageInfo?: Maybe<IPageInfo>;
    totalCount?: Maybe<Scalars["Int"]>;
};

export type IProductLighting_ConnectionEdge = {
    __typename: "ProductLighting_ConnectionEdge";
    node?: Maybe<IProductLighting>;
    cursor?: Maybe<Scalars["String"]>;
};

export type IProductLighting_Sort = {
    title?: Maybe<IOrder>;
    images?: Maybe<IOrder>;
    text?: Maybe<IOrder>;
    services?: Maybe<IOrder>;
    normalPrice?: Maybe<IOrder>;
    ourPrice?: Maybe<IOrder>;
    mehrwertsteuer?: Maybe<IOrder>;
    startDate?: Maybe<IOrder>;
    endDate?: Maybe<IOrder>;
    Power?: Maybe<IOrder>;
    Connectivity?: Maybe<IOrder>;
    articelNumber?: Maybe<IOrder>;
    productTechnicalDetails?: Maybe<IOrder>;
    productDownloads?: Maybe<IOrder>;
    EAN?: Maybe<IOrder>;
    productDescription?: Maybe<IOrder>;
    hight?: Maybe<IOrder>;
    width?: Maybe<IOrder>;
    depth?: Maybe<IOrder>;
    EnergyEfficiencyClass?: Maybe<IOrder>;
    EnergyConsumption?: Maybe<IOrder>;
    acceptAGB?: Maybe<IOrder>;
};

export type IProductLighting_Where = {
    title?: Maybe<ICaisyField_String_Where>;
    text?: Maybe<ICaisyField_String_Where>;
    normalPrice?: Maybe<ICaisyField_String_Where>;
    ourPrice?: Maybe<ICaisyField_String_Where>;
    mehrwertsteuer?: Maybe<ICaisyField_Number_WhereFloat>;
    startDate?: Maybe<ICaisy_Field_DateTime_WhereDateTime>;
    endDate?: Maybe<ICaisy_Field_DateTime_WhereDateTime>;
    Power?: Maybe<ICaisyField_String_Where>;
    Connectivity?: Maybe<ICaisyField_String_Where>;
    articelNumber?: Maybe<ICaisyField_Number_WhereFloat>;
    productTechnicalDetails?: Maybe<ICaisyField_String_Where>;
    EAN?: Maybe<ICaisyField_Number_WhereFloat>;
    productDescription?: Maybe<ICaisyField_String_Where>;
    hight?: Maybe<ICaisyField_Number_WhereFloat>;
    width?: Maybe<ICaisyField_Number_WhereFloat>;
    depth?: Maybe<ICaisyField_Number_WhereFloat>;
    EnergyEfficiencyClass?: Maybe<IProductLighting_EnergyEfficiencyClass_Where>;
    EnergyConsumption?: Maybe<ICaisyField_String_Where>;
    acceptAGB?: Maybe<Scalars["Boolean"]>;
    OR?: Maybe<Array<Maybe<IProductLighting_Where>>>;
    AND?: Maybe<Array<Maybe<IProductLighting_Where>>>;
};

export type IProductLighting_EnergyEfficiencyClass_Where = {
    eq?: Maybe<IProductLighting_EnergyEfficiencyClass>;
};

export type IProductCooktop_Connection = {
    __typename: "ProductCooktop_Connection";
    edges?: Maybe<Array<Maybe<IProductCooktop_ConnectionEdge>>>;
    pageInfo?: Maybe<IPageInfo>;
    totalCount?: Maybe<Scalars["Int"]>;
};

export type IProductCooktop_ConnectionEdge = {
    __typename: "ProductCooktop_ConnectionEdge";
    node?: Maybe<IProductCooktop>;
    cursor?: Maybe<Scalars["String"]>;
};

export type IProductCooktop_Sort = {
    services?: Maybe<IOrder>;
    text?: Maybe<IOrder>;
    title?: Maybe<IOrder>;
    images?: Maybe<IOrder>;
    articelNumber?: Maybe<IOrder>;
    normalPrice?: Maybe<IOrder>;
    ourPrice?: Maybe<IOrder>;
    mehrwertsteuer?: Maybe<IOrder>;
    startDate?: Maybe<IOrder>;
    endDate?: Maybe<IOrder>;
    productTechnicalDetails?: Maybe<IOrder>;
    productDescription?: Maybe<IOrder>;
    EAN?: Maybe<IOrder>;
    productDownloads?: Maybe<IOrder>;
    hight?: Maybe<IOrder>;
    width?: Maybe<IOrder>;
    EnergyEfficiencyClass?: Maybe<IOrder>;
    depth?: Maybe<IOrder>;
    EnergyConsumption?: Maybe<IOrder>;
    Connectivity?: Maybe<IOrder>;
    InductionType?: Maybe<IOrder>;
    weight?: Maybe<IOrder>;
    acceptAGB?: Maybe<IOrder>;
};

export type IProductCooktop_Where = {
    text?: Maybe<ICaisyField_String_Where>;
    title?: Maybe<ICaisyField_String_Where>;
    articelNumber?: Maybe<ICaisyField_Number_WhereFloat>;
    normalPrice?: Maybe<ICaisyField_String_Where>;
    ourPrice?: Maybe<ICaisyField_String_Where>;
    mehrwertsteuer?: Maybe<ICaisyField_Number_WhereFloat>;
    startDate?: Maybe<ICaisy_Field_DateTime_WhereDateTime>;
    endDate?: Maybe<ICaisy_Field_DateTime_WhereDateTime>;
    productTechnicalDetails?: Maybe<ICaisyField_String_Where>;
    productDescription?: Maybe<ICaisyField_String_Where>;
    EAN?: Maybe<ICaisyField_Number_WhereFloat>;
    hight?: Maybe<ICaisyField_Number_WhereFloat>;
    width?: Maybe<ICaisyField_Number_WhereFloat>;
    EnergyEfficiencyClass?: Maybe<IProductCooktop_EnergyEfficiencyClass_Where>;
    depth?: Maybe<ICaisyField_Number_WhereFloat>;
    EnergyConsumption?: Maybe<ICaisyField_String_Where>;
    Connectivity?: Maybe<ICaisyField_String_Where>;
    InductionType?: Maybe<ICaisyField_String_Where>;
    weight?: Maybe<ICaisyField_Number_WhereFloat>;
    acceptAGB?: Maybe<Scalars["Boolean"]>;
    OR?: Maybe<Array<Maybe<IProductCooktop_Where>>>;
    AND?: Maybe<Array<Maybe<IProductCooktop_Where>>>;
};

export type IProductCooktop_EnergyEfficiencyClass_Where = {
    eq?: Maybe<IProductCooktop_EnergyEfficiencyClass>;
};

export type IProductCoffeeMachine_Connection = {
    __typename: "ProductCoffeeMachine_Connection";
    edges?: Maybe<Array<Maybe<IProductCoffeeMachine_ConnectionEdge>>>;
    pageInfo?: Maybe<IPageInfo>;
    totalCount?: Maybe<Scalars["Int"]>;
};

export type IProductCoffeeMachine_ConnectionEdge = {
    __typename: "ProductCoffeeMachine_ConnectionEdge";
    node?: Maybe<IProductCoffeeMachine>;
    cursor?: Maybe<Scalars["String"]>;
};

export type IProductCoffeeMachine_Sort = {
    title?: Maybe<IOrder>;
    images?: Maybe<IOrder>;
    ourPrice?: Maybe<IOrder>;
    normalPrice?: Maybe<IOrder>;
    services?: Maybe<IOrder>;
    text?: Maybe<IOrder>;
    mehrwertsteuer?: Maybe<IOrder>;
    startDate?: Maybe<IOrder>;
    endDate?: Maybe<IOrder>;
    acceptAGB?: Maybe<IOrder>;
    Technology?: Maybe<IOrder>;
    articelNumber?: Maybe<IOrder>;
    productDownloads?: Maybe<IOrder>;
    hight?: Maybe<IOrder>;
    EAN?: Maybe<IOrder>;
    width?: Maybe<IOrder>;
    EnergyEfficiencyClass?: Maybe<IOrder>;
    depth?: Maybe<IOrder>;
    EnergyConsumption?: Maybe<IOrder>;
    BuiltInDevice?: Maybe<IOrder>;
    productDescription?: Maybe<IOrder>;
    productTechnicalDetails?: Maybe<IOrder>;
    weight?: Maybe<IOrder>;
    FullyAutomaticCoffeeMachine?: Maybe<IOrder>;
};

export type IProductCoffeeMachine_Where = {
    title?: Maybe<ICaisyField_String_Where>;
    ourPrice?: Maybe<ICaisyField_String_Where>;
    normalPrice?: Maybe<ICaisyField_String_Where>;
    text?: Maybe<ICaisyField_String_Where>;
    mehrwertsteuer?: Maybe<ICaisyField_Number_WhereFloat>;
    startDate?: Maybe<ICaisy_Field_DateTime_WhereDateTime>;
    endDate?: Maybe<ICaisy_Field_DateTime_WhereDateTime>;
    acceptAGB?: Maybe<Scalars["Boolean"]>;
    Technology?: Maybe<IProductCoffeeMachine_Technology_Where>;
    articelNumber?: Maybe<ICaisyField_Number_WhereFloat>;
    hight?: Maybe<ICaisyField_Number_WhereFloat>;
    EAN?: Maybe<ICaisyField_Number_WhereFloat>;
    width?: Maybe<ICaisyField_Number_WhereFloat>;
    EnergyEfficiencyClass?: Maybe<IProductCoffeeMachine_EnergyEfficiencyClass_Where>;
    depth?: Maybe<ICaisyField_Number_WhereFloat>;
    EnergyConsumption?: Maybe<ICaisyField_String_Where>;
    BuiltInDevice?: Maybe<Scalars["Boolean"]>;
    productDescription?: Maybe<ICaisyField_String_Where>;
    productTechnicalDetails?: Maybe<ICaisyField_String_Where>;
    weight?: Maybe<ICaisyField_Number_WhereFloat>;
    FullyAutomaticCoffeeMachine?: Maybe<Scalars["Boolean"]>;
    OR?: Maybe<Array<Maybe<IProductCoffeeMachine_Where>>>;
    AND?: Maybe<Array<Maybe<IProductCoffeeMachine_Where>>>;
};

export type IProductCoffeeMachine_Technology_Where = {
    eq?: Maybe<IProductCoffeeMachine_Technology>;
};

export type IProductCoffeeMachine_EnergyEfficiencyClass_Where = {
    eq?: Maybe<IProductCoffeeMachine_EnergyEfficiencyClass>;
};

export type IProductMicrowaveAndCooker_Connection = {
    __typename: "ProductMicrowaveAndCooker_Connection";
    edges?: Maybe<Array<Maybe<IProductMicrowaveAndCooker_ConnectionEdge>>>;
    pageInfo?: Maybe<IPageInfo>;
    totalCount?: Maybe<Scalars["Int"]>;
};

export type IProductMicrowaveAndCooker_ConnectionEdge = {
    __typename: "ProductMicrowaveAndCooker_ConnectionEdge";
    node?: Maybe<IProductMicrowaveAndCooker>;
    cursor?: Maybe<Scalars["String"]>;
};

export type IProductMicrowaveAndCooker_Sort = {
    text?: Maybe<IOrder>;
    services?: Maybe<IOrder>;
    images?: Maybe<IOrder>;
    normalPrice?: Maybe<IOrder>;
    ourPrice?: Maybe<IOrder>;
    title?: Maybe<IOrder>;
    mehrwertsteuer?: Maybe<IOrder>;
    endDate?: Maybe<IOrder>;
    startDate?: Maybe<IOrder>;
    articelNumber?: Maybe<IOrder>;
    productTechnicalDetails?: Maybe<IOrder>;
    productDescription?: Maybe<IOrder>;
    hight?: Maybe<IOrder>;
    productDownloads?: Maybe<IOrder>;
    EAN?: Maybe<IOrder>;
    width?: Maybe<IOrder>;
    EnergyConsumption?: Maybe<IOrder>;
    steamCooker?: Maybe<IOrder>;
    depth?: Maybe<IOrder>;
    EnergyEfficiencyClass?: Maybe<IOrder>;
    Connectivity?: Maybe<IOrder>;
    CookingChamberUsableVolume?: Maybe<IOrder>;
    weight?: Maybe<IOrder>;
    acceptAGB?: Maybe<IOrder>;
    BuiltInDevice?: Maybe<IOrder>;
};

export type IProductMicrowaveAndCooker_Where = {
    text?: Maybe<ICaisyField_String_Where>;
    normalPrice?: Maybe<ICaisyField_String_Where>;
    ourPrice?: Maybe<ICaisyField_String_Where>;
    title?: Maybe<ICaisyField_String_Where>;
    mehrwertsteuer?: Maybe<ICaisyField_Number_WhereFloat>;
    endDate?: Maybe<ICaisy_Field_DateTime_WhereDateTime>;
    startDate?: Maybe<ICaisy_Field_DateTime_WhereDateTime>;
    articelNumber?: Maybe<ICaisyField_Number_WhereFloat>;
    productTechnicalDetails?: Maybe<ICaisyField_String_Where>;
    productDescription?: Maybe<ICaisyField_String_Where>;
    hight?: Maybe<ICaisyField_Number_WhereFloat>;
    EAN?: Maybe<ICaisyField_Number_WhereFloat>;
    width?: Maybe<ICaisyField_Number_WhereFloat>;
    EnergyConsumption?: Maybe<ICaisyField_String_Where>;
    steamCooker?: Maybe<Scalars["Boolean"]>;
    depth?: Maybe<ICaisyField_Number_WhereFloat>;
    EnergyEfficiencyClass?: Maybe<IProductMicrowaveAndCooker_EnergyEfficiencyClass_Where>;
    Connectivity?: Maybe<ICaisyField_String_Where>;
    CookingChamberUsableVolume?: Maybe<ICaisyField_String_Where>;
    weight?: Maybe<ICaisyField_Number_WhereFloat>;
    acceptAGB?: Maybe<Scalars["Boolean"]>;
    BuiltInDevice?: Maybe<Scalars["Boolean"]>;
    OR?: Maybe<Array<Maybe<IProductMicrowaveAndCooker_Where>>>;
    AND?: Maybe<Array<Maybe<IProductMicrowaveAndCooker_Where>>>;
};

export type IProductMicrowaveAndCooker_EnergyEfficiencyClass_Where = {
    eq?: Maybe<IProductMicrowaveAndCooker_EnergyEfficiencyClass>;
};

export type IProductDryer_Connection = {
    __typename: "ProductDryer_Connection";
    edges?: Maybe<Array<Maybe<IProductDryer_ConnectionEdge>>>;
    pageInfo?: Maybe<IPageInfo>;
    totalCount?: Maybe<Scalars["Int"]>;
};

export type IProductDryer_ConnectionEdge = {
    __typename: "ProductDryer_ConnectionEdge";
    node?: Maybe<IProductDryer>;
    cursor?: Maybe<Scalars["String"]>;
};

export type IProductDryer_Sort = {
    title?: Maybe<IOrder>;
    services?: Maybe<IOrder>;
    text?: Maybe<IOrder>;
    ourPrice?: Maybe<IOrder>;
    images?: Maybe<IOrder>;
    normalPrice?: Maybe<IOrder>;
    startDate?: Maybe<IOrder>;
    mehrwertsteuer?: Maybe<IOrder>;
    endDate?: Maybe<IOrder>;
    articelNumber?: Maybe<IOrder>;
    productTechnicalDetails?: Maybe<IOrder>;
    productDescription?: Maybe<IOrder>;
    productDownloads?: Maybe<IOrder>;
    EnergyEfficiencyClass?: Maybe<IOrder>;
    EAN?: Maybe<IOrder>;
    hight?: Maybe<IOrder>;
    width?: Maybe<IOrder>;
    EnergyConsumption?: Maybe<IOrder>;
    depth?: Maybe<IOrder>;
    DryingEfficiencyClass?: Maybe<IOrder>;
    CondensationOrHeatPumpDryer?: Maybe<IOrder>;
    BuiltInDevice?: Maybe<IOrder>;
    Capacity?: Maybe<IOrder>;
    weight?: Maybe<IOrder>;
    acceptAGB?: Maybe<IOrder>;
};

export type IProductDryer_Where = {
    title?: Maybe<ICaisyField_String_Where>;
    text?: Maybe<ICaisyField_String_Where>;
    ourPrice?: Maybe<ICaisyField_String_Where>;
    normalPrice?: Maybe<ICaisyField_String_Where>;
    startDate?: Maybe<ICaisy_Field_DateTime_WhereDateTime>;
    mehrwertsteuer?: Maybe<ICaisyField_Number_WhereFloat>;
    endDate?: Maybe<ICaisy_Field_DateTime_WhereDateTime>;
    articelNumber?: Maybe<ICaisyField_Number_WhereFloat>;
    productTechnicalDetails?: Maybe<ICaisyField_String_Where>;
    productDescription?: Maybe<ICaisyField_String_Where>;
    EnergyEfficiencyClass?: Maybe<IProductDryer_EnergyEfficiencyClass_Where>;
    EAN?: Maybe<ICaisyField_Number_WhereFloat>;
    hight?: Maybe<ICaisyField_Number_WhereFloat>;
    width?: Maybe<ICaisyField_Number_WhereFloat>;
    EnergyConsumption?: Maybe<ICaisyField_String_Where>;
    depth?: Maybe<ICaisyField_Number_WhereFloat>;
    DryingEfficiencyClass?: Maybe<IProductDryer_DryingEfficiencyClass_Where>;
    CondensationOrHeatPumpDryer?: Maybe<ICaisyField_String_Where>;
    BuiltInDevice?: Maybe<Scalars["Boolean"]>;
    Capacity?: Maybe<ICaisyField_String_Where>;
    weight?: Maybe<ICaisyField_Number_WhereFloat>;
    acceptAGB?: Maybe<Scalars["Boolean"]>;
    OR?: Maybe<Array<Maybe<IProductDryer_Where>>>;
    AND?: Maybe<Array<Maybe<IProductDryer_Where>>>;
};

export type IProductDryer_EnergyEfficiencyClass_Where = {
    eq?: Maybe<IProductDryer_EnergyEfficiencyClass>;
};

export type IProductDryer_DryingEfficiencyClass_Where = {
    eq?: Maybe<IProductDryer_DryingEfficiencyClass>;
};

export type IProductExtractorHood_Connection = {
    __typename: "ProductExtractorHood_Connection";
    edges?: Maybe<Array<Maybe<IProductExtractorHood_ConnectionEdge>>>;
    pageInfo?: Maybe<IPageInfo>;
    totalCount?: Maybe<Scalars["Int"]>;
};

export type IProductExtractorHood_ConnectionEdge = {
    __typename: "ProductExtractorHood_ConnectionEdge";
    node?: Maybe<IProductExtractorHood>;
    cursor?: Maybe<Scalars["String"]>;
};

export type IProductExtractorHood_Sort = {
    images?: Maybe<IOrder>;
    title?: Maybe<IOrder>;
    text?: Maybe<IOrder>;
    services?: Maybe<IOrder>;
    normalPrice?: Maybe<IOrder>;
    ourPrice?: Maybe<IOrder>;
    mehrwertsteuer?: Maybe<IOrder>;
    startDate?: Maybe<IOrder>;
    endDate?: Maybe<IOrder>;
    articelNumber?: Maybe<IOrder>;
    productDownloads?: Maybe<IOrder>;
    productTechnicalDetails?: Maybe<IOrder>;
    hight?: Maybe<IOrder>;
    depth?: Maybe<IOrder>;
    width?: Maybe<IOrder>;
    EAN?: Maybe<IOrder>;
    EnergyConsumption?: Maybe<IOrder>;
    EnergyEfficiencyClass?: Maybe<IOrder>;
    productDescription?: Maybe<IOrder>;
    BuiltInDevice?: Maybe<IOrder>;
    FilterPerformance?: Maybe<IOrder>;
    ExhaustAirOrCirculatingAir?: Maybe<IOrder>;
    Connectivity?: Maybe<IOrder>;
    weight?: Maybe<IOrder>;
    acceptAGB?: Maybe<IOrder>;
    HasTroughfanOrDowndraft?: Maybe<IOrder>;
};

export type IProductExtractorHood_Where = {
    title?: Maybe<ICaisyField_String_Where>;
    text?: Maybe<ICaisyField_String_Where>;
    normalPrice?: Maybe<ICaisyField_String_Where>;
    ourPrice?: Maybe<ICaisyField_String_Where>;
    mehrwertsteuer?: Maybe<ICaisyField_Number_WhereFloat>;
    startDate?: Maybe<ICaisy_Field_DateTime_WhereDateTime>;
    endDate?: Maybe<ICaisy_Field_DateTime_WhereDateTime>;
    articelNumber?: Maybe<ICaisyField_Number_WhereFloat>;
    productTechnicalDetails?: Maybe<ICaisyField_String_Where>;
    hight?: Maybe<ICaisyField_Number_WhereFloat>;
    depth?: Maybe<ICaisyField_Number_WhereFloat>;
    width?: Maybe<ICaisyField_Number_WhereFloat>;
    EAN?: Maybe<ICaisyField_Number_WhereFloat>;
    EnergyConsumption?: Maybe<ICaisyField_String_Where>;
    EnergyEfficiencyClass?: Maybe<IProductExtractorHood_EnergyEfficiencyClass_Where>;
    productDescription?: Maybe<ICaisyField_String_Where>;
    BuiltInDevice?: Maybe<Scalars["Boolean"]>;
    FilterPerformance?: Maybe<ICaisyField_String_Where>;
    ExhaustAirOrCirculatingAir?: Maybe<ICaisyField_String_Where>;
    Connectivity?: Maybe<ICaisyField_String_Where>;
    weight?: Maybe<ICaisyField_Number_WhereFloat>;
    acceptAGB?: Maybe<Scalars["Boolean"]>;
    HasTroughfanOrDowndraft?: Maybe<Scalars["Boolean"]>;
    OR?: Maybe<Array<Maybe<IProductExtractorHood_Where>>>;
    AND?: Maybe<Array<Maybe<IProductExtractorHood_Where>>>;
};

export type IProductExtractorHood_EnergyEfficiencyClass_Where = {
    eq?: Maybe<IProductExtractorHood_EnergyEfficiencyClass>;
};

export type IProductDishwasher_Connection = {
    __typename: "ProductDishwasher_Connection";
    edges?: Maybe<Array<Maybe<IProductDishwasher_ConnectionEdge>>>;
    pageInfo?: Maybe<IPageInfo>;
    totalCount?: Maybe<Scalars["Int"]>;
};

export type IProductDishwasher_ConnectionEdge = {
    __typename: "ProductDishwasher_ConnectionEdge";
    node?: Maybe<IProductDishwasher>;
    cursor?: Maybe<Scalars["String"]>;
};

export type IProductDishwasher_Sort = {
    title?: Maybe<IOrder>;
    images?: Maybe<IOrder>;
    normalPrice?: Maybe<IOrder>;
    ourPrice?: Maybe<IOrder>;
    services?: Maybe<IOrder>;
    text?: Maybe<IOrder>;
    mehrwertsteuer?: Maybe<IOrder>;
    startDate?: Maybe<IOrder>;
    endDate?: Maybe<IOrder>;
    articelNumber?: Maybe<IOrder>;
    productDescription?: Maybe<IOrder>;
    productTechnicalDetails?: Maybe<IOrder>;
    productDownloads?: Maybe<IOrder>;
    width?: Maybe<IOrder>;
    depth?: Maybe<IOrder>;
    hight?: Maybe<IOrder>;
    EAN?: Maybe<IOrder>;
    EnergyEfficiencyClass?: Maybe<IOrder>;
    WaterConsumption?: Maybe<IOrder>;
    EnergyConsumption?: Maybe<IOrder>;
    NominalCapacity?: Maybe<IOrder>;
    EN50242?: Maybe<IOrder>;
    Connectivity?: Maybe<IOrder>;
    weight?: Maybe<IOrder>;
    acceptAGB?: Maybe<IOrder>;
    BuiltInDevice?: Maybe<IOrder>;
};

export type IProductDishwasher_Where = {
    title?: Maybe<ICaisyField_String_Where>;
    normalPrice?: Maybe<ICaisyField_String_Where>;
    ourPrice?: Maybe<ICaisyField_String_Where>;
    text?: Maybe<ICaisyField_String_Where>;
    mehrwertsteuer?: Maybe<ICaisyField_Number_WhereFloat>;
    startDate?: Maybe<ICaisy_Field_DateTime_WhereDateTime>;
    endDate?: Maybe<ICaisy_Field_DateTime_WhereDateTime>;
    articelNumber?: Maybe<ICaisyField_Number_WhereFloat>;
    productDescription?: Maybe<ICaisyField_String_Where>;
    productTechnicalDetails?: Maybe<ICaisyField_String_Where>;
    width?: Maybe<ICaisyField_Number_WhereFloat>;
    depth?: Maybe<ICaisyField_Number_WhereFloat>;
    hight?: Maybe<ICaisyField_Number_WhereFloat>;
    EAN?: Maybe<ICaisyField_Number_WhereFloat>;
    EnergyEfficiencyClass?: Maybe<IProductDishwasher_EnergyEfficiencyClass_Where>;
    WaterConsumption?: Maybe<ICaisyField_String_Where>;
    EnergyConsumption?: Maybe<ICaisyField_String_Where>;
    NominalCapacity?: Maybe<ICaisyField_String_Where>;
    EN50242?: Maybe<ICaisyField_String_Where>;
    Connectivity?: Maybe<ICaisyField_String_Where>;
    weight?: Maybe<ICaisyField_Number_WhereFloat>;
    acceptAGB?: Maybe<Scalars["Boolean"]>;
    BuiltInDevice?: Maybe<Scalars["Boolean"]>;
    OR?: Maybe<Array<Maybe<IProductDishwasher_Where>>>;
    AND?: Maybe<Array<Maybe<IProductDishwasher_Where>>>;
};

export type IProductDishwasher_EnergyEfficiencyClass_Where = {
    eq?: Maybe<IProductDishwasher_EnergyEfficiencyClass>;
};

export type IProductWashingMachine_Connection = {
    __typename: "ProductWashingMachine_Connection";
    edges?: Maybe<Array<Maybe<IProductWashingMachine_ConnectionEdge>>>;
    pageInfo?: Maybe<IPageInfo>;
    totalCount?: Maybe<Scalars["Int"]>;
};

export type IProductWashingMachine_ConnectionEdge = {
    __typename: "ProductWashingMachine_ConnectionEdge";
    node?: Maybe<IProductWashingMachine>;
    cursor?: Maybe<Scalars["String"]>;
};

export type IProductWashingMachine_Sort = {
    title?: Maybe<IOrder>;
    text?: Maybe<IOrder>;
    services?: Maybe<IOrder>;
    images?: Maybe<IOrder>;
    normalPrice?: Maybe<IOrder>;
    ourPrice?: Maybe<IOrder>;
    startDate?: Maybe<IOrder>;
    mehrwertsteuer?: Maybe<IOrder>;
    Connectivity?: Maybe<IOrder>;
    endDate?: Maybe<IOrder>;
    articelNumber?: Maybe<IOrder>;
    productDownloads?: Maybe<IOrder>;
    productTechnicalDetails?: Maybe<IOrder>;
    hight?: Maybe<IOrder>;
    productDescription?: Maybe<IOrder>;
    EAN?: Maybe<IOrder>;
    depth?: Maybe<IOrder>;
    width?: Maybe<IOrder>;
    EnergyEfficiencyClass?: Maybe<IOrder>;
    EnergyConsumption?: Maybe<IOrder>;
    Capacity?: Maybe<IOrder>;
    ProtectiveDrum?: Maybe<IOrder>;
    SpinningRate?: Maybe<IOrder>;
    WaterConsumption?: Maybe<IOrder>;
    weight?: Maybe<IOrder>;
    acceptAGB?: Maybe<IOrder>;
    BuiltInDevice?: Maybe<IOrder>;
};

export type IProductWashingMachine_Where = {
    title?: Maybe<ICaisyField_String_Where>;
    text?: Maybe<ICaisyField_String_Where>;
    normalPrice?: Maybe<ICaisyField_String_Where>;
    ourPrice?: Maybe<ICaisyField_String_Where>;
    startDate?: Maybe<ICaisy_Field_DateTime_WhereDateTime>;
    mehrwertsteuer?: Maybe<ICaisyField_Number_WhereFloat>;
    Connectivity?: Maybe<ICaisyField_String_Where>;
    endDate?: Maybe<ICaisy_Field_DateTime_WhereDateTime>;
    articelNumber?: Maybe<ICaisyField_Number_WhereFloat>;
    productTechnicalDetails?: Maybe<ICaisyField_String_Where>;
    hight?: Maybe<ICaisyField_Number_WhereFloat>;
    productDescription?: Maybe<ICaisyField_String_Where>;
    EAN?: Maybe<ICaisyField_Number_WhereFloat>;
    depth?: Maybe<ICaisyField_Number_WhereFloat>;
    width?: Maybe<ICaisyField_Number_WhereFloat>;
    EnergyEfficiencyClass?: Maybe<IProductWashingMachine_EnergyEfficiencyClass_Where>;
    EnergyConsumption?: Maybe<ICaisyField_String_Where>;
    Capacity?: Maybe<ICaisyField_String_Where>;
    ProtectiveDrum?: Maybe<ICaisyField_String_Where>;
    SpinningRate?: Maybe<ICaisyField_String_Where>;
    WaterConsumption?: Maybe<ICaisyField_String_Where>;
    weight?: Maybe<ICaisyField_Number_WhereFloat>;
    acceptAGB?: Maybe<Scalars["Boolean"]>;
    BuiltInDevice?: Maybe<Scalars["Boolean"]>;
    OR?: Maybe<Array<Maybe<IProductWashingMachine_Where>>>;
    AND?: Maybe<Array<Maybe<IProductWashingMachine_Where>>>;
};

export type IProductWashingMachine_EnergyEfficiencyClass_Where = {
    eq?: Maybe<IProductWashingMachine_EnergyEfficiencyClass>;
};

export type IProductStoveAndOven_Connection = {
    __typename: "ProductStoveAndOven_Connection";
    edges?: Maybe<Array<Maybe<IProductStoveAndOven_ConnectionEdge>>>;
    pageInfo?: Maybe<IPageInfo>;
    totalCount?: Maybe<Scalars["Int"]>;
};

export type IProductStoveAndOven_ConnectionEdge = {
    __typename: "ProductStoveAndOven_ConnectionEdge";
    node?: Maybe<IProductStoveAndOven>;
    cursor?: Maybe<Scalars["String"]>;
};

export type IProductStoveAndOven_Sort = {
    title?: Maybe<IOrder>;
    services?: Maybe<IOrder>;
    text?: Maybe<IOrder>;
    images?: Maybe<IOrder>;
    normalPrice?: Maybe<IOrder>;
    ourPrice?: Maybe<IOrder>;
    mehrwertsteuer?: Maybe<IOrder>;
    startDate?: Maybe<IOrder>;
    endDate?: Maybe<IOrder>;
    energyConsumptionTopBottomHeat?: Maybe<IOrder>;
    articelNumber?: Maybe<IOrder>;
    productDownloads?: Maybe<IOrder>;
    productDescription?: Maybe<IOrder>;
    EAN?: Maybe<IOrder>;
    productTechnicalDetails?: Maybe<IOrder>;
    width?: Maybe<IOrder>;
    hight?: Maybe<IOrder>;
    EnergyEfficiencyClass?: Maybe<IOrder>;
    depth?: Maybe<IOrder>;
    EnergyConsumption?: Maybe<IOrder>;
    BuiltInDevice?: Maybe<IOrder>;
    Connectivity?: Maybe<IOrder>;
    UsableVolume?: Maybe<IOrder>;
    Pyrolysis?: Maybe<IOrder>;
    weight?: Maybe<IOrder>;
    acceptAGB?: Maybe<IOrder>;
    steamCooker?: Maybe<IOrder>;
    energyConsumptionHotAir?: Maybe<IOrder>;
};

export type IProductStoveAndOven_Where = {
    title?: Maybe<ICaisyField_String_Where>;
    text?: Maybe<ICaisyField_String_Where>;
    normalPrice?: Maybe<ICaisyField_String_Where>;
    ourPrice?: Maybe<ICaisyField_String_Where>;
    mehrwertsteuer?: Maybe<ICaisyField_Number_WhereFloat>;
    startDate?: Maybe<ICaisy_Field_DateTime_WhereDateTime>;
    endDate?: Maybe<ICaisy_Field_DateTime_WhereDateTime>;
    energyConsumptionTopBottomHeat?: Maybe<ICaisyField_String_Where>;
    articelNumber?: Maybe<ICaisyField_Number_WhereFloat>;
    productDescription?: Maybe<ICaisyField_String_Where>;
    EAN?: Maybe<ICaisyField_Number_WhereFloat>;
    productTechnicalDetails?: Maybe<ICaisyField_String_Where>;
    width?: Maybe<ICaisyField_Number_WhereFloat>;
    hight?: Maybe<ICaisyField_Number_WhereFloat>;
    EnergyEfficiencyClass?: Maybe<IProductStoveAndOven_EnergyEfficiencyClass_Where>;
    depth?: Maybe<ICaisyField_Number_WhereFloat>;
    EnergyConsumption?: Maybe<ICaisyField_String_Where>;
    BuiltInDevice?: Maybe<Scalars["Boolean"]>;
    Connectivity?: Maybe<ICaisyField_String_Where>;
    UsableVolume?: Maybe<ICaisyField_String_Where>;
    Pyrolysis?: Maybe<Scalars["Boolean"]>;
    weight?: Maybe<ICaisyField_Number_WhereFloat>;
    acceptAGB?: Maybe<Scalars["Boolean"]>;
    steamCooker?: Maybe<Scalars["Boolean"]>;
    energyConsumptionHotAir?: Maybe<ICaisyField_String_Where>;
    OR?: Maybe<Array<Maybe<IProductStoveAndOven_Where>>>;
    AND?: Maybe<Array<Maybe<IProductStoveAndOven_Where>>>;
};

export type IProductStoveAndOven_EnergyEfficiencyClass_Where = {
    eq?: Maybe<IProductStoveAndOven_EnergyEfficiencyClass>;
};

export type IProductRefrigeratorAndFreezer_Connection = {
    __typename: "ProductRefrigeratorAndFreezer_Connection";
    edges?: Maybe<Array<Maybe<IProductRefrigeratorAndFreezer_ConnectionEdge>>>;
    pageInfo?: Maybe<IPageInfo>;
    totalCount?: Maybe<Scalars["Int"]>;
};

export type IProductRefrigeratorAndFreezer_ConnectionEdge = {
    __typename: "ProductRefrigeratorAndFreezer_ConnectionEdge";
    node?: Maybe<IProductRefrigeratorAndFreezer>;
    cursor?: Maybe<Scalars["String"]>;
};

export type IProductRefrigeratorAndFreezer_Sort = {
    text?: Maybe<IOrder>;
    normalPrice?: Maybe<IOrder>;
    ourPrice?: Maybe<IOrder>;
    images?: Maybe<IOrder>;
    services?: Maybe<IOrder>;
    title?: Maybe<IOrder>;
    mehrwertsteuer?: Maybe<IOrder>;
    startDate?: Maybe<IOrder>;
    endDate?: Maybe<IOrder>;
    articelNumber?: Maybe<IOrder>;
    productDescription?: Maybe<IOrder>;
    productTechnicalDetails?: Maybe<IOrder>;
    hight?: Maybe<IOrder>;
    EAN?: Maybe<IOrder>;
    width?: Maybe<IOrder>;
    productDownloads?: Maybe<IOrder>;
    depth?: Maybe<IOrder>;
    EnergyEfficiencyClass?: Maybe<IOrder>;
    EnergyConsumption?: Maybe<IOrder>;
    UsableCapacityCoolerCompartment?: Maybe<IOrder>;
    BuiltInDevice?: Maybe<IOrder>;
    NoiseEmission?: Maybe<IOrder>;
    Connectivity?: Maybe<IOrder>;
    UsableCapacityFreezerCompartment?: Maybe<IOrder>;
    StarRating?: Maybe<IOrder>;
    weight?: Maybe<IOrder>;
    acceptAGB?: Maybe<IOrder>;
    ZeroDegreeZone?: Maybe<IOrder>;
};

export type IProductRefrigeratorAndFreezer_Where = {
    text?: Maybe<ICaisyField_String_Where>;
    normalPrice?: Maybe<ICaisyField_String_Where>;
    ourPrice?: Maybe<ICaisyField_String_Where>;
    title?: Maybe<ICaisyField_String_Where>;
    mehrwertsteuer?: Maybe<ICaisyField_Number_WhereFloat>;
    startDate?: Maybe<ICaisy_Field_DateTime_WhereDateTime>;
    endDate?: Maybe<ICaisy_Field_DateTime_WhereDateTime>;
    articelNumber?: Maybe<ICaisyField_Number_WhereFloat>;
    productDescription?: Maybe<ICaisyField_String_Where>;
    productTechnicalDetails?: Maybe<ICaisyField_String_Where>;
    hight?: Maybe<ICaisyField_Number_WhereFloat>;
    EAN?: Maybe<ICaisyField_Number_WhereFloat>;
    width?: Maybe<ICaisyField_Number_WhereFloat>;
    depth?: Maybe<ICaisyField_Number_WhereFloat>;
    EnergyEfficiencyClass?: Maybe<IProductRefrigeratorAndFreezer_EnergyEfficiencyClass_Where>;
    EnergyConsumption?: Maybe<ICaisyField_String_Where>;
    UsableCapacityCoolerCompartment?: Maybe<ICaisyField_String_Where>;
    BuiltInDevice?: Maybe<Scalars["Boolean"]>;
    NoiseEmission?: Maybe<ICaisyField_String_Where>;
    Connectivity?: Maybe<ICaisyField_String_Where>;
    UsableCapacityFreezerCompartment?: Maybe<ICaisyField_String_Where>;
    StarRating?: Maybe<ICaisyField_String_Where>;
    weight?: Maybe<ICaisyField_Number_WhereFloat>;
    acceptAGB?: Maybe<Scalars["Boolean"]>;
    ZeroDegreeZone?: Maybe<Scalars["Boolean"]>;
    OR?: Maybe<Array<Maybe<IProductRefrigeratorAndFreezer_Where>>>;
    AND?: Maybe<Array<Maybe<IProductRefrigeratorAndFreezer_Where>>>;
};

export type IProductRefrigeratorAndFreezer_EnergyEfficiencyClass_Where = {
    eq?: Maybe<IProductRefrigeratorAndFreezer_EnergyEfficiencyClass>;
};

export type ICaisy_Document =
    | ICompanyInformationPrivate
    | IProductCooktop
    | IProduct
    | IPageImprint
    | IProductVacuumDrawer
    | INews
    | IArticle
    | IProductStoveAndOven
    | IProductRefrigeratorAndFreezer
    | IProductAService
    | IUniversalTexts
    | IContinuingPageGalleryItemRoot
    | IProductSmallDevice
    | IProductMicrowaveAndCooker
    | IProductWarmingDrawer
    | IBanner
    | IDeviceType
    | IProductAccessoriesAndSupplies
    | IProductExtractorHood
    | IProductWashingMachine
    | IProductDryer
    | IProductSinkAndTap
    | IAsset
    | IProductService
    | IPageBrand
    | IProductSparePart
    | IProductDishwasher
    | ISortingNews
    | IProductLighting
    | IProductKitchenFurniture
    | IProductCoffeeMachine
    | IProductBathroomFurniture
    | IProductKitchen
    | IProductWorktop
    | IBrand
    | IProductConsumerElectronics
    | IContinuingPageGalleryItem
    | IPageRepairInquiry
    | ISubNavigationItem
    | IGuestbookEntry
    | IPageLanding
    | IPage
    | ICompanyInformationPublic
    | ISettings
    | IPrimaryNavigation
    | ILandingEvent
    | IPageContact
    | INavigationItem
    | IPageDateInquiry
    | IPageBrandOverview
    | IPageNews
    | IFooter
    | IPageGuestbook;

export type ICaisy_Document_NotFound = {
    __typename: "Caisy_Document_NotFound";
    message?: Maybe<Scalars["String"]>;
};

export type IMutation = {
    __typename: "Mutation";
    createDeviceType?: Maybe<IDeviceType>;
    updateDeviceType?: Maybe<IDeviceType>;
    removeDeviceType?: Maybe<Scalars["Boolean"]>;
};

export type IMutationCreateDeviceTypeArgs = {
    locale?: Maybe<Scalars["String"]>;
    input: IDeviceType_CreateInput;
};

export type IMutationUpdateDeviceTypeArgs = {
    id: Scalars["ID"];
    merge?: Maybe<Scalars["Boolean"]>;
    locale?: Maybe<Scalars["String"]>;
    input: IDeviceType_UpdateInput;
};

export type IMutationRemoveDeviceTypeArgs = {
    id: Scalars["ID"];
    locale?: Maybe<Scalars["String"]>;
};

export type IDeviceType_CreateInput = {
    name?: Maybe<Scalars["String"]>;
};

export type IDeviceType_UpdateInput = {
    name?: Maybe<Scalars["String"]>;
};
