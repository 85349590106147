 import gql from 'graphql-tag';

 const MetaInformation = gql`
  fragment MetaInformation on MetaInformation{
    pageTitle
    description
    metaTags
    author
  }
 `;

 export default MetaInformation;