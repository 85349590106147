import React, { Fragment, FC } from "react";
import SRichText from "./Styles/SRichText";
import { RichTextRenderer } from "@caisy/rich-text-react-renderer";
import parse from "html-react-parser";
import TextOverwrite from "./nodes/Text";
import ListItemOverwrite from "./nodes/ListItem";
import ParagraphOverwrite from "./nodes/Paragraph";
import { css } from "styled-components";
import IFrame from "./nodes/IFrame";

const HTML_REGEX = new RegExp("<s*a[^>]*>(.*?)<s*/s*a>", "g");

interface IRichTextProps {
  children?: any /** give the component some markdown text as child */;
  renderBr?: boolean /** set this to false to deactive line breaks form rendering */;
  overwriteStyle?: any; //* * Optinoal to overwrite styles */
  textContent?: any; //* * Optinoal to overwrite styles */
  fragment?: boolean; //* * Optinoal to return no div and use just fragment, then no overwrite styles get applied */
  iframeContainer?: boolean;
}

const fixUrl = (url: string): string =>
  url.match(/^w{3}\./) ? `https://${url}` : url;

const Leaf = (leaf) => {
  let children = leaf.text;
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.code) {
    children = <code>{children}</code>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underlined) {
    children = <u>{children}</u>;
  }

  if (leaf.strikethrough) {
    children = <del>{children}</del>;
  }
  if (typeof children === "string") {
    const htmlMatch = children.match(HTML_REGEX);
    if (htmlMatch) {
      return (
        <>
          {htmlMatch.map((e, i) => {
            return <Fragment key={i}>{parse(e)}</Fragment>;
          })}
        </>
      );
    }
  }
  return <span {...leaf?.attributes}>{children}</span>;
};
const Element = (props) => {
  const { attributes, type, element, attrs, frame } = props;
  let children = props?.children?.map((c, i) => {
    if (c?.text) {
      return <Leaf key={`l${i}${c.text.substring(8)}`} {...c} />;
    }
    if (c?.type) {
      return <Element key={`e${i}${c.type.substring(8)}`} {...c} />;
    }
  });
  switch (type) {
    case "quote":
      return <blockquote {...attributes}>{children}</blockquote>;
    case "code":
      return (
        <pre>
          <code {...attributes}>{children}</code>
        </pre>
      );
    case "bulleted-list":
      return <ul {...attributes}>{children}</ul>;
    case "heading-one":
      return <h1 {...attributes}>{children}</h1>;
    case "heading-two":
      return <h2 {...attributes}>{children}</h2>;
    case "heading-three":
      return <h3 {...attributes}>{children}</h3>;
    case "heading-four":
      return <h4 {...attributes}>{children}</h4>;
    case "heading-five":
      return <h5 {...attributes}>{children}</h5>;
    case "heading-six":
      return <h6 {...attributes}>{children}</h6>;
    case "list-item":
      return <li {...attributes}>{children}</li>;
    case "numbered-list":
      return <ol {...attributes}>{children}</ol>;
    case "frame":
      return (
        <div className="c-frame">
          <iframe {...{ ...attributes, ...frame, src: frame.url }}>
            {children}
          </iframe>
        </div>
      );
    case "link":
      return (
        <>
          {props?.url ? (
            <a
              href={`${fixUrl(props?.url)}`}
              target={
                fixUrl(props?.url).includes("http") ? "_blank" : undefined
              }
              className={
                props?.url?.includes(".pdf") ||
                props?.url?.includes(".doc") ||
                props?.url?.includes(".docx")
                  ? "download"
                  : undefined
              }
              {...attributes}
            >
              {children}
            </a>
          ) : (
            <a>{children}</a>
          )}
        </>
      );
    case "paragraph":
      if (!children || `${children}` === "") {
        return (
          <p className={"c-empty"} {...attributes}>
            {children}
          </p>
        );
      }
      return <p {...attributes}>{children}</p>;
    case "table":
      return (
        <table {...attributes}>
          <tbody>{children}</tbody>
        </table>
      );
    case "table-row":
      return <tr {...attributes}>{children}</tr>;
    case "table-cell":
      if (props.width) {
        return (
          <td
            css={css`
              width: ${`${props.width}`.replace(/,/g, "")};
            `}
            {...attributes}
          >
            {children}
          </td>
        );
      }
      return <td {...attributes}>{children}</td>;
    default:
      return <span {...props?.attributes}>{children}</span>;
  }
};

const RichText: FC<IRichTextProps> = (props: IRichTextProps) => {
  const { textContent } = props;
  // if(typeof window !== "undefined"){
  //   console.log(` textContent`, textContent);
  // }

  if (textContent && textContent.type != "doc") {
    const elements: any[] = textContent?.content
      ? textContent?.content
      : textContent;
    if (props.fragment) {
      return (
        <>
          {props.children}
          <>
            {elements && typeof elements.map === "function" && (
              <>
                {elements?.map((e, i) => {
                  return (
                    <Element key={`e${i}${e?.type?.substring(8)}`} {...e} />
                  );
                })}
              </>
            )}
          </>
        </>
      );
    }
    return (
      <SRichText {...props}>
        {props.children}
        {elements &&
          Array.isArray(elements) &&
          elements.map((e, i) => {
            return <Element key={`e${i}${e?.type?.substring(8)}`} {...e} />;
          })}
      </SRichText>
    );
  }

  if (typeof textContent === "string") {
    console.warn("WARNING: Content is not rich: ", textContent);
    return <>{`${textContent}`}</>;
  }

  if (props.fragment) {
    return (
      <>
        {props.children}
        <RichTextRenderer
          node={textContent}
          overwrites={
            {
              paragraph: ParagraphOverwrite,
              text: TextOverwrite,
              listItem: ListItemOverwrite,
              ...(props?.iframeContainer ? { iframe: IFrame } : {})
            } as any
          }
        />
      </>
    );
  }
  return (
    <SRichText {...props}>
      {props.children}
      {/* "doc" | "hardBreak" | "paragraph" | "codeBlock" | "blockquote" | "bulletList" | "orderedList" | "listItem" | "heading" | "iframe" | "table" | "tableRow" | "tableCell" | "text"; */}
      <RichTextRenderer
        node={textContent}
        overwrites={
          {
            paragraph: ParagraphOverwrite,
            text: TextOverwrite,
            listItem: ListItemOverwrite,
            ...(props?.iframeContainer ? { iframe: IFrame } : {})
          } as any
        }
      />
    </SRichText>
  );
};

export { RichText };
