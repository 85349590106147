import gql from "graphql-tag";
import CallToAction from "./fragments/CallToAction.gql";
import Slider from "./fragments/Slider.gql";
import TextElement from "./fragments/TextElement.gql";
import Asset from "./fragments/Asset.gql";
import MetaInformation from "./fragments/MetaInformation.gql";
import ThemingColor from "./fragments/ThemingColor.gql";
import SidebarText from "./fragments/SidebarText.gql";
import ContinuingPageGallery from "./fragments/ContinuingPageGallery.gql";
import PhotoGallery from "./fragments/PhotoGallery.gql";
import Icon from "./fragments/Icon.gql";
import HtmlEmbedding from "./fragments/HtmlEmbedding.gql";
import TextElementWithCollapse from "./fragments/TextElementWithCollapse.gql";

export default gql`
  ${CallToAction}
  ${Slider}
  ${TextElementWithCollapse}
  ${Asset}
  ${MetaInformation}
  ${ThemingColor}
  ${SidebarText}
  ${PhotoGallery}
  ${ContinuingPageGallery}
  ${HtmlEmbedding}
  ${Icon}

  query allPage($slug: String!) {
    allPage(where: { slug: { eq: $slug } }) {
      edges {
        node {
          __typename
          slug
          nameInNavigation
          extras {
            __typename
            ...MetaInformation
            ...SidebarText
          }
          elements {
            __typename
            ...Asset
            ...TextElement
            ...Slider
            ...CallToAction
            ...ThemingColor
            ...PhotoGallery
            ...Icon
            ...ContinuingPageGallery
            ...HtmlEmbedding
          }
          id
          twoColumnLayout
          imageFixedBackground
          footnote
          layout
          seo_metaDescription
          seo_pageTitle
        }
      }
    }
  }
`;
