import gql from "graphql-tag";

import Asset from "./Asset.gql";

const TripleImageElementComp = gql`
${Asset}
  fragment TripleImageElementComp on TripleImageElementComp {
    id
    images{
      ...Asset
    }
  }
`;
export default TripleImageElementComp;
