import gql from "graphql-tag";
import Slider from "./fragments/Slider.gql";
import TextElement from "./fragments/TextElement.gql";

const links = `
__typename
... on PageNews {
    slug
    nameInNavigation
}
... on PageImprint {
    slug
    nameInNavigation
}
... on PageContact {
    slug
    nameInNavigation
}
... on PageGuestbook {
    slug
    nameInNavigation
}
... on Page {
    slug
    elements {
        __typename
        ...TextElement
        ...Slider
    }
    nameInNavigation
}
... on PageBrandOverview {
    slug
    nameInNavigation
    elements {
        __typename
        ...TextElement
        ...Slider
    }
}
... on PageBrand {
    slug
    nameInNavigation
    elements {
        __typename
        ...TextElement
        ...Slider
    }
}
... on PageDateInquiry {
    slug
    nameInNavigation
}
... on PageRepairInquiry {
    slug
    nameInNavigation
}
... on PageMicro {
    slug
    nameInNavigation
}
... on PageCourse {
    slug
    nameInNavigation
}
... on ExternalLink {
    externalLinkName
    externalLinkAddress
}
`;

export default gql`
    ${TextElement}
    ${Slider}
    query Settings {
        Settings {
            disableCookieBanner
            cookieBannerVisualization
            themeColorPrimary
            themeColorSecondary
            themeBackgroundColor
            themeTextColor
            themeTextColourIsland
            themeBackgroundColourIsland
            themeContrastColour
            desktopLogoOffsetY
            desktopLogoOffsetX
            desktopLogoSizePercent
            mobileLogoSizePercent
            desktopMenuVisualization
            mobileLogoOffsetX
            mobileLogoOffsetY
            mobileMenuVisualization
            appointmentRequestDisplay
            headlineWithoutBackground
            t12_position1{
                ${links}
            }
            t12_position2{
                ${links}
            }
            t12_position3{
                ${links}
            }
            t12_position4{
                ${links}
            }
            t12_position5{
                ${links}
            }
            t12_position6{
                ${links}
            }
            t12_position7{
                ${links}
            }
            t12_positionInformation{
                ${links}
            }
            t12_positionService{
                ${links}
            }
            t11_connectionsOnLanding {
                __typename
                ... on TextLinkItem {
                    id
                    headline
                    linkTo {
                        ${links}
                    }
                    subline
                }
            }
            snowOnHomeImage
        }
    }
`;
