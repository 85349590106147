import gql from "graphql-tag";

export default gql`
    query Footer {
        __typename
        Footer {
            Navigationspunkte {
                __typename
                ... on PageNews {
                    slug
                    id
                    nameInNavigation
                }
                ... on PageImprint {
                    slug
                    id
                    nameInNavigation
                }
                ... on PageContact {
                    slug
                    id
                    nameInNavigation
                }
                ... on PageGuestbook {
                    slug
                    id
                    nameInNavigation
                }
                ... on Page {
                    slug
                    id
                    nameInNavigation
                }
                ... on PageBrandOverview {
                    slug
                    id
                    nameInNavigation
                }
                ... on PageNews {
                    slug
                    id
                    nameInNavigation
                }
                ... on PageBrand {
                    slug
                    id
                    nameInNavigation
                }
                ... on PageDateInquiry {
                    slug
                    id
                    nameInNavigation
                }
                ... on PageRepairInquiry {
                    slug
                    id
                    nameInNavigation
                }
                ... on FooterSectionHeadline {
                    headline
                    id
                }
                ... on PageMicro {
                    slug
                    nameInNavigation
                }
                ... on PageCourse {
                    slug
                    nameInNavigation
                }
                ... on ExternalLink {
                    externalLinkName
                    externalLinkAddress
                }
            }
            id
            customHtml
            copyright
            _meta {
                id
            }
        }
    }
`;
