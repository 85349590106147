import gql from 'graphql-tag';

const CallToAction = gql`
 fragment CallToAction on CallToAction{
        ButtonLabel
        TextBelowButton
        TextAboveButton
        __typename
        linkTo {
           __typename
           ... on PageNews{
                slug
                __typename
            }
            ... on PageContact{
                __typename
                slug
            }
            ... on PageGuestbook{
                __typename
                slug
            }
            ... on Page{
                __typename
                slug
            }
            ... on PageDateInquiry {
                __typename
                slug
            }
            ... on PageRepairInquiry {
                __typename
                slug
            }
            ... on PageBrand{
                __typename
                slug
            }
        }
    }
`;
export default CallToAction;